import { CancelToken } from "axios";
import { inject, injectable } from "inversify";
import * as moment from "moment";
import qs from "qs";
import { notEmpty } from "../../helpers";
import { IDatetime } from "../../helpers/Datetime/interfaces";
import {
  DatetimeSymbol,
  HttpServiceSymbol,
  SettingsSymbol,
} from "../../inversify/symbols";
import {
  ICoreService,
  ProductType,
  ICoreServiceSort,
  MotorcycleLessonType,
} from "../../models/AcademyServiceModel/interfaces";
import { IApiBundle } from "../../models/BundleModel/interfaces";
import {
  GearType,
  ICoreInstructor,
  LanguagesSpoken,
} from "../../models/InstructorModel/interfaces";
import { IApiLocation } from "../../models/LocationModel/interfaces";
import { IDbUserConsents } from "../../models/UserModel/interfaces";
import { IApiRegion } from "../../stores/SettingsStore/interfaces";
import {
  IApiResponse,
  IApiResponseList,
  IApiSaldoAttendee,
  IBookesSlotQty,
  ICoreApiUserProfile,
  ICoreApiUserSettings,
  ICorePromoCodeInfo,
  ICoreUserProfile,
  IDashboardCounts,
  IEditBookingPayload,
  IKlarnaCheckoutPayload,
} from "../ApiService/interfaces";
import { isCoreApiError } from "../AuthService/interfaces";
import { IHttpService } from "../HttpService/interfaces";
import { ISettings } from "../Settings/interfaces";
import { formatCoreApiBooking } from "./helper";
import { ISavedInternalOrder } from "../../components/klarna/CheckoutScreen/interfaces";
import {
  IAPIAvailableSlot,
  IAvailableSlot,
  IBooking,
  IBookingConfig,
  IClientData,
  IClientOptions,
  ICoreApiBooking,
  ICoreApiService,
  IKlarnaConfirmationResponse,
  IKlarnaResponse,
  IOrderDetails,
  ISaldo,
} from "./interfaces";

@injectable()
export class CoreApiService implements ICoreApiService {
  public constructor(
    @inject(HttpServiceSymbol) private readonly http: IHttpService,
    @inject(SettingsSymbol) private readonly settings: ISettings,
    @inject(DatetimeSymbol) private readonly datetime: IDatetime
  ) {}

  public async registerClient(
    name: string,
    email: string,
    phone: string,
    ssn: string,
    defaultLanguage: LanguagesSpoken,
    consents: IDbUserConsents,
    currentLocationId?: number | null
  ): Promise<IClientData> {
    // Fixme! error handling
    try {
      const params = {
        name,
        email,
        phone,
        ssn,
        options: { ...consents, defaultLanguage },
        currentLocationId,
      };
      const url = `${this.settings.mdaCoreApiUrl}/v3/clients`;
      const resp = await this.http.axios.post<IApiResponse<IClientData>>(
        url,
        currentLocationId
          ? {
              name,
              email,
              phone,
              ssn,
              options: { ...consents, defaultLanguage },
              currentLocationId,
            }
          : {
              name,
              email,
              phone,
              ssn,
              options: { ...consents, defaultLanguage },
            }
      );

      return resp.data.data;
    } catch (e) {
      const errorData: any = e;
      const error = errorData.response.data;
      if (isCoreApiError(error)) {
        throw error;
      }
      throw e;
    }
  }

  public async getLocations(): Promise<IApiLocation[]> {
    // FIXME try catch
    const endpoint = `${this.settings.mdaCoreApiUrl}/v3/locations/search`;

    const resp = await this.http.axios.get<IApiResponseList<IApiLocation[]>>(
      `${endpoint}`,
      {
        params: {
          isActive: 1,
        },
      }
    );

    return resp.data.data.result;
  }

  public async getRegions(): Promise<IApiRegion[]> {
    try {
      const endpoint = `${this.settings.mdaCoreApiUrl}/v3/regions/search?isAvailable=1`;

      const resp = await this.http.axios.get<IApiResponseList<IApiRegion[]>>(
        `${endpoint}`
      );
      const { errorCode, data } = resp.data;
      if (errorCode === 0) {
        return data.result;
      } else {
        throw new Error(`error Code ${errorCode}`);
      }
    } catch (e) {
      throw new Error(`something went wrong`);
    }
  }

  public async getOrderStatus(sid: string): Promise<boolean> {
    const endpoint = `${this.settings.mdaCoreApiUrl}/v3/order/ps/${sid}/isComplete`;

    const resp = await this.http.axios
      .get<{
        data: boolean;
        errorCode: number;
      }>(`${endpoint}`)
      .then(({ data }) => data);

    return resp.data;
  }

  public async releaseReservations(sid: string): Promise<boolean> {
    const endpoint = `${this.settings.mdaCoreApiUrl}/v3/order/${sid}/releaseReservations`;

    const resp = await this.http.axios.delete(`${endpoint}`, {});

    return resp.data;
  }

  public async getServices(): Promise<ICoreService[]> {
    const endpoint = `${this.settings.mdaCoreApiUrl}/v3/services/search`;

    const resp = await this.http.axios.get<IApiResponseList<ICoreService[]>>(
      `${endpoint}`,
      {
        params: {
          isActive: 1,
          withAssociationsIds: 1,
        },
      }
    );
    const { errorCode, data } = resp.data;
    if (errorCode === 0) {
      return data.result;
    } else {
      throw new Error(`error Code ${errorCode}`);
    }
  }

  public async getServiceSort(): Promise<ICoreServiceSort[]> {
    const endpoint = `${this.settings.mdaCoreApiUrl}/v3/dropdownsort`;

    const fetchServiceSort = this.http.axios.get<
      IApiResponse<ICoreServiceSort[]>
    >(endpoint, {
      params: {
        isActive: 1,
      },
    });

    const [serviceSortResponse] = await Promise.all([fetchServiceSort]);

    const { errorCode, data } = serviceSortResponse.data;
    if (errorCode === 0) {
      return data;
    } else {
      throw new Error(`error Code ${errorCode}`);
    }
  }

  public async getInstructors(): Promise<ICoreInstructor[]> {
    const endpoint = `${this.settings.mdaCoreApiUrl}/v3/users/search?withAssociationsIds=1&withVehicles=1&isDisabled=0&isInstructor=1`;

    const resp = await this.http.axios.get<IApiResponseList<ICoreInstructor[]>>(
      `${endpoint}`
    );
    const { errorCode, data } = resp.data;
    if (errorCode === 0) {
      return data.result;
    } else {
      throw new Error(`error Code ${errorCode}`);
    }
  }

  public async getActiveBundles(): Promise<IApiBundle[]> {
    const endpoint = `${this.settings.mdaCoreApiUrl}/v3/bundles/search?isActive=1`;
    const resp = await this.http.axios.get<IApiResponseList<IApiBundle[]>>(
      `${endpoint}`
    );
    const { errorCode, data } = resp.data;
    if (errorCode === 0) {
      return data.result;
    } else {
      throw new Error(`error Code ${errorCode}`);
    }
  }
  public async getInstructor(instructorId: number): Promise<ICoreInstructor> {
    const endpoint = `${this.settings.mdaCoreApiUrl}/v3/users/${instructorId}`;

    const resp = await this.http.axios.get<IApiResponse<ICoreInstructor>>(
      `${endpoint}`
    );
    const { errorCode, data } = resp.data;
    if (errorCode === 0) {
      return data;
    } else {
      throw new Error(`error Code ${errorCode}`);
    }
  }

  public async getCurrentBookingConfig(
    userId: number
  ): Promise<IBookingConfig | undefined> {
    return await this.getCurrentBookingConfigFromNewApi(userId);
  }
  public async getAvailableClassesSlots(
    from: string,
    to: string,
    serviceId: number
  ): Promise<IAvailableSlot[]> {
    const endpoint = `${this.settings.mdaCoreApiUrl}/v3/availableSlots/from/${from}/to/${to}/service/${serviceId}`;

    const resp = await this.http.axios.get<IApiResponse<IAPIAvailableSlot[]>>(
      `${endpoint}`
    );
    const { errorCode, data } = resp.data;
    if (errorCode === 0) {
      return data
        .map((slot) => {
          const xFrom = this.datetime.fromDateTimeString(slot.start);

          const xTo = this.datetime.fromDateTimeString(slot.end);
          return (
            xFrom &&
            xTo && {
              interval: {
                from: xFrom.toDate(),
                to: xTo.toDate(),
              },
              qty: slot.qty || 0,
              seats: slot.seats && Object.values(slot.seats).length,
            }
          );
        })
        .filter(notEmpty);
    } else {
      throw new Error(`error Code ${errorCode}`);
    }
  }

  public async getAvailableSlots(
    from: string,
    to: string,
    instructorId: number,
    serviceId: number,
    isClass: boolean,
    gearType: GearType,
    locationId: number,
    motorcycleLessonType: MotorcycleLessonType,
    seatsQty?: number,
    clientId?: number
  ): Promise<IAvailableSlot[]> {
    return await this.getAvailableSlotsFromNewApi(
      from,
      to,
      instructorId,
      serviceId,
      gearType,
      isClass,
      locationId,
      motorcycleLessonType,
      seatsQty,
      clientId
    );
  }

  public async getInstructorWorkload(
    from: string,
    to: string,
    instructorId: number
  ): Promise<number> {
    return await this.getInstructorWorkloadFromNewApi(from, to, instructorId);
  }

  public async book(
    userId: number,
    serviceId: number,
    date: Date,
    time: Date,
    locationId: number,
    vehicleGearType: GearType,
    instructorId?: number,
    attendees?: IApiSaldoAttendee[],
    mcLessonType?: MotorcycleLessonType
  ): Promise<void> {
    try {
      await this.createBookingViaNewApi(
        userId,
        serviceId,
        date,
        time,
        locationId,
        vehicleGearType,
        instructorId,
        attendees,
        mcLessonType
      );
    } catch (e) {
      const errorData: any = e;
      const error = errorData.response.data;
      if (isCoreApiError(error)) {
        throw error;
      }
      throw e;
    }
  }

  public async order(
    payload: IKlarnaCheckoutPayload,
    vehicleGearType?: string
  ): Promise<IKlarnaResponse> {
    try {
      return await this.orderViaNewApi(payload, vehicleGearType);
    } catch (e) {
      const errorData: any = e;
      const error = errorData.response.data;
      if (isCoreApiError(error)) {
        throw error;
      }
      throw e;
    }
  }

  public async getStudentBookings(
    from: Date,
    to: Date,
    userId: number
  ): Promise<IBooking[]> {
    return await this.getStudentBookingsFromNewApi(from, to, userId);
  }

  public async getBooking(bookingId: string): Promise<IBooking | undefined> {
    return await this.getStudentBookingFromNewApi(bookingId);
  }
  public async editBook(
    booking: IEditBookingPayload
  ): Promise<IBooking | undefined> {
    try {
      return await this.editBookViaCoreApi(booking);
    } catch (e) {
      const errorData: any = e;
      const error = errorData.error;
      if (isCoreApiError(error)) {
        throw error;
      }
      throw e;
    }
  }
  public async loadConfirmation(
    sid: string
  ): Promise<IKlarnaConfirmationResponse> {
    try {
      const endpoint = `${this.settings.mdaCoreApiUrl}/v3/order/confirmation/${sid}`;
      const resp = await this.http.axios.get<
        IApiResponse<IKlarnaConfirmationResponse>
      >(endpoint);
      return resp.data.data;
    } catch (e) {
      const error: any = e;
      if (error.response && error.response.data && error.response.data.error) {
        throw new Error(error.response.data.error);
      } else {
        throw e;
      }
    }
  }
  public async getOrderDetails(orderId: number): Promise<IOrderDetails> {
    try {
      const endpoint = `${this.settings.mdaCoreApiUrl}/v3/order/${orderId}`;
      const resp = await this.http.axios.get<IApiResponse<IOrderDetails>>(
        endpoint,
        {
          params: {
            withCart: 1,
          },
        }
      );
      return resp.data.data;
    } catch (e) {
      const error: any = e;
      if (error.response && error.response.data && error.response.data.error) {
        throw new Error(error.response.data.error);
      } else {
        throw e;
      }
    }
  }
  public async checkPromocode(
    cancelToken: CancelToken,
    code: string,
    serviceId: number,
    startDateTime: Date,
    count: number,
    email: string,
    userId: number,
    productType: string
  ): Promise<boolean> {
    return await this.checkPromocodeViaNewApi(
      code,
      serviceId,
      startDateTime,
      count,
      userId,
      productType
    );
  }

  public async checkGiftcard(
    // cancelToken: CancelToken,
    code: string,
    serviceId: number,
    startDateTime: Date,
    count: number,
    email: string,
    userId: number,
    productType: string
  ): Promise<number> {
    return await this.checkGiftcardViaNewApi(
      code,
      serviceId,
      startDateTime,
      count,
      userId,
      productType
    );
  }

  public async checkCartPrice(
    payload: IKlarnaCheckoutPayload,
    vehicleGearType: string
  ): Promise<any> {
    const endpoint = `${this.settings.mdaCoreApiUrl}/v3/order/cartTotalPrice`;

    const data = {
      cart: [
        {
          productId: payload.eventId,
          productType: payload.productType,
          ...(payload.attendees?.length
            ? { qty: payload.attendees?.length }
            : {}),
        },
      ],
      meta: {
        clientId: payload.clientId,
        promocode: !!payload.promocode ? payload.promocode : undefined,
        giftcards: payload.giftcards,
        booking:
          payload.productType === ProductType.Service
            ? {
                startAt: moment(payload.firstLessonStartDateTime).format(
                  "YYYY-MM-DDTHH:mm:ss"
                ),
                instructor: payload.isClass
                  ? undefined
                  : {
                      id: payload.unitId,
                      vehicleGearType,
                    },
                locationId: payload.locationId,
                attendees: payload.attendees,
                asSupport: false,
                serviceId: payload.eventId,
              }
            : undefined,
      },
    };
    const response = await this.http.axios.post<IApiResponse<IKlarnaResponse>>(
      endpoint,
      data,
      {}
    );

    return response.data.data;
  }

  public async getGiftCardData(
    cancelToken: CancelToken,
    code: string
  ): Promise<ICorePromoCodeInfo> {
    return await this.getPromoCodeDataViaNewApi(cancelToken, code);
  }
  public async getPromoCodeData(
    cancelToken: CancelToken,
    code: string
  ): Promise<ICorePromoCodeInfo> {
    return await this.getPromoCodeDataViaNewApi(cancelToken, code);
  }

  public async fetchUserProfile(
    userId: number
  ): Promise<ICoreUserProfile | undefined> {
    const endpointForCoreUser = `${this.settings.mdaCoreApiUrl}/v3/clients/${userId}/profile`;

    const resp = await this.http.axios.get<IApiResponse<ICoreApiUserProfile>>(
      endpointForCoreUser
    );
    const result = resp.data.data;
    return {
      availableRegions: result.availableRegions,
      currentInstructorId: result.currentInstructorId || undefined,
      currentLocationId: result.currentLocationId || undefined,
      isAutomaticCar:
        typeof result.isAutomaticCar === "boolean"
          ? result.isAutomaticCar
          : undefined,
      status: result.status,
      testLessonDate: result.servicesState?.testLesson.date || undefined,
    };
  }

  public async fetchUserSettings(
    userId: number
  ): Promise<IClientData | undefined> {
    const endpointForCoreUser = `${this.settings.mdaCoreApiUrl}/v3/clients/${userId}`;

    const resp = await this.http.axios.get<IApiResponse<IClientData>>(
      endpointForCoreUser
    );
    const result = resp.data.data;

    return { ...result };
  }

  public async defineNewSudent(userId: number): Promise<boolean> {
    const endpointForCoreUser = `${this.settings.mdaCoreApiUrl}/v3/clients/${userId}/isNewbieMc`;

    const resp = await this.http.axios.get<IApiResponse<boolean>>(
      endpointForCoreUser
    );
    const result = resp.data.data;

    return result;
  }

  public async getDashboardCounts(userId: number): Promise<IDashboardCounts> {
    const endpointForCoreUser = `${this.settings.mdaCoreApiUrl}/v3/clients/${userId}/dashboard`;

    const resp = await this.http.axios.get<IApiResponse<IDashboardCounts>>(
      endpointForCoreUser
    );
    const { errorCode, data } = resp.data;
    if (errorCode === 0) {
      return data;
    } else {
      throw new Error(`error Code ${errorCode}`);
    }
  }

  public async getClientsSaldo(clientId: number): Promise<ISaldo[]> {
    try {
      const endpoint = `${this.settings.mdaCoreApiUrl}/v3/clients/${clientId}/saldo`;

      const resp = await this.http.axios.get<IApiResponse<ISaldo[]>>(
        `${endpoint}`,
        {
          params: {
            isActive: 1,
          },
        }
      );

      return resp.data.data;
    } catch (e) {
      throw new Error(`Can not get saldo`);
    }
  }
  async setLanguage(userId: number, language: string): Promise<IClientOptions> {
    try {
      const endpoint = `${this.settings.mdaCoreApiUrl}/v3/clients/${userId}/defaultLanguage`;

      const resp = await this.http.axios.patch<IApiResponse<any>>(
        `${endpoint}`,
        {
          languageCode: language,
        }
      );

      return resp.data.data;
    } catch (e) {
      throw new Error(`Can not set language `);
    }
  }

  private async getPromoCodeDataViaNewApi(
    cancelToken: CancelToken,
    code: string
  ): Promise<ICorePromoCodeInfo> {
    const endpoint = `${this.settings.mdaCoreApiUrl}/v2/promocodes/${code}`;

    const res = await this.http.axios.get<ICorePromoCodeInfo>(endpoint, {
      cancelToken,
    });

    return { ...res.data, value: res.data.value / 100 };
  }

  private async checkPromocodeViaNewApi(
    code: string,
    serviceId: number,
    startDateTime: Date,
    count: number,
    clientId: number,
    productType: string
  ): Promise<boolean> {
    const url = `${this.settings.mdaCoreApiUrl}/v2/promocodes/validate/promocode/${code}`;
    const payload: {
      clientId: string; // fixme!
      count: number;
      productId: number;
      startDateTime: string; // isoDate, required
      productType: string;
    } = {
      clientId: String(clientId),
      count,
      productId: serviceId,
      startDateTime: this.datetime.toDateTimeStrings(startDateTime),
      productType,
    };
    const resp = await this.http.axios
      .post<{
        error: number | null;
        data: boolean;
      }>(url, payload)
      .then((res) => res.data)
      .catch((e) => Promise.reject(e.response.data));

    return resp.data;
  }

  private async checkGiftcardViaNewApi(
    code: string,
    serviceId: number,
    startDateTime: Date,
    count: number,
    clientId: number,
    productType: string
  ): Promise<number> {
    const url = `${this.settings.mdaCoreApiUrl}/v2/promocodes/validate/giftcard/${code}`;
    const payload: {
      clientId: string; // fixme!
      count: number;
      productId: number;
      startDateTime: string; // isoDate, required
      productType: string;
    } = {
      clientId: String(clientId),
      count,
      productId: serviceId,
      startDateTime: this.datetime.toDateTimeStrings(startDateTime),
      productType,
    };
    const resp = await this.http.axios
      .post<{
        error: number | null;
        availableValue: number;
      }>(url, payload)
      .then((res) => res.data)
      .catch((e) => Promise.reject(e.response.data));

    return resp.availableValue;
  }

  private async editBookViaCoreApi(
    booking: IEditBookingPayload
  ): Promise<IBooking | undefined> {
    const url = `${this.settings.mdaCoreApiUrl}/v3/bookings/${booking.bookingId}`;

    const payload: {
      clientId: number;
      instructor: {
        id: number;
        vehicleGearType: GearType;
      };
      mcLessonType?: MotorcycleLessonType;
      locationId: number;
      serviceId: number;
      startDateTime: string;
    } = {
      clientId: booking.clientId,
      instructor: {
        id: booking.instructorId,
        vehicleGearType: booking.vehicleGearType,
      },
      locationId: booking.locationId,
      serviceId: booking.serviceId,
      startDateTime: `${booking.date} ${booking.time}`,
      mcLessonType: booking.mcLessonType,
    };
    try {
      const res = await this.http.axios.put<IApiResponse<IBooking | undefined>>(
        url,
        payload
      );
      return res.data.data;
    } catch (e) {
      const errorData: any = e;
      const error = errorData.response.data;
      if (isCoreApiError(error)) {
        throw error;
      }
      throw e;
    }
  }

  private async getStudentBookingFromNewApi(
    bookingId: string
  ): Promise<IBooking | undefined> {
    const url = `${this.settings.mdaCoreApiUrl}/v3/bookings/${bookingId}`;
    try {
      const resp = await this.http.axios.get<
        IApiResponse<ICoreApiBooking | undefined>
      >(url);

      const booking = resp.data.data;

      return booking && formatCoreApiBooking(booking);
    } catch (e: any) {
      return e.response.data.error;
    }
  }

  private async orderViaNewApi(
    payload: IKlarnaCheckoutPayload,
    vehicleGearType?: string
  ): Promise<IKlarnaResponse> {
    const endpoint = `${this.settings.mdaCoreApiUrl}/v3/order/`;
    const data = {
      cart: [
        {
          productId: payload.eventId,
          productType: payload.productType,
          ...(payload.attendees?.length
            ? { qty: payload.attendees?.length }
            : {}),
        },
      ],
      meta: {
        clientId: payload.clientId,
        promocode: !!payload.promocode ? payload.promocode : undefined,
        giftcards: !!payload.giftcards ? payload.giftcards : [],
        booking:
          payload.productType === ProductType.Service || payload.bookedBundle
            ? {
                startAt: moment(payload.firstLessonStartDateTime).format(
                  "YYYY-MM-DDTHH:mm:ss"
                ),
                instructor:
                  payload.isClass || payload.bookedBundle
                    ? undefined
                    : {
                        id: payload.unitId,
                        vehicleGearType,
                      },
                mcLessonType: payload.mcLessonType,
                locationId: payload.locationId,
                attendees: payload.attendees,
                asSupport: false,
                serviceId: payload.serviceId
                  ? payload.serviceId
                  : payload.eventId,
              }
            : undefined,
        ...(payload.bookingReservationId
          ? {
              bookingReservationId: payload.bookingReservationId,
            }
          : {}),
      },
    };
    const response = await this.http.axios.post<IApiResponse<IKlarnaResponse>>(
      endpoint,
      data,
      {}
    );

    return response.data.data;
  }

  private async getStudentBookingsFromNewApi(
    from: Date,
    to: Date,
    clientId: number
  ): Promise<IBooking[]> {
    const endpoint = `${this.settings.mdaCoreApiUrl}/v3/bookings/search/`;
    const queryString: {
      clientId: number;
      startDate: {
        from: string;
        to: string;
      };
    } = {
      clientId,
      startDate: {
        from: moment(from).format("YYYY-MM-DD"),
        to: moment(to).format("YYYY-MM-DD"),
      },
    };
    const response = await this.http.axios.get<
      IApiResponseList<ICoreApiBooking[]>
    >(`${endpoint}?${qs.stringify(queryString)}`, {
      params: {
        withSlot: 1,
      },
    });
    return response.data.data.result
      .filter((el) => !el.isCanceled)
      .map((booking) => formatCoreApiBooking(booking));
  }

  private async createBookingViaNewApi(
    clientId: number,
    serviceId: number,
    date: Date,
    time: Date,
    locationId: number,
    vehicleGearType: GearType,
    instructorId?: number,
    attendees?: IApiSaldoAttendee[],
    mcLessonType?: MotorcycleLessonType
  ): Promise<void> {
    const endpoint = `${this.settings.mdaCoreApiUrl}/v3/bookings`;
    const payload: {
      clientId: number;
      instructor:
        | {
            id: number;
            vehicleGearType: GearType;
          }
        | undefined;
      mcLessonType?: MotorcycleLessonType;
      locationId: number;
      serviceId: number;
      startAt: string;
      attendees?: IApiSaldoAttendee[];
    } = {
      clientId,
      instructor: instructorId
        ? {
            id: instructorId,
            vehicleGearType,
          }
        : undefined,
      mcLessonType,
      locationId,
      serviceId,
      startAt: this.datetime.toDateTimeStrings(
        this.datetime.fromDayAndTimePart(date, time)
      ),
      attendees,
    };

    await this.http.axios.post(endpoint, payload);
  }

  private async getCurrentBookingConfigFromNewApi(
    studentId: number
  ): Promise<IBookingConfig | undefined> {
    const endpoint = `${this.settings.mdaCoreApiUrl}/v3/clients/${studentId}/bookingConfig`;

    try {
      const resp = await this.http.axios.get<
        IApiResponse<{ serviceId: number; instructorId: number }>
      >(`${endpoint}`);
      const { errorCode, data } = resp.data;
      if (errorCode === 0) {
        return {
          instructorId: data.instructorId,
          serviceId: data.serviceId,
        };
      } else {
        return undefined;
      }
    } catch (e) {
      return undefined;
    }
  }

  private async getInstructorWorkloadFromNewApi(
    from: string,
    to: string,
    instructorId: number
  ): Promise<number> {
    const endpoint = `${this.settings.mdaCoreApiUrl}/v3/users/${instructorId}/workload/from/${from}/to/${to}`;

    const response = await this.http.axios.get<IBookesSlotQty>(`${endpoint}`);

    return response.data.bookedSlotsQty;
  }

  private async getAvailableSlotsFromNewApi(
    from: string,
    to: string,
    instructorId: number,
    serviceId: number,
    gearType: GearType,
    isClass: boolean,
    locationId: number,
    motorcycleLessonType: MotorcycleLessonType,
    seatsQty?: number,
    clientId?: number
  ): Promise<IAvailableSlot[]> {
    let endpoint = `${this.settings.mdaCoreApiUrl}/v3/availableSlots/from/${from}/to/${to}/service/${serviceId}/instructor/${instructorId}/gearType/${gearType}/location/${locationId}`;
    if (isClass) {
      endpoint = `${this.settings.mdaCoreApiUrl}/v3/availableSlots/from/${from}/to/${to}/service/${serviceId}`;
    }

    const queryString: {
      mcLessonType: MotorcycleLessonType;
      seatsQty?: number;
      clientId?: number;
    } = {
      mcLessonType: motorcycleLessonType || undefined,
      seatsQty: seatsQty || undefined,
      clientId: clientId || undefined,
    };

    const resp = await this.http.axios.get<IApiResponse<IAPIAvailableSlot[]>>(
      `${endpoint}?${qs.stringify(queryString)}`
    );

    const { errorCode, data } = resp.data;
    if (errorCode === 0) {
      return data
        .map((slot) => {
          const xFrom = this.datetime.fromDateTimeString(slot.start);

          const xTo = this.datetime.fromDateTimeString(slot.end);
          return (
            xFrom &&
            xTo && {
              interval: {
                from: xFrom.toDate(),
                to: xTo.toDate(),
              },
              qty: slot.qty || 0,
              seats: slot.seats && Object.values(slot.seats).length,
            }
          );
        })
        .filter(notEmpty);
    } else {
      throw new Error(`error Code ${errorCode}`);
    }
  }
  public async getGiftCardOrderForm(
    buyersEmail: string,
    value: number,
    clientId?: number
  ): Promise<any> {
    const endpoint = `${this.settings.mdaCoreApiUrl}/v3/order`;
    const payload = {
      meta: {
        clientId: clientId,
        giftcard: {
          value,
          buyersEmail,
        },
      },
      cart: [{ productId: 1, productType: "Giftcard" }],
    };
    const res = await this.http.axios.post(endpoint, payload);
    return res.data;
  }
  public async orderGiftCard(
    buyersEmail: string,
    value: number,
    clientId?: number
  ): Promise<IKlarnaResponse> {
    try {
      const res = await this.getGiftCardOrderForm(buyersEmail, value, clientId);
      return res.data;
    } catch (e) {
      const errorData: any = e;
      const error = errorData.response.data;
      if (isCoreApiError(error)) {
        throw error;
      }
      throw e;
    }
  }
}
