import classNames from "classnames";
import { History, Location } from "history";
import { observer } from "mobx-react";
import qs from "qs";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { lazyInject } from "../../../inversify/container";
import {
  ApiServiceSymbol,
  AuthServiceSymbol,
  CalendarModelSymbol,
  HistorySymbol,
  I18nServiceSymbol,
  UiStoreSymbol,
  UserStoreSymbol,
} from "../../../inversify/symbols";
import { IApiService } from "../../../services/ApiService/interfaces";
import { IAuthService } from "../../../services/AuthService/interfaces";
import { II18nService } from "../../../services/I18nService/interfaces";
import { IUiStore } from "../../../stores/UiStore/interfaces";
import { IUserStore } from "../../../stores/UserStore/interfaces";
import { StyleType } from "../../../styles/interfaces";
import { CheckoutScreenModel } from "../../klarna/CheckoutScreen/model";
import { Button } from "../../shared/forms/Button";
import { Card } from "../../shared/grid/Card";
import { ContentContainer } from "../../shared/grid/ContentContainer";
import { LoadingWrapper } from "../../shared/loaders/LoadingWrapper";
import { ModelMounter } from "../../shared/ModelMounter";
import { OrderModel } from "../OrderScreen/OrderForm/OrderModel";
import { Calendar } from "./Calendar";
import { ICalendarModel } from "./Calendar/interfaces";
import styles from "./styles.scss";

interface IScheduleScreenProps extends RouteComponentProps {}
interface IScheduleScreenState {}

class ScheduleScreen extends React.Component<
  IScheduleScreenProps,
  IScheduleScreenState
> {
  private get loading(): boolean {
    const user = this.userStore.user!;
    return (
      user.dashboardCountsReloading.isPending ||
      this.model.eventsReloading.isPending
    );
  }
  @lazyInject(I18nServiceSymbol)
  private readonly i18nService!: II18nService;
  @lazyInject(ApiServiceSymbol)
  private readonly apiService!: IApiService;
  @lazyInject(CalendarModelSymbol)
  private readonly model!: ICalendarModel;
  @lazyInject(UserStoreSymbol)
  private readonly userStore!: IUserStore;
  @lazyInject(AuthServiceSymbol)
  private readonly authService!: IAuthService;
  @lazyInject(UiStoreSymbol)
  private readonly uiStore!: IUiStore;
  @lazyInject(HistorySymbol)
  private readonly history!: History;

  private unlistenCb: (() => void) | undefined;
  public componentDidMount() {
    this.handleLocation(this.history.location);
    this.unlistenCb = this.history.listen(this.handleLocation);
  }
  public componentWillUnmount() {
    if (this.unlistenCb) {
      this.unlistenCb();
    }
  }

  public render() {
    return (
      <ContentContainer>
        <ModelMounter
          mount={this.mountModel}
          unmount={this.unmountModel}
          label="Schedule preview page"
        >
          <LoadingWrapper loading={this.loading}>
            <div className={"column"}>
              <Card>
                <div className={"column"}>
                  <Calendar model={this.model} />
                </div>
              </Card>
              {this.uiStore.features.showLessonsHistory && (
                <Button
                  styleType={StyleType.Primary}
                  onClick={this.onShowLessonsHistory}
                >
                  {this.i18nService.i18next
                    .t("lessonsHistory.button")
                    .toString()}
                </Button>
              )}
              <Button
                styleType={StyleType.Primary}
                onClick={this.onRequestBook}
                className={classNames({
                  hidden: this.model.lessonsLeft === 0,
                })}
              >
                {this.i18nService.i18next.t("scheduleScreen.scheduleANew", {
                  count: this.model.lessonsLeft,
                })}
              </Button>
              <Button
                styleType={StyleType.Primary}
                onClick={this.onRequestPurchase}
              >
                {this.i18nService.i18next
                  .t("scheduleScreen.findCourses")
                  .toString()}
              </Button>
              {/*<Button
                className={styles.purchaseButton}
                styleType={StyleType.Primary}
                onClick={this.onRequestPurchaseGiftCard}
              >
                {this.i18nService.i18next
                  .t("scheduleScreen.buyGiftCard")
                  .toString()}
                </Button>*/}
              {/*  <Button styleType={StyleType.Secondary} onClick={this.onLogout}>
                  Logout
                </Button>*/}
            </div>
          </LoadingWrapper>
        </ModelMounter>
      </ContentContainer>
    );
  }

  private mountModel = () => {
    return this.model.mount();
  };
  private unmountModel = () => {
    return this.model.unmount();
  };

  private handleLocation = (location: Location) => {
    const query = location.search.replace("?", "");
    const parsedQuery = qs.parse(query);
    if ("cancel" in parsedQuery) {
      console.debug("clear orderForm data");
      OrderModel.clearData();
      CheckoutScreenModel.cancelOrder(this.apiService).catch((err) =>
        console.error(err)
      );
    }
  };

  private readonly onRequestPurchase = () => {
    this.props.history.push("/booking/order");
  };

  private readonly onRequestPurchaseGiftCard = () => {
    this.props.history.push("/giftcard");
  };

  private readonly onRequestBook = () => {
    this.props.history.push("/booking/schedule");
  };

  private readonly onShowLessonsHistory = () => {
    this.props.history.push("/booking/lessonsHistory");
  };
}

const WrappedComponent = observer(ScheduleScreen);
const WrappedComponent1 = withRouter(WrappedComponent);

export { WrappedComponent1 as ScheduleScreen };
