import { inject, injectable } from "inversify";
import { action, observable, runInAction } from "mobx";
import {
  AnalyticsManagerSymbol,
  CoreApiServiceSymbol,
  SettingsStoreSymbol,
  UserStoreSymbol,
} from "../../../inversify/symbols";
import { PendingStatus } from "../../../models/PendingStatus";
import { IAnalytics } from "../../../services/AnalyticsManager/interfaces";
import {
  ICoreApiService,
  IOrderProducts,
} from "../../../services/CoreApiService/interfaces";
import { ISettingsStore } from "../../../stores/SettingsStore/interfaces";
import { IUserStore } from "../../../stores/UserStore/interfaces";
import { OrderModel } from "../../bookings/OrderScreen/OrderForm/OrderModel";
import { IInternalOrderRecord } from "../CheckoutScreen/interfaces";
import { CheckoutScreenModel } from "../CheckoutScreen/model";
import { ICoreConfirmationScreenModel } from "./interfaces";
// TODO: OrderData class instead of calculations
@injectable()
export class CoreConfirmationScreenModel
  implements ICoreConfirmationScreenModel
{
  public get lessonsLeft(): number {
    const { user } = this.userStore;
    if (!user) {
      return 0;
    }
    const { saldoBalance } = user;

    return saldoBalance;
  }
  public pendingStatus = new PendingStatus();
  @observable
  public isPackage: boolean;
  @observable
  public snippet = "";
  @observable
  public orderProducts: IOrderProducts[] = [];

  constructor(
    @inject(CoreApiServiceSymbol)
    private readonly coreApiService: ICoreApiService,

    @inject(AnalyticsManagerSymbol) private readonly gtm: IAnalytics,
    @inject(SettingsStoreSymbol) private readonly settingsStore: ISettingsStore,
    @inject(UserStoreSymbol) private readonly userStore: IUserStore
  ) {
    this.isPackage = false;
  }
  public async load(orderId: string): Promise<void> {
    console.debug(
      "order finished, so we can clear both the form  and order data from the localstore"
    );
    const orderData = CheckoutScreenModel.fetchInternalOrderFromLocalStore();
    this.setIsPackage(!!orderData && orderData.data.isPacket);
    if (orderData) {
      try {
        await this.notifyGTM(orderData);
      } catch (e) {
        console.error(e);
      }
    }
    OrderModel.clearData();
    CheckoutScreenModel.clearInternalOrderData();
    CheckoutScreenModel.clearKlarnaSnippetData();

    runInAction(() => {
      this.setSnippet("");
      this.pendingStatus.startPending();
    });

    try {
      const resp = await this.coreApiService.loadConfirmation(orderId);

      this.setSnippet(resp.confirmationSnippet.snippet);
      this.setOrderProducts(resp.products);
    } catch (e) {
      console.error(e);
    } finally {
      this.pendingStatus.stopPending();
    }
  }

  @action
  public setIsPackage(val: boolean) {
    this.isPackage = val;
  }
  private async notifyGTM(orderData: IInternalOrderRecord): Promise<void> {
    const { bookingId, eventId, locationId, attendees, priceWithDiscount } =
      orderData.data;
    if (!bookingId) {
      throw new Error("Booking id not defined. Order was not successful?");
    }
    const coreService = this.settingsStore.services.find(
      (service) => service.id === eventId
    );
    const serviceName = coreService
      ? coreService.name.en
      : `Service #${eventId} not found`;
    const location = this.settingsStore.locations.find(
      (x) => x.id.toString() === locationId.toString()
    );
    const locationName = location
      ? location.name.en
      : `Location #${locationId} not found`;
    const quantity = attendees ? attendees.length : 1;
    await this.gtm.logOrder({
      city: locationName,
      eventId: eventId.toString(),
      eventName: serviceName,
      orderId: bookingId,
      price: priceWithDiscount,
      quantity,
      totalPrice: priceWithDiscount * quantity,
    });
  }
  @action
  private setSnippet(snippet: string) {
    this.snippet = snippet;
  }
  @action
  private setOrderProducts(products: IOrderProducts[]) {
    this.orderProducts = products;
  }
}
