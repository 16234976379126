import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import styles from "./styles.scss";

interface IContentContainerProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children: React.ReactNode;
}

@observer
export class ContentContainer extends React.Component<IContentContainerProps> {
  public render() {
    const { className, children, ...otherProps } = this.props;
    return (
      <div className={classNames(styles.container, className)} {...otherProps}>
        {children}
      </div>
    );
  }
}
