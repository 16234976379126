import * as React from "react";
import { hot } from "react-hot-loader/root";
import { Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { App } from "./components/App";
import { I18nServiceSymbol } from "./inversify/symbols";
import type { II18nService } from "./services/I18nService/interfaces";
import { lazyInject } from "./inversify/container";
import { reaction } from "mobx";
import { observer } from "mobx-react";

export const LangContext = React.createContext<string|null>(null);

@observer
class RootComponent extends React.Component {
  @lazyInject(I18nServiceSymbol)
  private readonly i18nService!: II18nService;

  selectableDatesDisposer = reaction(
    () => ({
      lng: this.i18nService.lng,
    }),
    ({ lng }) => {
      this.forceUpdate();
    }
  );
  /**
   * We use 2 different prefixes now: "/" and "/studentNew".
   * In order to have backward compatibility we determinate the prefix in run-time
   */
  private get prefix(): string {
    const { pathname } = location;
    const oldPrefix = "/studentNew";
    return pathname.startsWith(oldPrefix) ? oldPrefix : "/";
  }
  public render() {
    return (
      <LangContext.Provider value={this.i18nService.lng}>
        <BrowserRouter basename={this.prefix}>
          <Route component={App} />
        </BrowserRouter>
      </LangContext.Provider>
    );
  }
}

export default hot(RootComponent);
