import { inject, injectable, interfaces } from "inversify";

import {
  CoreApiServiceSymbol,
  InversifyContextSymbol,
  UserDbServiceSymbol,
  UserModelSymbol,
} from "../../inversify/symbols";
import { ICoreApiService } from "../../services/CoreApiService/interfaces";
import { IUserDbService } from "../../services/UserDbService/interfaces";
import { IDbUserConsents, IUserModel, IUserModelFactory } from "./interfaces";

@injectable()
export class UserModelFactory implements IUserModelFactory {
  constructor(
    @inject(InversifyContextSymbol)
    private readonly context: interfaces.Context,
    @inject(CoreApiServiceSymbol)
    private readonly coreApiService: ICoreApiService,

    @inject(UserDbServiceSymbol) private readonly userDbService: IUserDbService
  ) {}
  public async createById(dbUserId: string): Promise<IUserModel | undefined> {
    const dbUser = await this.userDbService.findUser(dbUserId);
    if (!dbUser) {
      return undefined;
    }

    const saldo = await this.coreApiService.getClientsSaldo(
      Number(dbUser.bookingClientId)
    );


    const user = this.context.container.get<IUserModel>(UserModelSymbol);

    user.setEmail((dbUser.email || "").trim());
    user.setFirstName(dbUser.firstName);
    user.setLastName(dbUser.lastName);
    user.setFirebaseID(dbUserId);
    user.setUserId(Number(dbUser.bookingClientId));
    user.setSsn(dbUser.ssn);
    user.setIsBound(dbUser.anonymousUserIsBonded || false);
    user.setIsAdmin(dbUser.roles.some((role) => role === "instructor"));
    user.setSaldo(saldo);

    return user;
  }
  public createDbUserConsents(marketing: boolean): IDbUserConsents {
    const consent: string = new Date().toISOString();

    return {
      consents: {
        eulaDate: consent,
        ...(marketing ? { marketingDate: consent } : {}),
      },
    };
  }
}
