export enum StyleType {
  Primary = "primary",
  Secondary = "secondary",
  Blue = "blue",
  White = "white",
  WhiteBlue = "whiteBlue",
  Transparent = "transparent",
}
export enum Color {
  DarkSkyBlue = "darkSkyBlue",
  FadedOrange = "fadedOrange",
  PaleOrange = "paleOrange",
  GreyishBrown = "greyishBrown",
  GreyishBrownTwo = "greyishBrownTwo",
  Lichen = "lichen",
  LightBlueGrey = "lightBlueGrey",
  LightGreyBlue = "lightGreyBlue",
  LightGreyGreen = "lightGreyGreen",
  Mushroom = "mushroom",
  PaleGrey = "paleGrey",
  PaleGreyTwo = "paleGreyTwo",
  PaleRose = "paleRose",
  Rose = "rose",
  RosePink = "rosePink",
  TrailerPink = "trailerPink",
  Sea = "sea",
  SickGreen = "sickGreen",
  WarmGrey = "warmGrey",
  Wheat = "wheat",
  White = "white",
  Wisteria = "wisteria",
  Yellowish = "yellowish",
  PastelBlue = "pastelBlue",
  BrightOrange = "brightOrange",
  Attention = "attention",
  Magenta = "magenta",
  DarkViolet = "darkViolet",
}
