import classNames from "classnames";
import { History } from "history";
import { observer } from "mobx-react";
import * as React from "react";
import { Link } from "react-router-dom";
import { IDatetime } from "../../../../helpers/Datetime/interfaces";
import { lazyInject } from "../../../../inversify/container";
import {
  DatetimeSymbol,
  HistorySymbol,
  I18nServiceSymbol,
} from "../../../../inversify/symbols";
import { II18nService } from "../../../../services/I18nService/interfaces";
import { DatePicker } from "../../../shared/DatePicker";
import { HorizontalRule } from "../../../shared/grid/HorizontalRule";
import { CalendarDayCell } from "./CalendarDayCell";
import { CalendarLegend } from "./CalendarLegend";
import { ICalendarModel } from "./interfaces";
import styles from "./styles.scss";

interface ICalendarProps {
  model: ICalendarModel;
}
interface ICalendarState {
  legendCollapsed: boolean;
}

@observer
export class Calendar extends React.Component<ICalendarProps, ICalendarState> {
  public state: ICalendarState = {
    legendCollapsed: true,
  };
  @lazyInject(HistorySymbol)
  private readonly history!: History;
  @lazyInject(DatetimeSymbol)
  private readonly datetime!: IDatetime;
  @lazyInject(I18nServiceSymbol)
  private readonly i18nService!: II18nService;
  public render() {
    const { selectedDay } = this.props.model;

    return (
      <section className={"column"}>
        <button
          className={classNames(styles.collapseBtn, {
            [styles.collapsed]: this.state.legendCollapsed,
          })}
          onClick={this.state.legendCollapsed ? this.onOpen : this.onCollapse}
        >
          <span className={styles.collapseLabel}>
            {this.i18nService.i18next
              .t("scheduleScreen.view_details")
              .toString()}{" "}
          </span>
          <span
            className={classNames(
              styles.collapseLabel,
              styles.collapseLabelHidden
            )}
          >
            ?
          </span>
        </button>
        <h1 className={styles.title}>
          {" "}
          {this.i18nService.i18next
            .t("scheduleScreen.dashboard")
            .toString()}{" "}
        </h1>
        <div
          className={classNames(styles.legend, {
            [styles.isCollapsed]: this.state.legendCollapsed,
          })}
        >
          <CalendarLegend model={this.props.model} />
          <HorizontalRule />
        </div>
        <DatePicker
          className={styles.calendar}
          onNavigateDate={this.onChangeDate}
          dateOptions={this.props.model.selectableDays}
          Cell={CalendarDayCell}
          onSelectDate={this.onSelectDate}
          selectedDate={selectedDay}
        />
        <div className={styles.linksContainer}>
          <Link to={"/booking/view"}>
            {this.i18nService.i18next.t("scheduleScreen.showAll").toString()}
          </Link>
        </div>
      </section>
    );
  }
  private onChangeDate = (date: Date) => {
    this.props.model.setDay(date);
  };

  private onSelectDate = (day: Date | undefined) => {
    if (day) {
      this.history.push(
        `/booking/view?when=${this.datetime.toDateString(day)}`
      );
    }
  };

  private setCollapsed(legendCollapsed: boolean) {
    this.setState({
      legendCollapsed,
    });
  }

  private onCollapse = () => {
    this.setCollapsed(true);
  };

  private onOpen = () => {
    this.setCollapsed(false);
  };
}
