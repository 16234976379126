import { UserSettingsStore } from "@/stores/UserStore/UserSettingsStore";
import i18next from "i18next";
import { inject, injectable } from "inversify";
import { action, autorun, computed, observable } from "mobx";
import * as moment from "moment";
import numeral from "numeral";

import { AVAILABLE_LANGUAGES } from "../../helpers";
import {
  CoreApiServiceSymbol,
  SettingsStoreSymbol,
  UserSettingsStoreSymbol,
} from "../../inversify/symbols";
import { II18nService, Language } from "./interfaces";
import { MyLanguageDetectorFactory } from "./MyLanguageDetector";

import type { ISettingsStore } from "../../stores/SettingsStore/interfaces";
import { ICoreApiService } from "../CoreApiService/interfaces";
@injectable()
export class I18nService implements II18nService {
  @computed
  public get currentLanguage(): Language {
    const a = this.lng; // required to trigger event
    const currentLang = this.i18next.language as Language;

    return AVAILABLE_LANGUAGES.includes(currentLang)
      ? currentLang
      : Language.Eng;
  }

  public readonly i18next: i18next.i18n;
  // public readonly currentLanguage: Language;

  private readonly settingsStore: ISettingsStore;
  @observable
  lng!: string;

  @action
  setLanguage(lng: string) {
    this.lng = lng;
  }

  constructor(
    @inject(SettingsStoreSymbol) settingsStore: ISettingsStore,
    @inject(UserSettingsStoreSymbol) userSettingsStore: UserSettingsStore,
    @inject(CoreApiServiceSymbol) private coreApiService: ICoreApiService
  ) {
    this.settingsStore = settingsStore;
    this.i18next = i18next.createInstance();
    i18next.on("languageChanged", (lng) => {
      this.handleLocaleChanges(lng);
      this.setLanguage(lng);
      coreApiService.setLanguage(userSettingsStore.userId, lng);
    });
    this.i18next.on("missingKey", this.onI18nextMissingKey);
    this.i18next.use(MyLanguageDetectorFactory(userSettingsStore));

    this.i18next
      .init({
        debug: process.env.NODE_ENV !== "production",
        defaultNS: "common",
        detection: {
          caches: ["localStorage", "cookie", "mda"],
          lookupLocalStorage: "i18nextLng",
          lookupQuerystring: "lng",
          order: ["querystring", "mda", "localStorage", "navigator"],
        },
        fallbackLng: {
          default: [Language.Eng],
          eng: [Language.Eng],
          swe: [Language.Swe],
        },
        interpolation: {
          format: this.format,
        },
        ns: ["common"],
        resources: {
          [Language.Eng]: {
            common: require("../../i18next/common/en.json"),
          },
          [Language.Swe]: {
            common: require("../../i18next/common/sv.json"),
          },
        },
        saveMissing: true,
      })
      .then(() => this.handleLocaleChanges(this.i18next.language))
      .catch((err) => console.error(err));

    this.i18next.on("languageChanged", (lng) => {
      this.handleLocaleChanges(lng);
      this.setLanguage(lng);
      if (userSettingsStore.userId) {
        coreApiService.setLanguage(userSettingsStore.userId, lng);
      }
    });
    autorun(() => {
      const { translations } = this.settingsStore;
      if (translations.en) {
        this.i18next.addResourceBundle(
          Language.Eng,
          "common",
          translations.en,
          true,
          true
        );
      }
      if (translations.sv) {
        this.i18next.addResourceBundle(
          Language.Swe,
          "common",
          translations.sv,
          true,
          true
        );
      }
    });
    const t: any = this.i18next.t;
    const self = this;
    this.i18next.t = function (...args: any[]) {
      const a = self.lng; // required to trigger event
      return t.bind(this)(...args);
    };
  }

  private format = (value: string, format?: string): string => {
    if (format && format.startsWith("numeral:")) {
      const numeralFormat = format.substring(8);
      return numeral(value).format(numeralFormat);
    }
    return value;
  };
  private onI18nextMissingKey = (
    lngs: string[],
    namespace: string,
    key: string,
    res: string
  ) => {
    console.error("Key has no translation", { lngs, namespace, key, res });
  };

  private handleLocaleChanges(localeString: string) {
    switch (localeString) {
      case Language.Eng:
        moment.locale("en-gb");
        break;
      case Language.Swe:
        moment.locale("sv");
        break;
      default:
        moment.locale("en-gb");
    }
  }
}
