export enum DrivingItemStatusChange {
  Checked = "checked",
  Unchecked = "unchecked", // TODO change to "uncheck"
}

export interface IDrivingItemEvent {
  ts: number;
  instructorId?: string;
  comment?: string;
  statusChange?: DrivingItemStatusChange;
}

export interface IDrivingItem {
  dummy?: number; // Used for creating new items. Firebase won't create a record if it has empty value
  events: { [eventId: string]: IDrivingItemEvent };
}

export interface IDrivingItemDummy {
  dummy: number;
}

export interface IDrivingGroup {
  [itemId: string]: IDrivingItem | IDrivingItemDummy;
}

export enum DrivingGroupStatus {
  Finished = "finished",
  InProgress = "inProgress",
  NotStarted = "notStarted",
}

export interface IDrivingGroupSummary {
  lastReadTime: { [usedId: string]: number | undefined };
  status: DrivingGroupStatus | undefined;
  ts: number | undefined;
}

export interface IDrivingGroupsSummary {
  [groupId: string]: IDrivingGroupSummary;
}
export interface IDrivingPlan {
  metaId: string;
  groups: { [groupId: string]: IDrivingGroup };
  groupsSummary: { [groupId: string]: IDrivingGroupSummary };
  counters: IDrivingPlanCounters;
}

export interface IDrivingPlanCounters {
  studentComments: number;
  instructorComments: number;
  inProgressItems: number;
  finishedItems: number;
}

export interface IDrivingPlanStrings {
  itemTexts: {
    [itemId: string]: string;
  };

  groupTitles: {
    [groupId: string]: string;
  };

  subgroupTitles: {
    [subgroupId: string]: string;
  };
}

export interface IDrivingSubgroupMeta {
  id: string;
  itemIds: string[];
}

export interface IDrivingGroupMeta {
  id: string;
  subgroups: IDrivingSubgroupMeta[];
}

export interface IDrivingPlanMeta {
  strings: {
    [localeId: string]: IDrivingPlanStrings;
  };

  structure: {
    groups: IDrivingGroupMeta[];
  };
}

export interface IDrivingPlanDbService {
  getPlan(ownerId: string): Promise<IDrivingPlan>;
  getPlanMeta(metaId: string): Promise<IDrivingPlanMeta>;

  writePlan(ownerId: string, plan: IDrivingPlan): Promise<any>;

  subscribeToGroupsSummary(
    ownerId: string,
    callback: (summary: IDrivingGroupsSummary) => void
  ): () => void;
  subscribeToGroup(
    ownerId: string,
    groupId: string,
    callback: (group: IDrivingGroup) => void
  ): () => void;
  subscribeToCounters(
    ownerId: string,
    callback: (counters: IDrivingPlanCounters) => void
  ): () => void;

  batchUpdate(updates: object): Promise<any>;

  markGroupSummaryAsRead(
    ownerId: string,
    groupId: string,
    readerId: string
  ): object;
  setGroupSummaryStatus(
    ownerId: string,
    groupId: string,
    status: DrivingGroupStatus
  ): object;
  touchGroupSummary(ownerId: string, groupId: string): object;

  touchPlanAsInstructor(instructorId: string, ownerId: string): object;

  addItemComment(
    ownerId: string,
    groupId: string,
    itemId: string,
    comment: string,
    instructorId?: string
  ): Promise<void>;
  addItemStatusChange(
    ownerId: string,
    groupId: string,
    itemId: string,
    change: DrivingItemStatusChange,
    instructorId?: string
  ): Promise<void>;

  getPlanCounters(ownerId: string): Promise<IDrivingPlanCounters>;
  updatePlanCounters(ownerId: string, counters: IDrivingPlanCounters): object;
}
