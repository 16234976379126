import * as firebase from "firebase/app";
import { inject, injectable } from "inversify";
import {
  FirebaseAccessorSymbol,
  SettingsSymbol,
} from "../../inversify/symbols";
import { IFirebaseAccessor } from "../FirebaseAccessor/interfaces";
import { ISettings } from "../Settings/interfaces";
import { IAuthService, isAPIErrors, SignInErrorCode } from "./interfaces";

@injectable()
export class AuthService implements IAuthService {
  private get auth() {
    return this.firebaseAccessor.auth;
  }
  constructor(
    @inject(SettingsSymbol) private readonly settings: ISettings,
    @inject(FirebaseAccessorSymbol)
    private readonly firebaseAccessor: IFirebaseAccessor
  ) {}
  public async startFirebaseSignInWithPhone(
    phone: string,
    verifier: firebase.auth.ApplicationVerifier
  ): Promise<firebase.auth.ConfirmationResult> {
    try {
      console.debug(`startFirebaseSignInWithPhone with phone "${phone}"`);
      return await this.auth.signInWithPhoneNumber(phone, verifier);
    } catch (e) {
      if (isAPIErrors(e) && e.code === "auth/invalid-phone-number") {
        console.error(`phone is "${phone}"`);
        throw { code: SignInErrorCode.InvalidPhone };
      }

      throw e;
    }
  }

  public subscribeToAuthStateChange(
    callback: (user: firebase.User | null) => void
  ): firebase.Unsubscribe {
    return this.auth.onAuthStateChanged((user) => {
      callback(user);
    });
  }

  public async getCurrentUserToken(): Promise<string | undefined> {
    return this.auth.currentUser
      ? this.auth.currentUser.getIdToken(false)
      : undefined;
  }

  public async signInWithEmailPassword(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  public async confirmPhoneSignIn(
    signInResult: any,
    code: string
  ): Promise<any> {
    try {
      return await signInResult.confirm(code);
    } catch (e) {
      if (isAPIErrors(e) && e.code === "auth/invalid-verification-code") {
        throw { code: SignInErrorCode.InvalidConfirmationCode };
      }
      throw e;
    }
  }

  public async unlinkCurrentUserEmailPassword(): Promise<any> {
    console.debug("unlinkCurrentUserEmailPassword");
    const user = this.auth.currentUser;
    if (!user) {
      throw new Error("No current user");
    }

    try {
      await user.unlink(firebase.auth.EmailAuthProvider.PROVIDER_ID);
    } catch (e) {
      // User didn't have the email/password provider - that's fine
      if (isAPIErrors(e) && e.code !== "auth/no-such-provider") {
        throw e;
      }
    }
  }

  public async linkCurrentUserWithEmailPassword(
    email: string,
    password: string
  ): Promise<any> {
    const user = this.auth.currentUser;
    if (!user) {
      throw new Error("No current user");
    }

    await user.linkAndRetrieveDataWithCredential(
      firebase.auth.EmailAuthProvider.credential(email, password)
    );
  }

  public async signOut() {
    return this.auth.signOut();
  }

  public async updateCurrentUserName(firstName: string, lastName: string) {
    const currentUser = this.auth.currentUser;
    if (currentUser) {
      await currentUser.updateProfile({
        displayName: `${firstName} ${lastName}`,
        photoURL: "",
      });
    }
  }
}
