import {
  FirebaseAnalyticsEventDTO,
  GoogleTagManagerEventDTO,
} from "../interfaces";
import { IAnalyticsEvent } from "./types";

interface IGTMOrderProductData {
  sku: string; // Product SKU - Type: String - Required - Globally unique identifier
  name: string; // Product Name - Type: String - Required - Product name will be the type of service you choose
  category: string; // Product Category - Type: String - Category name will be the city/area you choose
  price: number; // Product Price - Type: Numeric - Required
  quantity: 1; // Product Quantity - Type: Numeric - Required - Quantity will always be equal to 1
}

interface IGTMOrderData extends GoogleTagManagerEventDTO {
  event: "successfulApplication";
  transactionId: string; // Transaction ID - Type: String - Required - Globally unique identifier
  transactionTotal: number; // Total revenue - Type: Numeric - Required - The total of the transaction, in our case this will always be the same as product price
  transactionProducts: ReadonlyArray<IGTMOrderProductData>;
}
interface IFirebaseAnalyticsOrderEvent extends FirebaseAnalyticsEventDTO {
  name: "successfulApplication";
  payload: {
    transactionId: string;
    transactionTotal: number;
    price: number;
    quantity: number;
    sku: string; // category id
    name: string; // event name
    city: string;
  };
}
export class OrderEvent implements IAnalyticsEvent {
  constructor(
    private readonly data: {
      orderId: string;
      eventId: string;
      eventName: string;
      city: string;
      price: number;
      totalPrice: number;
      quantity: number;
    }
  ) {}

  public toGoogleTagManagerEvent(): IGTMOrderData {
    const transactionProducts: IGTMOrderProductData[] = [];
    for (let i = 0; i < this.data.quantity; ++i) {
      transactionProducts.push({
        category: this.data.city,
        name: this.data.eventName,
        price: this.data.price,
        quantity: 1,
        sku: this.data.eventId,
      });
    }
    return {
      event: "successfulApplication",
      transactionId: this.data.orderId,
      transactionProducts,
      transactionTotal: this.data.price * this.data.quantity,
    };
  }

  public toFirebaseAnalyticsEvent(): IFirebaseAnalyticsOrderEvent {
    return {
      name: "successfulApplication",
      payload: {
        city: this.data.city,
        name: this.data.eventName,
        price: this.data.price,
        quantity: this.data.quantity,
        sku: this.data.eventId,
        transactionId: this.data.orderId,
        transactionTotal: this.data.price * this.data.quantity,
      },
    };
  }
}
