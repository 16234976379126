import { computed, observable } from "mobx";
import { DrivingGroupStatus } from "../../services/DrivingPlanDbService/interfaces";

import { DrivingItem, DrivingItemStatus } from "./DrivingItem";
import { DrivingSubgroup } from "./DrivingSubgroup";

export class DrivingGroup {
  @observable
  public subgroups: DrivingSubgroup[] = [];

  public id: string = "";
  public title?: string;
  public order: number = 0;

  public calcNewSummaryStatus(
    changedItemId: string,
    newItemStatus?: DrivingItemStatus,
    newItemComment?: string
  ) {
    if (!this.items.length) {
      return DrivingGroupStatus.NotStarted;
    }

    let anyComments = !!newItemComment;
    let anyCheckboxes = false;
    let allItemsDone = true;
    for (const subgroup of this.subgroups) {
      for (const item of subgroup.items) {
        anyComments =
          anyComments || item.events.filter((e) => !!e.comment).length > 0;

        if (newItemStatus && newItemStatus !== DrivingItemStatus.Unchecked) {
          anyCheckboxes = true;
        }
        if (
          (item.status === DrivingItemStatus.InDebate ||
            item.status === DrivingItemStatus.Done) &&
          (changedItemId !== item.id ||
            newItemStatus !== DrivingItemStatus.Unchecked)
        ) {
          anyCheckboxes = true;
        }

        if (newItemStatus && newItemStatus !== DrivingItemStatus.Done) {
          allItemsDone = false;
        }
        if (
          item.status !== DrivingItemStatus.Done &&
          (changedItemId !== item.id ||
            newItemStatus !== DrivingItemStatus.Done)
        ) {
          allItemsDone = false;
        }
      }
    }

    if (allItemsDone) {
      return DrivingGroupStatus.Finished;
    }

    return anyComments || anyCheckboxes
      ? DrivingGroupStatus.InProgress
      : DrivingGroupStatus.NotStarted;
  }

  @computed
  private get items() {
    return this.subgroups
      .map((sg) => sg.items)
      .reduce(
        (all: DrivingItem[], subgroupItems: DrivingItem[]) => [
          ...all,
          ...subgroupItems,
        ],
        [] as DrivingItem[]
      );
  }
}
