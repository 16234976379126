import { IAcademyServiceModel } from "src/models/AcademyServiceModel/interfaces";
import { IInstructorModel } from "../../models/InstructorModel/interfaces";
import {
  IApiLocation,
  IApiMultiLanguage,
  ILocationModel,
} from "../../models/LocationModel/interfaces";
import { IRegionModel } from "../../models/RegionModel/interfaces";
import { Subject } from "rxjs";

export interface ISettingsStore {
  readonly maintenance: IMaintenance;
  readonly translations: ITranslations;
  readonly drivingPlanMetaId: string;
  readonly loading: boolean;
  readonly countdownDuration: number; // seconds
  readonly regions: ReadonlyArray<IRegionModel>;
  readonly locations: ILocationModel[];
  readonly services: ReadonlyArray<IAcademyServiceModel>;
  readonly instructors: ReadonlyArray<IInstructorModel>;
  readonly loaded: Promise<boolean>;
}

export interface ISettings {
  maintenanceList: IMaintenanceList | undefined;
  translations: ITranslations;
  drivingPlanMeta: string;
  units: UnsafeFirebaseDictionary<ISettingsUnit>;
  locationEvents: ISettingsLocationEvents;
}

// integer keys makes dictionary be an array; more other - empty array turns into nothing;
export type UnsafeFirebaseDictionary<T> =
  | { [key: string]: T }
  | T[]
  | undefined;

export interface IMaintenanceList {
  [version: string]: IMaintenance;
}

export interface IMaintenance {
  enable: boolean;
  whiteList?: string[];
  message: string;
}

export interface ITranslations {
  en: { [key: string]: string | ITranslationRecord } | {};
  sv: { [key: string]: string | ITranslationRecord } | {};
}

export interface ITranslationRecord {
  [key: string]: string | ITranslationRecord;
}

export interface ISettingsUnit {
  description: string;
  email: string;
  id: string;
  isActive: boolean;
  isAutomaticCar: boolean;
  isVisible: true;
  name: string;
  phone: string;
  picturePath: string;
  positionInLocation: UnsafeFirebaseDictionary<string>;
  stationId: string;
  classId?: string;
}

export interface ISettingsLocation {
  availableUnitsIds: UnsafeFirebaseDictionary<string>;
  events: UnsafeFirebaseDictionary<ISettingsEvent>;
  id: string;
  name: string;
  description: string;
  address1: string;
  address2: string;
  city: string;
  zip: string;
  position: string;
  lat: string;
  lng: string;
  eventsOrder: undefined | number[];
}

export interface ISettingsEvent {
  availableUnitsIds: UnsafeFirebaseDictionary<number>;
  defaultLessonsQty: number;
  duration: number | string; // min
  id: string;
  isClass: boolean;
  description: string;
  isActive: boolean;
  isPublic: boolean;
  isVisible: boolean;
  key: ServiceKey;
  lessonQty: string | number;
  name: string;
  picturePath: string;
  price: number | string;
  priceWithTax: number | string;
  position: number | string;
}

export enum ServiceKey {
  Default = "default",
  BasPacket = "basPacket",
  EducationControl = "educationControl",
  Intro = "intro",
  MaxiPacket = "maxiPacket",
  MellanPacket = "mellanPacket",
  MiniPacket = "miniPacket",
  Risk1 = "risk1",
  Risk2 = "risk2",
  Moped = "moped",
  MopedDay = "mopedday",
  Taxi = "taxiLesson",
  Test = "testLesson",
  TheoryLesson = "theoryLesson",
  TheoryGroup = "theoryGroup",
  Digital = "digital",
  DigitalIntroEn = "digitalIntroEn",
  DigitalIntro = "digitalIntro",
  TrailerPacket = "trailerPacket",
  Trailer = "trailerLesson",
  TestDriving = "testDriving",
  DrivingTest = "drivingTest",
  MopedManeuver = "mopedManeuver",
  MopedTraffic = "mopedTraffic",
  MopedTheory = "mopedTheory",
}
export const ORDER_OF_PACKAGES = [
  ServiceKey.MiniPacket,
  ServiceKey.MellanPacket,
  ServiceKey.BasPacket,
  ServiceKey.MaxiPacket,
  ServiceKey.Intro,
  ServiceKey.Risk1,
  ServiceKey.Risk2,
  ServiceKey.TheoryGroup,
];

export type IntroServiceKey = typeof INTRO[number];

export const INTRO = [
  ServiceKey.Intro,
  ServiceKey.DigitalIntro,
  ServiceKey.DigitalIntroEn,
] as const;

export type TrailerServiceKey = typeof TRAILER[number];

export const TRAILER = [ServiceKey.Trailer, ServiceKey.TrailerPacket] as const;

export function isServiceKey(key: unknown): key is ServiceKey {
  return (
    typeof key === "string" && Object.values(ServiceKey).some((x) => x === key)
  );
}

export function isIntroServiceKey(key: unknown): key is IntroServiceKey {
  return isServiceKey(key) && INTRO.some((r) => r === key);
}

export function isTrailerServiceKey(key: unknown): key is TrailerServiceKey {
  return isServiceKey(key) && TRAILER.some((r) => r === key);
}

export interface ISettingsLocationEvents {
  locations: UnsafeFirebaseDictionary<ISettingsLocation>;
  globalSettings: ServiceTypeSettingsType;
}

export type ServiceTypeSettingsType = { [key in ServiceKey]: IServiceSettings };

export interface IServiceSettings {
  defaultQty: number;
  namePattern: string;
}

export interface IApiRegion {
  id: number;
  name: IApiMultiLanguage;
  description: IApiMultiLanguage;
  locations: IApiLocation[];
  isVisible: boolean;
}
