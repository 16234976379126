import * as React from "react";

export const PersonIcon: React.FC<{
  width: number;
  height: number;
  stroke: string;
}> = ({ width, height, stroke }) => {
  return (
    <>
      <svg
        stroke={stroke}
        fill="transparent"
        width={width}
        height={height}
        strokeWidth="0.08rem"
        id="Layer_1"
        version="1.1"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <style type="text/css"></style>
        <g>
          <circle cx="12" cy="12" r="11.3" />
          <path d="M12,14.9c-3.5,0-6.5,2-8,5c2,2.1,4.9,3.3,8,3.3s6-1.3,8-3.3C18.5,17,15.5,14.9,12,14.9z" />
          <circle cx="12" cy="8.7" r="3.6" />
        </g>
      </svg>
    </>
  );
};
