import * as React from "react";
import styles from "./styles.scss";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import mts_logo from "./martison.png";
import UserSettings from "../UserSettings/UserSettings";
export const HEADER_HEIGHT = 60;
export interface IMyDrivingHeaderProps {}

export class MyDrivingHeader extends React.PureComponent<IMyDrivingHeaderProps> {
  public render() {
    return (
      <header
        className={styles.header}
        style={{ height: `${HEADER_HEIGHT}px` }}
      >
        <div className={[styles.container, styles.headerContainer].join(" ")}>
          <div className={styles.headerLogos}>
            <a href={"https://mydrivingacademy.com/"} className={styles.brand}>
              <svg
                className={styles.logo}
                width="422px"
                height="145px"
                viewBox="0 0 422 145"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Logo_Vit</title>
                <desc>Created with Sketch.</desc>
                <defs>
                  <polygon
                    id="path-1"
                    points="0 144.32 421.2 144.32 421.2 0 0 0"
                  />
                </defs>
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Logotyper"
                    transform="translate(-38.000000, -569.000000)"
                  >
                    <g
                      id="Logo_Vit"
                      transform="translate(38.000000, 569.000000)"
                    >
                      <path
                        d="M98.1093,92.0804 C95.7513,98.1264 91.8613,102.0194 85.1003,102.0194 C83.0493,102.0194 81.0043,101.6074 79.0543,101.0954 L79.6723,95.3594 C81.4133,95.8724 83.3593,96.4864 85.2013,96.4864 C89.7093,96.4864 91.6553,92.2894 93.0893,88.5994 L97.3913,77.6414 L78.1363,30.1144 L84.9993,30.1144 L100.6673,69.9624 L115.9293,30.1144 L122.4843,30.1144 L98.1093,92.0804 Z"
                        id="Fill-4"
                        fill="#FFFFFF"
                      />
                      <path
                        d="M168.3618,53.1576 C168.3618,43.3266 162.2148,36.2616 152.3838,36.2616 C142.5498,36.2616 136.4058,43.3266 136.4058,53.1576 C136.4058,62.9986 142.5498,70.0626 152.3838,70.0626 C162.2148,70.0626 168.3618,62.9986 168.3618,53.1576 L168.3618,53.1576 Z M177.5788,77.4376 L168.3618,77.4376 L168.3618,70.4746 L168.1558,70.4746 C164.5728,75.6976 157.8118,78.6666 151.2568,78.6666 C136.1998,78.6666 126.5708,67.4996 126.5708,53.1576 C126.5708,38.6166 136.3008,27.6586 151.2568,27.6586 C158.2198,27.6586 164.8798,30.8306 168.1558,35.8496 L168.3618,35.8496 L168.3618,0.0006 L177.5788,0.0006 L177.5788,77.4376 Z"
                        id="Fill-6"
                        fill="#FFFFFF"
                      />
                      <path
                        d="M189.4453,28.8871 L198.6653,28.8871 L198.6653,36.3631 L198.8713,36.3631 C201.2263,31.2411 207.3703,27.6581 213.4163,27.6581 C214.8493,27.6581 216.2833,27.8621 217.5113,28.2731 L217.1003,38.2051 C215.5653,37.7931 214.0303,37.4901 212.5953,37.4901 C203.5813,37.4901 198.6653,42.4031 198.6653,53.0571 L198.6653,77.4381 L189.4453,77.4381 L189.4453,28.8871 Z"
                        id="Fill-8"
                        fill="#FFFFFF"
                      />
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1" />
                      </mask>
                      <g id="Clip-11" />
                      <polygon
                        id="Fill-10"
                        fill="#FFFFFF"
                        mask="url(#mask-2)"
                        points="226.007 77.438 235.225 77.438 235.225 28.887 226.007 28.887"
                      />
                      <polygon
                        id="Fill-12"
                        fill="#FFFFFF"
                        mask="url(#mask-2)"
                        points="241.9785 28.8871 252.6295 28.8871 267.1735 66.0691 281.1045 28.8871 290.9385 28.8871 271.8865 77.4381 261.7465 77.4381"
                      />
                      <polygon
                        id="Fill-13"
                        fill="#FFFFFF"
                        mask="url(#mask-2)"
                        points="297.686 77.438 306.904 77.438 306.904 28.887 297.686 28.887"
                      />
                      <path
                        d="M319.4951,28.8871 L328.7161,28.8871 L328.7161,36.3631 L328.9181,36.3631 C331.2761,31.2411 337.4201,27.6581 344.8991,27.6581 C354.3221,27.6581 362.5141,33.2861 362.5141,46.1941 L362.5141,77.4381 L353.2971,77.4381 L353.2971,48.7581 C353.2971,39.6421 348.0741,36.2621 342.2341,36.2621 C334.5531,36.2621 328.7161,41.1741 328.7161,52.4431 L328.7161,77.4381 L319.4951,77.4381 L319.4951,28.8871 Z"
                        id="Fill-14"
                        fill="#FFFFFF"
                        mask="url(#mask-2)"
                      />
                      <path
                        d="M380.0273,53.1576 C380.0273,62.9986 386.1713,69.4496 396.0063,69.4496 C405.8373,69.4496 411.9833,62.9986 411.9833,53.1576 C411.9833,43.3266 405.8373,36.2616 396.0063,36.2616 C386.1713,36.2616 380.0273,43.3266 380.0273,53.1576 L380.0273,53.1576 Z M421.2003,77.0266 C421.2003,91.6756 410.5493,102.0196 394.6733,102.0196 C385.4563,102.0196 377.7733,99.6646 370.9113,93.3146 L377.1593,85.5286 C381.9733,90.6496 387.4023,93.4166 394.4663,93.4166 C408.1943,93.4166 411.9833,84.8126 411.9833,76.6156 L411.9833,69.4496 L411.6763,69.4496 C408.1943,75.2856 401.5353,78.0526 394.8793,78.0526 C380.6413,78.0526 370.0913,67.2976 370.1933,53.1576 C370.1933,38.8186 379.8213,27.6586 394.8793,27.6586 C401.4333,27.6586 408.1943,30.6276 411.7773,35.8496 L411.9833,35.8496 L411.9833,28.8866 L421.2003,28.8866 L421.2003,77.0266 Z"
                        id="Fill-15"
                        fill="#FFFFFF"
                        mask="url(#mask-2)"
                      />
                      <path
                        d="M147.1205,114.2704 C140.7545,114.2704 134.3885,115.8644 134.3885,120.8944 C134.3885,124.5214 137.8905,126.3664 141.0715,126.3664 C149.2195,126.3664 151.3205,121.1504 151.3205,116.2454 L151.3205,114.2704 L147.1205,114.2704 Z M154.7595,116.6904 C154.7595,122.4204 154.8845,125.6664 155.3315,129.0414 L151.7665,129.0414 C151.5105,127.5794 151.3855,125.9224 151.3855,124.2664 L151.2575,124.2664 C149.0935,128.0234 145.9105,129.8054 140.9455,129.8054 C135.9145,129.8054 130.5685,127.3234 130.5685,121.0824 C130.5685,112.1064 141.0715,111.2174 146.8645,111.2174 L151.3205,111.2174 L151.3205,109.4354 C151.3205,104.3404 147.7555,102.3024 143.0465,102.3024 C139.6075,102.3024 136.6805,103.3844 134.0695,105.6144 L131.8415,103.0024 C134.6415,100.3314 139.2255,98.8674 143.0465,98.8674 C150.1755,98.8674 154.7595,102.0504 154.7595,109.4984 L154.7595,116.6904 Z"
                        id="Fill-16"
                        fill="#FFFFFF"
                        mask="url(#mask-2)"
                      />
                      <path
                        d="M186.4565,124.7147 C183.5925,128.1497 179.8345,129.8057 175.6345,129.8057 C166.5955,129.8057 160.1645,123.3137 160.1645,114.3337 C160.1645,105.3597 166.5955,98.8677 175.6345,98.8677 C179.8995,98.8677 183.7185,100.7127 186.5845,103.9587 L183.5275,106.2527 C181.3005,103.8327 178.6905,102.3017 175.6345,102.3017 C168.4405,102.3017 163.9855,107.7157 163.9855,114.3337 C163.9855,120.9567 168.4405,126.3667 175.6345,126.3667 C178.8185,126.3667 181.5545,124.7147 183.5275,122.2947 L186.4565,124.7147 Z"
                        id="Fill-17"
                        fill="#FFFFFF"
                        mask="url(#mask-2)"
                      />
                      <path
                        d="M207.5156,114.2704 C201.1496,114.2704 194.7836,115.8644 194.7836,120.8944 C194.7836,124.5214 198.2856,126.3664 201.4686,126.3664 C209.6166,126.3664 211.7156,121.1504 211.7156,116.2454 L211.7156,114.2704 L207.5156,114.2704 Z M215.1546,116.6904 C215.1546,122.4204 215.2806,125.6664 215.7266,129.0414 L212.1616,129.0414 C211.9066,127.5794 211.7806,125.9224 211.7806,124.2664 L211.6526,124.2664 C209.4886,128.0234 206.3066,129.8054 201.3406,129.8054 C196.3106,129.8054 190.9636,127.3234 190.9636,121.0824 C190.9636,112.1064 201.4686,111.2174 207.2606,111.2174 L211.7156,111.2174 L211.7156,109.4354 C211.7156,104.3404 208.1516,102.3024 203.4416,102.3024 C200.0026,102.3024 197.0756,103.3844 194.4656,105.6144 L192.2366,103.0024 C195.0376,100.3314 199.6206,98.8674 203.4416,98.8674 C210.5706,98.8674 215.1546,102.0504 215.1546,109.4984 L215.1546,116.6904 Z"
                        id="Fill-18"
                        fill="#FFFFFF"
                        mask="url(#mask-2)"
                      />
                      <path
                        d="M236.3359,102.3021 C229.1419,102.3021 224.6859,107.7161 224.6859,114.3331 C224.6859,120.9571 229.1419,126.3661 236.3359,126.3661 C243.2729,126.3661 248.6209,120.9571 248.6209,114.3331 C248.6209,107.7161 243.2729,102.3021 236.3359,102.3021 L236.3359,102.3021 Z M248.2389,123.8841 L248.1109,123.8841 C245.7569,127.9601 240.7279,129.8051 236.3359,129.8051 C227.2959,129.8051 220.8649,123.3141 220.8649,114.3331 C220.8649,105.3591 227.2959,98.8671 236.3359,98.8671 C240.7279,98.8671 245.7569,100.7131 248.1109,104.7841 L248.2389,104.7841 L248.2389,80.9141 L252.0579,80.9141 L252.0579,129.0421 L248.2389,129.0421 L248.2389,123.8841 Z"
                        id="Fill-19"
                        fill="#FFFFFF"
                        mask="url(#mask-2)"
                      />
                      <path
                        d="M283.1806,111.8509 C283.1806,106.6339 278.6596,102.3019 272.9936,102.3019 C264.6556,102.3019 262.3006,110.3239 262.3006,111.8509 L283.1806,111.8509 Z M286.1076,123.5029 C283.1156,127.9599 278.5966,129.8059 273.3776,129.8059 C264.4016,129.8059 258.4806,123.3769 258.4806,114.2699 C258.4806,105.4849 264.5916,98.8679 273.1856,98.8679 C282.2246,98.8679 286.9986,105.8039 286.9986,112.6189 L286.9986,115.2889 L262.3006,115.2889 C262.3006,118.4749 264.5296,126.3669 273.4406,126.3669 C277.3246,126.3669 281.3986,124.3329 283.3086,121.1499 L286.1076,123.5029 Z"
                        id="Fill-20"
                        fill="#FFFFFF"
                        mask="url(#mask-2)"
                      />
                      <path
                        d="M294.2499,107.9047 C294.2499,105.7407 293.9319,101.1567 293.9319,99.6307 L297.7519,99.6307 C297.8139,101.7947 297.8769,104.2147 298.0059,104.7847 L298.1959,104.7847 C299.7249,101.5387 303.4799,98.8677 308.2549,98.8677 C312.1379,98.8677 315.7019,100.7757 317.1039,105.2337 C318.9479,100.8387 323.0219,98.8677 327.0969,98.8677 C336.2639,98.8677 338.2999,105.1037 338.2999,111.5317 L338.2999,129.0417 L334.4809,129.0417 L334.4809,111.7247 C334.4809,106.9527 333.1439,102.3017 327.0969,102.3017 C320.0299,102.3017 318.1849,108.2857 318.1849,112.4887 L318.1849,129.0417 L314.3649,129.0417 L314.3649,111.3427 C314.3649,105.9337 312.3919,102.3017 308.2549,102.3017 C302.5889,102.3017 298.0679,106.2527 298.0679,114.3337 L298.0679,129.0417 L294.2499,129.0417 L294.2499,107.9047 Z"
                        id="Fill-21"
                        fill="#FFFFFF"
                        mask="url(#mask-2)"
                      />
                      <path
                        d="M353.5341,138.1425 C352.0701,141.9005 349.6501,144.3205 345.4501,144.3205 C344.1781,144.3205 342.9031,144.0645 341.6951,143.7455 L342.0761,140.1805 C343.1591,140.4985 344.3661,140.8805 345.5131,140.8805 C348.3141,140.8805 349.5241,138.2725 350.4161,135.9785 L353.0901,129.1685 L341.1211,99.6305 L345.3861,99.6305 L355.1281,124.3955 L364.6111,99.6305 L368.6851,99.6305 L353.5341,138.1425 Z"
                        id="Fill-22"
                        fill="#FFFFFF"
                        mask="url(#mask-2)"
                      />
                      <path
                        d="M0.5126,43.4281 C0.5126,39.9461 -0.0004,32.5711 -0.0004,30.1151 L6.1466,30.1151 C6.2476,33.5971 6.3496,37.4901 6.5546,38.4071 L6.8626,38.4071 C9.3216,33.1851 15.3646,28.8871 23.0456,28.8871 C29.2936,28.8871 35.0296,31.9571 37.2836,39.1291 C40.2516,32.0581 46.8076,28.8871 53.3626,28.8871 C68.1126,28.8871 71.3886,38.9201 71.3886,49.2641 L71.3886,77.4381 L65.2446,77.4381 L65.2446,49.5741 C65.2446,41.8961 63.0926,34.4131 53.3626,34.4131 C41.9926,34.4131 39.0236,44.0411 39.0236,50.8031 L39.0236,77.4381 L32.8766,77.4381 L32.8766,48.9601 C32.8766,40.2561 29.7026,34.4131 23.0456,34.4131 C13.9296,34.4131 6.6566,40.7691 6.6566,53.7721 L6.6566,77.4381 L0.5126,77.4381 L0.5126,43.4281 Z"
                        id="Fill-1"
                        fill="#FFFFFF"
                        mask="url(#mask-2)"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </a>
            <img
              src={mts_logo}
              width={117}
              height={40}
              draggable="false"
              alt="Mitt Körkort logo"
            />
          </div>
          <div className={styles.headerLogos}>
            <LanguageSelector></LanguageSelector>
            <UserSettings />
          </div>
        </div>
      </header>
    );
  }
}
