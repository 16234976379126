import { computed, observable } from "mobx";
import {
  DrivingItemStatusChange,
  IDrivingItem,
  IDrivingItemEvent,
} from "../../services/DrivingPlanDbService/interfaces";
import { IUserStore } from "../../stores/UserStore/interfaces";

import { lazyInject } from "../../inversify/container";
import { UserDbServiceSymbol } from "../../inversify/symbols";
import { IUserDbService } from "../../services/UserDbService/interfaces";
import { DrivingGroup } from "./DrivingGroup";
import { DrivingSubgroup } from "./DrivingSubgroup";

export enum DrivingItemStatus {
  Unchecked = "unchecked",
  Done = "done",
  InDebate = "inDebate",
}

export interface IMyDrivingItemEvent {
  ts: number;
  my: boolean;
  instructorId?: string;
  authorName: string;
  comment?: string;
  statusChange?: DrivingItemStatusChange;
}

// TODO: was copied from the previous system, must be refactored
const userNameCache: { [userId: string]: string | undefined } = {};

export class DrivingItem {
  @computed
  public get latestEvent() {
    return this.events.length ? this.events[this.events.length - 1] : undefined;
  }

  @computed
  public get status() {
    return this.events
      .filter((item) => !!item.statusChange)
      .reduce((statusSoFar: DrivingItemStatus, event: IMyDrivingItemEvent) => {
        if (event.statusChange === DrivingItemStatusChange.Unchecked) {
          return DrivingItemStatus.Unchecked;
        }

        if (event.statusChange === DrivingItemStatusChange.Checked) {
          const byInstructor = !!event.instructorId;
          if (statusSoFar === DrivingItemStatus.InDebate) {
            return byInstructor ? DrivingItemStatus.Done : statusSoFar;
          }
          if (statusSoFar === DrivingItemStatus.Unchecked) {
            return byInstructor
              ? DrivingItemStatus.Done
              : DrivingItemStatus.InDebate;
          }
          if (statusSoFar === DrivingItemStatus.Done) {
            return statusSoFar;
          }
        }

        return statusSoFar;
      }, DrivingItemStatus.Unchecked);
  }
  public static async fromApi(
    id: string,
    apiItem: IDrivingItem,
    text: string,
    ownerId: string,
    userStore: IUserStore,
    userDbService: IUserDbService
  ) {
    const sortedApiEvents = apiItem.events
      ? Object.keys(apiItem.events)
          .sort()
          .map((key: string) => apiItem.events[key])
      : [];

    const events = await Promise.all(
      sortedApiEvents.map(
        async (event: IDrivingItemEvent): Promise<IMyDrivingItemEvent> => {
          const authorUserId = event.instructorId || ownerId;
          const user = userStore.user!;
          const authorName =
            (await DrivingItem.getUserName(authorUserId, userDbService)) || "";
          const my = authorUserId === user.firebaseId;
          return {
            authorName,
            comment: event.comment,
            instructorId: event.instructorId,
            my,
            statusChange: event.statusChange,
            ts: event.ts,
          };
        }
      )
    );

    return new DrivingItem(id, text, events);
  }

  private static async getUserName(
    userId: string,
    userDbService: IUserDbService
  ): Promise<string | undefined> {
    if (!userNameCache[userId]) {
      const user = await userDbService.findUser(userId);
      if (user) {
        userNameCache[userId] = `${user.firstName} ${user.lastName}`;
      }
    }

    return userNameCache[userId];
  }
  @observable
  public events: IMyDrivingItemEvent[] = [];

  public readonly id!: string;
  public readonly index!: number;
  public readonly text: string;
  public group!: DrivingGroup;
  public subgroup!: DrivingSubgroup;
  @lazyInject(UserDbServiceSymbol)
  private readonly userDbService!: IUserDbService;

  private constructor(id: string, text: string, events: IMyDrivingItemEvent[]) {
    this.id = id;
    this.text = text;
    this.events = events;
  }

  // TODO Refactor because this resembles get status() in some ways
  public calcNewStatus(change: DrivingItemStatusChange, byInstructor: boolean) {
    switch (change) {
      case DrivingItemStatusChange.Checked:
        switch (this.status) {
          case DrivingItemStatus.Done:
            return this.status;
          case DrivingItemStatus.InDebate:
            return byInstructor ? DrivingItemStatus.Done : this.status;
          case DrivingItemStatus.Unchecked:
            return byInstructor
              ? DrivingItemStatus.Done
              : DrivingItemStatus.InDebate;
          default:
            throw new Error(`Unknown item status ${this.status}`);
        }

      case DrivingItemStatusChange.Unchecked:
        switch (this.status) {
          case DrivingItemStatus.Done:
            return byInstructor ? DrivingItemStatus.Unchecked : this.status;
          case DrivingItemStatus.InDebate:
            return DrivingItemStatus.Unchecked;
          case DrivingItemStatus.Unchecked:
            return this.status;
          default:
            throw new Error(`Unknown item status ${this.status}`);
        }

      default:
        throw new Error(`Unknown item status change ${change}`);
    }
  }

  public calcStatusChange(byInstructor: boolean) {
    switch (this.status) {
      case DrivingItemStatus.Done:
        return byInstructor ? DrivingItemStatusChange.Unchecked : undefined;

      case DrivingItemStatus.InDebate:
        return byInstructor
          ? DrivingItemStatusChange.Checked
          : DrivingItemStatusChange.Unchecked;

      case DrivingItemStatus.Unchecked:
        return DrivingItemStatusChange.Checked;

      default:
        return undefined;
    }
  }
}
