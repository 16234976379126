import { AxiosError } from "axios";
import * as firebase from "firebase/app";
import { ICoreApiError } from "../HttpService/interfaces";

export enum SignInErrorCode {
  InvalidPhone = "invalid_phone_number",
  InvalidConfirmationCode = "invalid_confirmation_code",
}

export interface IAuthService {
  startFirebaseSignInWithPhone(
    phone: string,
    verifier?: firebase.auth.ApplicationVerifier
  ): Promise<firebase.auth.ConfirmationResult>;

  subscribeToAuthStateChange(
    callback: (user: firebase.User | null) => void
  ): firebase.Unsubscribe;

  getCurrentUserToken(): Promise<string | undefined>;

  signInWithEmailPassword(email: string, password: string): Promise<any>;

  confirmPhoneSignIn(
    signInResult: firebase.auth.ConfirmationResult,
    code: string
  ): Promise<any>;

  unlinkCurrentUserEmailPassword(): Promise<any>;

  linkCurrentUserWithEmailPassword(
    email: string,
    password: string
  ): Promise<any>;

  signOut(): Promise<void>;

  updateCurrentUserName(firstName: string, lastName: string): Promise<any>;
}

export interface IError extends Error {
  code: string;
}
// MOVE OT HELPERS
export function isAPIErrors(u: unknown): u is IError {
  if (typeof u !== "object" || u === null) {
    return false;
  }
  return u && typeof (u as any).code === "string";
}

export function isAxiosError(error: AxiosError | any): error is AxiosError {
  return error && error.isAxiosError;
}

export function isCoreApiError(u: unknown): u is ICoreApiError {
  if (typeof u !== "object" || u === null) {
    return false;
  }
  return (
    u &&
    typeof (u as any).error === "string" &&
    typeof (u as any).errorCode === "number"
  );
}
