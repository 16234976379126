import { injectable } from "inversify";
import { computed } from "mobx";
import * as qs from "qs";
import { INativeAppService } from "./interfaces";

@injectable()
export class NativeAppService implements INativeAppService {
  @computed
  public get isWebView(): boolean {
    const isWebView = (window as any).isWebView;
    if (!isWebView) {
      console.error("function isWebView not defined");
      return false;
    }
    return isWebView();
  }
  public sendAuthenticationEvent(
    tempEmail: string,
    tempPassword: string,
    realEmail: string,
    bookingPassword: string
  ) {
    console.debug(
      `Sending email "${realEmail}", booking password "${bookingPassword}", generated email "${tempEmail}" and generated password "${tempPassword}" to the app`
    );
    const q = qs.stringify({
      bookingPwd: bookingPassword,
      email: tempEmail,
      pwd: tempPassword,
      realEmail,
    });
    this.send(`mdacademy://auth?${q}`);
  }

  public sendPageLocation(url: string) {
    const q = qs.stringify({
      page: url,
    });
    console.debug("Sending page location", `mdacademy://page?${q}`);
    this.send(`mdacademy://page?${q}`);
  }

  public sendBoundEvent(): void {
    console.debug("Sending `user bound` event", `mdacademy://userBound`);
    this.send(`mdacademy://userBound`);
  }

  public passSid(sid: string): void {
    const q = qs.stringify({
      sid,
    });
    console.debug("passSid", sid);
    this.send(`mdacademy://checkout?${q}`);
  }

  private send(href: string) {
    if (this.isWebView) {
      window.location.href = href;
    }
  }
}
