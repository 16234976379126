import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import { inject, injectable } from "inversify";
import { SettingsSymbol } from "../../inversify/symbols";
import { ISettings } from "../Settings/interfaces";
import { IFirebaseAccessor } from "./interfaces";

@injectable()
export class FirebaseAccessor implements IFirebaseAccessor {
  public readonly auth: firebase.auth.Auth;
  public readonly database: firebase.database.Database;
  public readonly functions: firebase.functions.Functions;

  public constructor(@inject(SettingsSymbol) settings: ISettings) {
    if (firebase.apps.length === 0) {
      firebase.initializeApp({
        apiKey: settings.fbApiKey,
        authDomain: settings.fbAuthDomain,
        databaseURL: settings.fbDatabaseURL,
        projectId: settings.fbProjectId,
      });
    }

    this.auth = firebase.auth();
    this.database = firebase.database();
    this.functions = firebase.functions();

    //  this.auth.useDeviceLanguage();
  }
}
