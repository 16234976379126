import {
  FirebaseAnalyticsEventDTO,
  GoogleTagManagerEventDTO,
} from "../interfaces";
import { IAnalyticsEvent } from "./types";

interface IGTMFormEvent extends GoogleTagManagerEventDTO {
  event: "form";
  eventAction: string;
  eventCategory: "form";
  eventLabel: string;
}
interface IFirebaseAnalyticsFormEvent extends FirebaseAnalyticsEventDTO {
  name: "form";
  payload: {
    eventAction: string;
    eventLabel: string;
  };
}
export class FormEvent implements IAnalyticsEvent {
  constructor(
    private readonly data: {
      eventAction: string;
      eventLabel: string;
    }
  ) {}

  public toGoogleTagManagerEvent(): IGTMFormEvent {
    return {
      event: "form",
      eventAction: this.data.eventAction,
      eventCategory: "form",
      eventLabel: this.data.eventLabel,
    };
  }

  public toFirebaseAnalyticsEvent(): IFirebaseAnalyticsFormEvent {
    return {
      name: "form",
      payload: {
        eventAction: this.data.eventAction,
        eventLabel: this.data.eventLabel,
      },
    };
  }
}
