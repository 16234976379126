import * as firebase from "firebase/app";
import { inject, injectable } from "inversify";
import { FirebaseAccessorSymbol } from "../../inversify/symbols";
import { IFirebaseAccessor } from "../FirebaseAccessor/interfaces";
import { IDbService } from "./interfaces";

@injectable()
export class DbService implements IDbService {
  private readonly prefix = "/v3";

  public constructor(
    @inject(FirebaseAccessorSymbol)
    private readonly firebaseAccessor: IFirebaseAccessor
  ) {}

  public createQuery(path: string): firebase.database.Query {
    return this.db.ref(this.patchPath(path));
  }

  public async find<T>(path: string) {
    const snapshot = await this.db.ref(this.patchPath(path)).once("value");
    return snapshot.val() as T;
  }

  public set(path: string, value: any) {
    return this.db.ref(this.patchPath(path)).set(value);
  }

  public push(path: string, value: any) {
    const ref = this.db.ref(this.patchPath(path)).push();
    return ref.set(value);
  }

  public batchUpdate(updates: object) {
    const patched = Object.keys(updates).reduce((prev, key) => {
      return {
        ...prev,
        [this.patchPath(key)]: (updates as any)[key],
      };
    }, {});
    return this.db.ref().update(patched);
  }

  public subscribe<T>(path: string, callback: (result: T) => void): () => void {
    const internalCallback = (snapshot: any) => callback(snapshot.val() as T);
    this.db.ref(this.patchPath(path)).on("value", internalCallback);
    return () =>
      this.db.ref(this.patchPath(path)).off("value", internalCallback);
  }

  private get db() {
    return this.firebaseAccessor.database;
  }

  private patchPath(path: string) {
    return `${this.prefix}${path}`;
  }
}
