import { inject, injectable, interfaces } from "inversify";
import { CalendarEventType } from "../../components/bookings/ScheduleScreen/Calendar/interfaces";
import { IDatetime } from "../../helpers/Datetime/interfaces";
import {
  ClassEventModelSymbol,
  DatetimeSymbol,
  FbDrivingExamEventModelSymbol,
  FbTheoryExamEventModelSymbol,
  I18nServiceSymbol,
  InversifyContextSymbol,
  LessonEventModelSymbol,
  SettingsStoreSymbol,
} from "../../inversify/symbols";
import { IBooking } from "../../services/CoreApiService/interfaces";
import { II18nService } from "../../services/I18nService/interfaces";
import {
  ISettingsStore,
  isIntroServiceKey,
  isTrailerServiceKey,
  ServiceKey,
} from "../../stores/SettingsStore/interfaces";
import { IDrivingExam, ITheoryExam } from "../UserModel/interfaces";
import {
  IClassEventModel,
  IEventModelFactory,
  IFbDrivingExamEventModel,
  IFbTheoryExamEventModel,
  ILessonEventModel,
  IPickup,
} from "./interfaces";
import { ClassEventModel } from "./ClassEventModel";
import { LessonEventModel } from "./LessonEventModel";

@injectable()
export class EventModelFactory implements IEventModelFactory {
  constructor(
    @inject(InversifyContextSymbol)
    private readonly inversifyContent: interfaces.Context,
    @inject(DatetimeSymbol) private readonly datetime: IDatetime,
    @inject(I18nServiceSymbol) private readonly i18nService: II18nService,
    @inject(SettingsStoreSymbol) private readonly settingsStore: ISettingsStore
  ) {}
  public eventFromApi(
    apiBooking: IBooking
  ): IClassEventModel | ILessonEventModel {
    if (apiBooking.isClass) {
      return this.courseFromApi(apiBooking);
    } else {
      return this.lessonFromApi(apiBooking);
    }
  }
  public createFbDrivingExamEvent(
    data: IDrivingExam
  ): IFbDrivingExamEventModel {
    const model = this.getFbDrivingExamModel();
    const day = this.datetime.fromDateString(data.date);
    const start = this.datetime.fromTimeString(data.startTime);
    const end = this.datetime.fromTimeString(data.endTime);
    if (!day || !start || !end) {
      throw new Error("Can't create drivingExamEvent");
    }

    const from = this.datetime.fromDayAndTimePart(day, start);
    const to = this.datetime.fromDayAndTimePart(day, end);
    model.setLocation(data.location || "");
    model.setFrom(from.toDate());
    model.setTo(to.toDate());
    model.setName(
      this.i18nService.i18next.t("eventsList.drivingTest").toString()
    );

    return model;
  }

  public createFbTheoryExamEvent(data: ITheoryExam) {
    const model = this.getFbTheoryExamModel();
    const day = this.datetime.fromDateString(data.date);
    const start = this.datetime.fromTimeString(data.startTime);
    const end = this.datetime.fromTimeString(data.endTime);
    if (!day || !start || !end) {
      throw new Error("Can't create theoryExamEvent");
    }

    const from = this.datetime.fromDayAndTimePart(day, start);
    const to = this.datetime.fromDayAndTimePart(day, end);
    model.setLocation(data.location || "");
    model.setFrom(from.toDate());
    model.setTo(to.toDate());
    model.setName(
      this.i18nService.i18next.t("eventsList.theoryTest").toString()
    );

    return model;
  }
  private lessonFromApi(apiBooking: IBooking): ILessonEventModel {
    const model = this.getLessonModel();

    const from = this.datetime.fromDateTimeString(apiBooking.startDate);
    const to = this.datetime.fromDateTimeString(apiBooking.endDate);
    if (!from || !to) {
      throw new Error(`Wrong time format from="${from}", to="${to}"`);
    }
    const key = apiBooking.serviceKey;

    if (!key) {
      throw new Error(`Service not found in courseFromApi`);
    }

    let typeKey: CalendarEventType = CalendarEventType.Lesson;

    if (key === ServiceKey.TheoryLesson) {
      typeKey = CalendarEventType.TheoryLesson;
    }
    if (key === ServiceKey.Test) {
      typeKey = CalendarEventType.Test;
    }

    if (key === ServiceKey.Taxi) {
      typeKey = CalendarEventType.Taxi;
    }
    if (apiBooking.isSimulator) {
      typeKey = CalendarEventType.Simulator;
    }

    const instructor = this.settingsStore.instructors.find(
      (x) => apiBooking.instructorId && x.id === apiBooking.instructorId
    );
    const fallbackInstructorName = apiBooking.instructorName;
    const pickup: IPickup = {
      address: "",
      htmlFormatted: false,
    };

    const locationId: number | undefined =
      apiBooking.locationId ||
      (instructor && Array.from(instructor.locationIds)[0]) ||
      undefined;
    const location = locationId
      ? this.settingsStore.locations.find((x) => x.id === locationId)
      : undefined;
    if (location) {
      pickup.address = location.address || "";
      const lat = location.lat ? Number(location.lat) : 0;
      const lng = location.lat ? Number(location.lng) : 0;
      if (!Number.isNaN(lat) && !Number.isNaN(lng) && lat !== 0 && lng !== 0) {
        pickup.coords = {
          lat,
          lng,
        };
      }
    }

    let nameKey: string;

    if (typeKey === CalendarEventType.TheoryLesson) {
      (nameKey = "eventModel.theoryLesson"), { name: apiBooking.serviceName };
    } else if (apiBooking.isUTB) {
      (nameKey = "eventModel.utbLesson"), { name: apiBooking.serviceName };
    } else if (apiBooking.isDriving) {
      (nameKey = "eventsList.drivingTest"), { name: apiBooking.serviceName };
    } else if (apiBooking.isSimulator) {
      nameKey = "eventsList.simulator";
    } else if (isTrailerServiceKey(key)) {
      nameKey = "eventModel.BELesson";
    } else {
      (nameKey = "eventModel.drivingLesson"), { name: apiBooking.serviceName };
    }

    const name = this.i18nService.i18next
      .t(nameKey, {
        duration: Math.round(to.diff(from) / (60 * 1000)),
        locationTitle: location ? location.name.en : "",
        name: apiBooking.serviceName,
      })
      .toString();

    model.setVehicle(apiBooking.vehicle);

    model.setFrom(from.toDate());
    model.setTo(to.toDate());
    model.setInstructor(instructor);
    model.setFallbackInstructor(fallbackInstructorName);
    model.setName(name);
    model.setPickup(pickup);
    model.setMcLessonType(apiBooking?.mcLessonType || undefined);
    model.setExternalComment(apiBooking?.externalComment || undefined);
    model.setId(apiBooking.id);
    model.setIsDriving(apiBooking.isDriving);
    model.setIsLocked(apiBooking.isLocked);
    model.setIsUTB(apiBooking.isUTB);
    model.setTypeKey(typeKey);
    model.isPaid = apiBooking.isPaid;

    return model;
  }
  private courseFromApi(apiBooking: IBooking): IClassEventModel {
    const model = this.getCourseModel();
    const from = this.datetime.fromDateTimeString(apiBooking.startDate);
    const to = this.datetime.fromDateTimeString(apiBooking.endDate);
    if (!from || !to) {
      throw new Error(`Wrong time format from="${from}", to="${to}"`);
    }
    const key = apiBooking.serviceKey;

    if (!key) {
      throw new Error(`Service not found in courseFromApi`);
    }

    const pickup: IPickup = {
      address: "",
      htmlFormatted: false,
    };

    const locationId: number | undefined = apiBooking.locationId || undefined;
    const location = locationId
      ? this.settingsStore.locations.find(
          (x) => x.id.toString() === locationId.toString()
        )
      : undefined;
    if (location) {
      pickup.address = location.address || "";
      const lat = location.lat ? Number(location.lat) : 0;
      const lng = location.lat ? Number(location.lng) : 0;
      if (!Number.isNaN(lat) && !Number.isNaN(lng) && lat !== 0 && lng !== 0) {
        pickup.coords = {
          lat,
          lng,
        };
      }
    }

    let typeKey: CalendarEventType;
    if (isIntroServiceKey(key)) {
      typeKey = CalendarEventType.Intro;
    } else if (key === ServiceKey.Risk1) {
      typeKey = CalendarEventType.Risk1;
    } else if (key === ServiceKey.Risk2) {
      typeKey = CalendarEventType.Risk2;
    } else if (key === ServiceKey.Moped || key === ServiceKey.MopedDay) {
      typeKey = CalendarEventType.Moped;
    } else if (key === ServiceKey.TheoryGroup) {
      typeKey = CalendarEventType.TheoryGroup;
    } else if (key === ServiceKey.Taxi) {
      typeKey = CalendarEventType.Taxi;
    } else if (key === ServiceKey.TestDriving) {
      typeKey = CalendarEventType.TestDriving;
    } else if (key === ServiceKey.DrivingTest) {
      typeKey = CalendarEventType.DrivingTest;
    } else if (key === ServiceKey.MopedManeuver) {
      typeKey = CalendarEventType.Moped;
    } else if (key === ServiceKey.MopedTraffic) {
      typeKey = CalendarEventType.Moped;
    } else if (key === ServiceKey.MopedTheory) {
      typeKey = CalendarEventType.Moped;
    } else {
      throw new Error(
        `Course type "${key}" not mapped (id="${apiBooking.id}")`
      );
    }
    model.setIsDriving(apiBooking.isDriving);
    model.setFrom(from.toDate());
    model.setTo(to.toDate());
    model.setTypeKey(typeKey);
    model.setName(apiBooking.serviceName);
    model.setId(apiBooking.id);
    model.setPickup(pickup);
    if (model instanceof ClassEventModel || model instanceof LessonEventModel) {
      model.isPaid = apiBooking.isPaid;
    }

    return model;
  }
  private getLessonModel(): ILessonEventModel {
    return this.inversifyContent.container.get<ILessonEventModel>(
      LessonEventModelSymbol
    );
  }
  private getCourseModel(): IClassEventModel {
    return this.inversifyContent.container.get<IClassEventModel>(
      ClassEventModelSymbol
    );
  }

  private getFbTheoryExamModel(): IFbTheoryExamEventModel {
    return this.inversifyContent.container.get<IFbTheoryExamEventModel>(
      FbTheoryExamEventModelSymbol
    );
  }
  private getFbDrivingExamModel(): IFbDrivingExamEventModel {
    return this.inversifyContent.container.get<IFbDrivingExamEventModel>(
      FbDrivingExamEventModelSymbol
    );
  }
}
