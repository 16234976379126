import { injectable } from "inversify";
import { mapNameToMultiLanguageName } from "../../helpers";
import { IApiRegion } from "../../stores/SettingsStore/interfaces";
import { IMultiLanguage } from "../LocationModel/interfaces";
import { IRegionModel } from "./interfaces";

@injectable()
export class RegionModel implements IRegionModel {
  public readonly id: number;
  public readonly name: IMultiLanguage;
  public readonly description: IMultiLanguage;
  public readonly locationsIds: number[];
  readonly isVisible: boolean;

  constructor(private readonly apiRegion: IApiRegion) {
    this.id = apiRegion.id;
    this.name = mapNameToMultiLanguageName(
      `${apiRegion.name.en} ·`,
      `${apiRegion.name.sw} ·`
    );
    this.description = mapNameToMultiLanguageName(
      apiRegion.description.en,
      apiRegion.description.sw
    );
    this.locationsIds = apiRegion.locations.map((location) => location.id);
    this.isVisible = apiRegion?.isVisible ?? true;
  }
}
