import classNames from "classnames";
import * as React from "react";
import styles from "./styles.scss";

interface IHorizontalRuleProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHRElement>,
    HTMLHRElement
  > {}

export function HorizontalRule(props: IHorizontalRuleProps) {
  const { className, ...otherProps } = props;
  return <hr className={classNames(styles.rule, className)} {...otherProps} />;
}
