import { inject, injectable } from "inversify";
import {
  action,
  computed,
  IReactionDisposer,
  observable,
  ObservableMap,
  reaction,
  runInAction,
  when,
} from "mobx";
import {
  DrivingPlanDbServiceSymbol,
  NativeAppServiceSymbol,
  SettingsStoreSymbol,
  UiStoreSymbol,
  UserDbServiceSymbol,
  UserStoreSymbol,
} from "../../inversify/symbols";
import { DrivingGroup } from "../../models/driving/DrivingGroup";
import { DrivingGroupSummary } from "../../models/driving/DrivingGroupSummary";
import {
  DrivingItem,
  DrivingItemStatus,
} from "../../models/driving/DrivingItem";
import { DrivingSubgroup } from "../../models/driving/DrivingSubgroup";
import {
  DrivingGroupStatus,
  IDrivingGroup,
  IDrivingGroupsSummary,
  IDrivingItem,
  IDrivingPlan,
  IDrivingPlanCounters,
  IDrivingPlanDbService,
  IDrivingPlanMeta,
} from "../../services/DrivingPlanDbService/interfaces";
import { INativeAppService } from "../../services/NativeAppService/interfaces";
import { IUserDbService } from "../../services/UserDbService/interfaces";
import { ISettingsStore } from "../SettingsStore/interfaces";
import { IUiStore } from "../UiStore/interfaces";
import { IUserStore } from "../UserStore/interfaces";
import { IActiveGroup, IDrivingStore } from "./interfaces";
/* tslint:disable */
// TODO: refactor this
@injectable()
export class DrivingStore implements IDrivingStore {
  @computed public get loading() { return this._loading; }
  @computed public get groupSummaries() { return this._groupSummaries; }
  @computed public get ownerId() { return this._ownerId; }

  @observable public feed = false;
  @observable public multipleActiveGroupsAllowed: boolean = false;

  @observable private _ownerId: string = "";
  @observable private _groupSummaries: DrivingGroupSummary[] = [];
  @observable private _activeGroups: ObservableMap<string, IActiveGroup> = observable.map({});
  @observable private _loading = false;
  @observable private planMeta!: IDrivingPlanMeta;
  @observable private counters?: IDrivingPlanCounters; // TODO don't use db model

  private unsubSummary?: (() => void);
  private unsubCounters?: (() => void);
  private disposers: IReactionDisposer[] = [];

  @computed
  public get sortedActiveGroups() {
    return Array.from(this._activeGroups
      .values())
      .map(info => info.group)
      .sort((g1, g2) => {
        const idx1 = this.planMeta.structure.groups.findIndex(g => g.id === g1.id);
        const idx2 = this.planMeta.structure.groups.findIndex(g => g.id === g2.id);
        return idx1 - idx2;
      });
  }

  @computed
  public get itemFeed() {
    if (!this.feed)
      return [];

    const items: DrivingItem[] = [];
    for (const info of Array.from(this._activeGroups.values())) {
      const subgroupsItems = info.group.subgroups.map(sg => sg.items);
      subgroupsItems.forEach(sgItems => items.push(...sgItems.filter(item => !!item.latestEvent)));
    }

    return items.sort((i1, i2) => i2.latestEvent!.ts - i1.latestEvent!.ts);
  }

  public constructor(
    @inject(UiStoreSymbol) private readonly uiStore: IUiStore,
    @inject(UserStoreSymbol) private readonly userStore: IUserStore,
    @inject(UserDbServiceSymbol) private readonly userDbService: IUserDbService,
    @inject(SettingsStoreSymbol) private readonly settingsStore: ISettingsStore,
    @inject(DrivingPlanDbServiceSymbol) private readonly planDbService: IDrivingPlanDbService,
    @inject(NativeAppServiceSymbol) private readonly nativeAppService: INativeAppService
  ) {
  }

  public async init(ownerId?: string) {

    this.disposers.push(
      reaction(
        () => this.multipleActiveGroupsAllowed,
        allowMultipleActiveGroups => !allowMultipleActiveGroups && this.deactivateAllGroups()
      ),

      reaction(
        () => this.feed && this._groupSummaries.length,
        async () => {
          await this.deactivateAllGroups();
          if (this.feed)
            await this.activateAllGroups();
        }
      )
    );
    await when(
      () => !!this.userStore.user
    );
    const studentId = this.uiStore.query.studentBookingId;
    const user = this.userStore.user!;
    await this.reload(ownerId || studentId || user.firebaseId);
  }

  public async stop() {
    await this.deactivateAllGroups();

    if (this.unsubSummary) {
      this.unsubSummary();
      this.unsubSummary = undefined;
    }

    if (this.unsubCounters) {
      this.unsubCounters();
      this.unsubCounters = undefined;
    }

    this.disposers.forEach(d => d());
  }

  public async resetPlan(ownerId: string) {
    const metaId = this.settingsStore.drivingPlanMetaId;
    const meta = await this.planDbService.getPlanMeta(metaId);
    const plan = DrivingStore.createFreshPlanFromMeta(metaId, meta);
    await this.planDbService.writePlan(ownerId, plan);
  }

  public async toggleActiveGroup(groupId: string) {
    const deactivated = await this.deactivateGroup(groupId);
    if (!deactivated)
      await this.activateGroup(groupId);
  }

  public async addItemComment(item: DrivingItem, comment: string) {
    const user = this.userStore.user!;
    const instructorId = user.isInstructor ? user.firebaseId : undefined;
    const newSummaryStatus = item.group.calcNewSummaryStatus(item.id, void 0, comment);

    await this.planDbService.addItemComment(this.ownerId, item.group.id, item.id, comment, instructorId);
    await this.planDbService.batchUpdate({
      ...this.planDbService.setGroupSummaryStatus(this.ownerId, item.group.id, newSummaryStatus),
      ...this.planDbService.touchGroupSummary(this.ownerId, item.group.id),
      ...this.planDbService.markGroupSummaryAsRead(this.ownerId, item.group.id,user.firebaseId),
      ...(instructorId ? this.planDbService.touchPlanAsInstructor(instructorId, this.ownerId) : {})
    });

    await this.updateCounters(counters => {
      if (instructorId)
        counters.instructorComments += 1;
      else
        counters.studentComments += 1;
    });
  }

  public async switchItemStatus(item: DrivingItem) {
    const user = this.userStore.user!;
    const isInstructor = user.isInstructor;
    const instructorId = isInstructor? user.firebaseId : undefined;
    const change = item.calcStatusChange(isInstructor);

    if (change) {
      const newItemStatus = item.calcNewStatus(change, isInstructor);
      const oldItemStatus = item.status;
      const newSummaryStatus = item.group.calcNewSummaryStatus(item.id, newItemStatus);
      await this.planDbService.addItemStatusChange(this.ownerId, item.group.id, item.id, change, instructorId);
      await this.planDbService.batchUpdate({
        ...this.planDbService.setGroupSummaryStatus(this.ownerId, item.group.id, newSummaryStatus),
        ...this.planDbService.markGroupSummaryAsRead(this.ownerId, item.group.id, user.firebaseId),
        ...(isInstructor ? this.planDbService.touchPlanAsInstructor(instructorId!, this.ownerId) : {})
      });
      await this.updateCounters(counters => {
        switch (newItemStatus) {
          case DrivingItemStatus.Done:
            counters.finishedItems += 1;
            if (oldItemStatus === DrivingItemStatus.InDebate)
              counters.inProgressItems = Math.max(counters.inProgressItems - 1, 0);
            break;
          case DrivingItemStatus.InDebate:
            counters.inProgressItems += 1;
            // Not really possible to switch from Done to inProgress, but who knows...
            if (oldItemStatus === DrivingItemStatus.Done)
              counters.finishedItems = Math.max(counters.finishedItems - 1, 0);
            break;
          case DrivingItemStatus.Unchecked:
            if (oldItemStatus === DrivingItemStatus.Done)
              counters.finishedItems = Math.max(counters.finishedItems - 1, 0);
            else if (oldItemStatus === DrivingItemStatus.InDebate)
              counters.inProgressItems = Math.max(counters.inProgressItems - 1, 0);
            break;
        }
      });
    }
  }

  private async deactivateAllGroups() {
    await Promise.all(
      this._groupSummaries
        .map(s => s.groupId)
        .map(groupId => this.deactivateGroup(groupId))
    );
  }

  private async updateCounters(update: (counters: IDrivingPlanCounters) => void) {
    const counters = await this.planDbService.getPlanCounters(this.ownerId);
    update(counters);
    const batch = this.planDbService.updatePlanCounters(this.ownerId, counters);
    await this.planDbService.batchUpdate(batch);
  }

  private async activateGroup(groupId: string) {
    if (this._activeGroups.has(groupId))
      return;

    let deactivatePrevId: string | undefined;
    if (!this.multipleActiveGroupsAllowed && this._activeGroups.size)
      deactivatePrevId = Array.from(this._activeGroups.keys())[0];

    const unsub = this.planDbService.subscribeToGroup(
      this.ownerId,
      groupId,
      async (apiGroup: IDrivingGroup) => {
        const summary = this._groupSummaries.find(s => s.groupId === groupId)!;
        const group = await this.convertGroup(groupId, apiGroup, summary);
        runInAction(() => this._activeGroups.set(groupId, { group, unsub }));
        if (deactivatePrevId) {
          await this.deactivateGroup(deactivatePrevId);
          deactivatePrevId = undefined;
        }
      }
    );

    await this.markGroupAsRead(groupId);
  }

  private async deactivateGroup(groupId: string) {
    const info = this._activeGroups.get(groupId);
    if (info) {
      info.unsub();
      runInAction(() => this._activeGroups.delete(groupId));
      await this.markGroupAsRead(groupId);
    }
    return !!info;
  }

  private activateAllGroups() {
    return Promise.all(
      this._groupSummaries
        .map(s => s.groupId)
        .map(groupId => this.activateGroup(groupId))
    );
  }

  @action
  private async reload(ownerId: string) {
    this._ownerId = ownerId;
    this._loading = true;

    const plan = await this.planDbService.getPlan(ownerId);
    const planMeta = await this.planDbService.getPlanMeta(plan.metaId);

    this.unsubSummary = this.planDbService.subscribeToGroupsSummary(ownerId, this.handleNewSummary);

    runInAction(() => {
      this.planMeta = planMeta;
    });
  }

  @computed
  private get planStrings() {
    return this.planMeta.strings[this.uiStore.planMetaLocale];
  }

  private markGroupAsRead(groupId: string) {
    const user = this.userStore.user!;
    return this.planDbService.batchUpdate({
      ...this.planDbService.markGroupSummaryAsRead(this.ownerId, groupId, user.firebaseId)
    });
  }

  @action.bound
  private handleNewSummary(summary: IDrivingGroupsSummary) {
    this._loading = false; // TODO refactor
    const user = this.userStore.user!;

    this._groupSummaries = Object
      .keys(summary)
      .sort((id1, id2) => {
        const idx1 = this.planMeta.structure.groups.findIndex(g => g.id === id1);
        const idx2 = this.planMeta.structure.groups.findIndex(g => g.id === id2);
        return idx1 - idx2;
      })
      .map((groupId, idx) => {
        const groupSummary = summary[groupId];
        const lastReadTime = (groupSummary.lastReadTime && groupSummary.lastReadTime[user.firebaseId]);
        const newEvents = (groupSummary.ts && lastReadTime && lastReadTime < groupSummary.ts);
        return new DrivingGroupSummary(
          groupId,
          groupSummary.status || DrivingGroupStatus.NotStarted,
          !!newEvents,
          idx + 1
        );
      });
  }

  private async convertGroup(groupId: string, apiGroup: IDrivingGroup, summary: DrivingGroupSummary) {
    const structure = this.planMeta.structure.groups.find(g => g.id === groupId)!; // must exist

    const group = new DrivingGroup();
    group.id = groupId;
    group.title = this.planStrings.groupTitles[groupId];
    group.order = summary.groupOrder;
    group.subgroups = await Promise.all(
      structure.subgroups
        .map(async sg => {
          const subgroup = new DrivingSubgroup();
          subgroup.id = sg.id;
          subgroup.title = this.planStrings.subgroupTitles[sg.id];
          subgroup.items = await Promise.all(
            sg.itemIds
              .map(async itemId => {
                const apiItem = apiGroup[itemId] as IDrivingItem;
                const item = await this.convertItem(itemId, apiItem);
                item.group = group;
                item.subgroup = subgroup;
                return item;
              })
          );
          return subgroup;
        })
    );

    return group;
  }

  private async convertItem(itemId: string, apiItem: IDrivingItem) {
    const text = this.planStrings.itemTexts[itemId];
    return await DrivingItem.fromApi(itemId, apiItem, text, this.ownerId, this.userStore, this.userDbService);
  }

  private static createFreshPlanFromMeta(metaId: string, meta: IDrivingPlanMeta): IDrivingPlan {
    const groups: any = {};
    const groupsSummary: any = {};

    for (const g of meta.structure.groups) {
      const group: IDrivingGroup = {};
      groups[g.id] = group;
      groupsSummary[g.id] = {
        status: DrivingGroupStatus.NotStarted,
        lastReadTime: {},
        ts: 0
      };

      for (const sg of g.subgroups) {
        for (const itemId of sg.itemIds) {
          group[itemId] = { dummy: 0 };
        }
      }
    }

    return {
      metaId,
      groups,
      groupsSummary,
      counters: {
        finishedItems: 0,
        inProgressItems: 0,
        studentComments: 0,
        instructorComments: 0
      }
    };
  }
}
