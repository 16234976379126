//@ts-ignore
import { Config } from "@bugsnag/core/types/common";
import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";
import * as React from "react";

const bugsnagConfig: Config = {
  apiKey: "e8134ed5ada4ac08d7670b221fc89f14",
  maxBreadcrumbs: 40,
  // notifyReleaseStages: ["production"],
};
export const bugsnagClient = bugsnag(bugsnagConfig);
bugsnagClient.use(bugsnagReact, React);
export const ErrorBoundary = bugsnagClient.getPlugin("react");
