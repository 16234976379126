import { injectable } from "inversify";
import { mapNameToMultiLanguageName } from "../../helpers";
import { IApiLocation, ILocationModel, IMultiLanguage } from "./interfaces";

@injectable()
export class LocationModel implements ILocationModel {
  public readonly id: number;
  public readonly name: IMultiLanguage;
  public readonly description: IMultiLanguage;
  public readonly address: string;
  public readonly addressHints: string | null;
  public readonly city: string;
  public readonly zip: string;
  public readonly lat: string;
  public readonly lng: string;
  public readonly listOrder: number;
  public readonly availableServicesIds: number[];
  public readonly availableInstructorsIds?: number[];
  public readonly regionId: number;
  public readonly isVisible: boolean;

  constructor(private readonly apiLocation: IApiLocation) {
    this.id = apiLocation.id;
    this.name = mapNameToMultiLanguageName(
      `${apiLocation.name.en} ·`,
      `${apiLocation.name.sw} ·`
    );
    this.description = mapNameToMultiLanguageName(
      apiLocation.description.en,
      apiLocation.description.sw
    );
    this.address = apiLocation.address;
    this.addressHints = apiLocation.addressHints;
    this.city = apiLocation.city;
    this.zip = apiLocation.zip;
    this.lat = apiLocation.lat;
    this.lng = apiLocation.lng;
    this.listOrder = apiLocation.listOrder || 0;
    this.availableServicesIds = apiLocation.associationsIds?.services || [];
    this.availableInstructorsIds = apiLocation.associationsIds?.users;
    this.regionId = apiLocation.regionId;
    this.isVisible = apiLocation.isVisible;
  }
}
