import {
  FirebaseAnalyticsEventDTO,
  GoogleTagManagerEventDTO,
} from "../interfaces";
import { IAnalyticsEvent } from "./types";

interface IGTMSignedInEvent extends GoogleTagManagerEventDTO {
  event: "account";
  eventCategory: "account";
  eventAction: "signedIn";
  eventLabel: undefined;
}
interface IFirebaseAnalyticsSignedInEvent extends FirebaseAnalyticsEventDTO {
  name: "signedIn";
}
export class SignedInEvent implements IAnalyticsEvent {
  public toGoogleTagManagerEvent(): IGTMSignedInEvent {
    return {
      event: "account",
      eventAction: "signedIn",
      eventCategory: "account",
      eventLabel: undefined,
    };
  }

  public toFirebaseAnalyticsEvent(): IFirebaseAnalyticsSignedInEvent {
    return {
      name: "signedIn",
      payload: {},
    };
  }
}
