import classNames from "classnames";
import React from "react";
import { StyleType } from "../../../../styles/interfaces";
import styles from "./styles.scss";

interface IButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  styleType?: StyleType;
  textButton?: boolean;
  iconButton?: boolean;
  busy?: boolean;
}

export function Button(props: IButtonProps) {
  const {
    styleType = StyleType.Secondary,
    className,
    textButton = false,
    iconButton = false,
    busy = false,
    disabled = false,
    children,
    ...tagProps
  } = props;
  return (
    <button
      className={classNames(
        styles.button,
        styles[styleType],
        { [styles.textButton]: textButton },
        { [styles.iconButton]: iconButton },
        className
      )}
      disabled={disabled || busy}
      type={props.type ? props.type : "button"}
      {...tagProps}
    >
      <div className={styles.content}>
        {children}
        {busy && <div className={styles.loader} />}
      </div>
    </button>
  );
}
