import * as firebase from "firebase/app";
import type { History, Location } from "history";
import { inject, injectable } from "inversify";
import { action, flow, observable } from "mobx";
import { CancellablePromise } from "mobx/lib/api/flow";
import qs from "qs";
import { bugsnagClient } from "../../bugsnag";
import {
  ApiServiceSymbol,
  AuthServiceSymbol,
  FirebaseAccessorSymbol,
  HistorySymbol,
  NativeAppServiceSymbol,
  UiStoreSymbol,
  UserDbServiceSymbol,
  UserModelFactorySymbol,
  UserSettingsStoreSymbol,
} from "../../inversify/symbols";
import {
  IUserModel,
  IUserModelFactory,
  IUserProfile,
} from "../../models/UserModel/interfaces";
import { IApiService } from "../../services/ApiService/interfaces";
import { IAuthService } from "../../services/AuthService/interfaces";
import { IFirebaseAccessor } from "../../services/FirebaseAccessor/interfaces";
import { INativeAppService } from "../../services/NativeAppService/interfaces";
import { IUserDbService } from "../../services/UserDbService/interfaces";
import { ERROR_CODE, IUiStore } from "../UiStore/interfaces";
import { IUserStore } from "./interfaces";
import { UserSettingsStore } from "./UserSettingsStore";
import { Subject } from "rxjs";

@injectable()
export class UserStore implements IUserStore {
  @observable
  public fbUser: firebase.User | undefined;
  @observable
  public user: IUserModel | undefined;
  @observable
  public loading = true;
  @observable
  public initialRoute!: Location;

  @action
  setInitialRoute(route: Location) {
    this.initialRoute = route;
  }

  private loadedSubject = new Subject<boolean>();
  readonly loaded = this.loadedSubject.asObservable().toPromise();

  private readonly anonymousUserId: string | undefined;

  private handlingFirebaseUserChanged?: CancellablePromise<unknown>;
  private handlingUsersProfileChanged?: CancellablePromise<unknown>;

  private readonly handleFirebaseUserChangedFlow = flow(function* (
    this: UserStore,
    firebaseUser: firebase.User | null
  ) {
    this.setLoading(true);
    try {
      if (firebaseUser && firebaseUser.phoneNumber) {
        yield this.logEvents(
          firebaseUser.phoneNumber,
          `handleFirebaseUserChangedFlow`
        );
      }
      const mdaUser: IUserModel | undefined = firebaseUser
        ? yield this.userModelFactory.createById(firebaseUser.uid)
        : undefined;
      if (firebaseUser && firebaseUser.phoneNumber) {
        yield this.logEvents(
          firebaseUser.phoneNumber,
          `handleFirebaseUserChangedFlow, user ${
            mdaUser ? `found` : "not found"
          }`
        );
      }
      if (mdaUser) {
        const [profile, settings] = yield Promise.all([
          mdaUser.refreshProfile(),
          mdaUser.refreshSettings(),
          mdaUser.refreshDashboardCounts(),
          mdaUser.defineNewbie(),
        ]);
        this.userSettingsStore?.setSettings(settings);
        this.userSettingsStore?.setUserId(mdaUser.id);

        if (this.anonymousUserId) {
          yield mdaUser.bindAnonymousUserId(this.anonymousUserId);
          yield this.nativeAppService.sendBoundEvent();
        }
      }
      this.setUser(mdaUser);
    } finally {
      this.setLoading(false);
      this.loadedSubject.next(true);
      this.loadedSubject.complete();
    }
  }).bind(this);

  private readonly handleUsersProfileChangedFlow = flow(function* (
    this: UserStore,
    profile: IUserProfile | null | undefined
  ) {
    console.debug(
      "Received changing user's profile notification. Refresh profile"
    );
    const { user } = this;
    if (user) {
      yield user.refreshProfile();
      yield user.refreshDashboardCounts();
    }
  }).bind(this);

  private usersProfileDisposer?: () => void;

  constructor(
    @inject(AuthServiceSymbol) private authService: IAuthService,
    @inject(FirebaseAccessorSymbol) private fbAccessor: IFirebaseAccessor,
    @inject(UserDbServiceSymbol) private userDbService: IUserDbService,
    @inject(ApiServiceSymbol) private apiService: IApiService,
    @inject(UserModelFactorySymbol) private userModelFactory: IUserModelFactory,
    @inject(NativeAppServiceSymbol) private nativeAppService: INativeAppService,
    @inject(UiStoreSymbol) private uiStore: IUiStore,
    @inject(HistorySymbol) private history: History,
    @inject(UserSettingsStoreSymbol)
    private userSettingsStore: UserSettingsStore
  ) {
    this.anonymousUserId = this.extractAnonymousUserId();
    (async () => {
      try {
        await this.tryToSubscribeWithCustomToken();
      } catch (err) {
        console.error("Custom token authentication failed");
      }

      if (!this.fbUser && !this.uiStore.features.disableLegacyAuthentication) {
        try {
          await this.tryToMakeNativeAuthorization();
        } catch (err) {
          console.error("Native email/password authentication failed");
        }
      }

      this.authService.subscribeToAuthStateChange((firebaseUser) => {
        this.setFbUser(firebaseUser);
        this.handleFirebaseUserChanged(firebaseUser).catch((err) =>
          console.error(err)
        );
      });
    })().catch((err) => console.error(err));
  }

  public async signInWithCustomToken(token: string) {
    await this.fbAccessor.auth.signInWithCustomToken(token);
  }

  public async forceReloadUser(): Promise<void> {
    const { currentUser } = this.fbAccessor.auth;
    await this.handleFirebaseUserChanged(currentUser);
  }

  public async logEvents(phone: string, type: string): Promise<void> {
    const key = this.fbAccessor.database.ref(`/v3/userLogs`).push().key;
    const payload = {
      anonymousUId: this.anonymousUserId || "",
      date: firebase.database.ServerValue.TIMESTAMP,
      phone,
      type,
      uid: this.fbUser ? this.fbUser.uid : "",
    };
    await this.fbAccessor.database.ref(`/v3/userLogs/${key}`).update(payload);
  }

  public async startSubscribe(phone: string) {
    const pattern = /\d+/g;
    const phoneNumbers = phone.match(pattern);
    const phoneNumber = phoneNumbers && phoneNumbers.join("");

    if (!phoneNumber) {
      return;
    }
    this.userDbService.subscribeToCodes(phoneNumber, async (code) => {
      // tslint:disable-next-line: no-console
      if (code) {
        const customToken = await this.userDbService.checkCode(
          phone,
          `${code}`,
          true
        );

        if (customToken) {
          await this.signInWithCustomToken(customToken);
        }
      }
    });
  }

  private extractAnonymousUserId(): string | undefined {
    const query = this.history.location.search.replace("?", "");
    const parsedQuery = qs.parse(query);
    return isString(parsedQuery.anonymousUserId)
      ? parsedQuery.anonymousUserId
      : undefined;
  }

  private async tryToSubscribeWithCustomToken(): Promise<void> {
    const query = this.history.location.search.replace("?", "");
    const parsedQuery = qs.parse(query);
    if (parsedQuery.authToken && isString(parsedQuery.authToken)) {
      await this.signInWithCustomToken(parsedQuery.authToken);
    }
  }

  @action
  private setFbUser(fbUser: firebase.User | null) {
    this.fbUser = fbUser || undefined;
  }

  private async handleUsersProfileChanged(
    profile: IUserProfile | null | undefined
  ): Promise<void> {
    if (this.handlingUsersProfileChanged) {
      this.handlingUsersProfileChanged.cancel();
    }
    this.handlingUsersProfileChanged =
      this.handleUsersProfileChangedFlow(profile);
    try {
      await this.handlingUsersProfileChanged;
    } catch (e) {
      if (e instanceof Error && e.message !== "FLOW_CANCELLED") {
        throw e;
      }
    }
  }

  private async handleFirebaseUserChanged(
    firebaseUser: firebase.User | null
  ): Promise<void> {
    if (this.handlingFirebaseUserChanged) {
      this.handlingFirebaseUserChanged.cancel();
    }
    this.handlingFirebaseUserChanged =
      this.handleFirebaseUserChangedFlow(firebaseUser);
    try {
      await this.handlingFirebaseUserChanged;
    } catch (e) {
      if (e instanceof Error && e.message !== "FLOW_CANCELLED") {
        this.uiStore.setError(ERROR_CODE.NetworkError);
        if (firebaseUser && firebaseUser.phoneNumber) {
          await this.logEvents(
            firebaseUser.phoneNumber,
            `handleFirebaseUserChanged Error: ${e.message}`
          );
        }
        throw e;
      }
    } finally {
      const user = this.user;
      if (this.usersProfileDisposer) {
        this.usersProfileDisposer();
      }
      if (user) {
        this.usersProfileDisposer = this.userDbService.subscribeToProfileChange(
          user.id,
          (profile) => {
            this.handleUsersProfileChanged(profile).catch((err) => {
              this.uiStore.setError(ERROR_CODE.NetworkError);
              console.error(err);
            });
          }
        );
      }
    }
  }

  @action
  public setUser(user: IUserModel | undefined) {
    this.user = user;
    bugsnagClient.user = {
      user: user
        ? {
            displayName: user.displayName,
            firebaseId: user.firebaseId,
            id: user.id,
          }
        : {},
    };
  }

  @action
  private setLoading(val: boolean) {
    this.loading = val;
  }

  private async tryToMakeNativeAuthorization() {
    const query = qs.parse(this.history.location.search.replace("?", ""));
    const email: string | undefined = isString(query.email)
      ? query.email
      : undefined;
    const password: string | undefined = isString(query.password)
      ? query.password
      : undefined;
    if (email && password) {
      await this.authWithProvidedFromNativeAppCredentials(email, password);
    } else {
      console.debug(
        "Application opened without native-provided email and password"
      );
    }
  }

  private async authWithProvidedFromNativeAppCredentials(
    queryEmail: string,
    queryPassword: string
  ) {
    console.debug("Authenticating with native-provided email/password");
    await this.authService.signInWithEmailPassword(queryEmail, queryPassword);
  }
}

export function isString(u: unknown): u is string {
  return typeof u === "string";
}
