import { inject, injectable } from "inversify";
import { action, computed, observable, runInAction } from "mobx";
import * as uuid from "uuid";
import { bugsnagClient } from "../../../bugsnag";
import {
  AnalyticsManagerSymbol,
  AuthServiceSymbol,
  CoreApiServiceSymbol,
  FirebaseAccessorSymbol,
  I18nServiceSymbol,
  SettingsStoreSymbol,
  UiStoreSymbol,
  UserModelFactorySymbol,
  UserStoreSymbol,
} from "../../../inversify/symbols";
import { ErrorMessage } from "../../../models/ErrorMessage";
import { PendingStatus } from "../../../models/PendingStatus";
import { IUserModelFactory } from "../../../models/UserModel/interfaces";
import { IAnalytics } from "../../../services/AnalyticsManager/interfaces";
import {
  IAuthService,
  isCoreApiError,
} from "../../../services/AuthService/interfaces";
import {
  IClientData,
  ICoreApiService,
} from "../../../services/CoreApiService/interfaces";
import { IFirebaseAccessor } from "../../../services/FirebaseAccessor/interfaces";
import { II18nService } from "../../../services/I18nService/interfaces";
import { IUiStore } from "../../../stores/UiStore/interfaces";
import { IUserStore } from "../../../stores/UserStore/interfaces";
import { ISignUpScreenModel } from "./interfaces";
import { LanguagesSpoken } from "@/models/InstructorModel/interfaces";
import {
  ICityOption,
  IRegionOption,
} from "@/components/shared/forms/booking/interfaces";
import { ISettingsStore } from "@/stores/SettingsStore/interfaces";
import { FormFieldState } from "@/models/FormFieldState";
import { IMultiLanguage } from "../../../models/LocationModel/interfaces";

@injectable()
export class SignUpScreenModel implements ISignUpScreenModel {
  @observable
  public currState = 0;
  @observable
  public phone = "";
  @observable
  public firstName = "";
  @observable
  public firstNameStatus: boolean | undefined;
  @observable
  public lastName = "";
  @observable
  public lastNameStatus: boolean | undefined;

  @observable
  public ssn = "";
  @observable
  public ssnStatus: boolean | undefined;

  @observable
  public email = "";
  @observable
  public locationId;
  @observable
  public emailStatus: boolean | undefined;
  @observable
  public phoneStatus: boolean | undefined;

  @observable
  public marketingAccepted = false;
  @observable
  public eulaAccepted = false;
  public pendingStatus = new PendingStatus();
  public errorStatus = new ErrorMessage();

  @observable
  public cityStatus: boolean | undefined;
  @observable
  public regionStatus: boolean | undefined;

  constructor(
    @inject(FirebaseAccessorSymbol)
    private readonly fbAccessor: IFirebaseAccessor,
    @inject(UiStoreSymbol) private readonly uiStore: IUiStore,
    @inject(UserStoreSymbol) private readonly userStore: IUserStore,
    @inject(AuthServiceSymbol) private readonly authService: IAuthService,
    @inject(I18nServiceSymbol) private readonly i18nService: II18nService,
    @inject(AnalyticsManagerSymbol) private readonly gtm: IAnalytics,
    @inject(UserModelFactorySymbol) private userModelFactory: IUserModelFactory,
    @inject(CoreApiServiceSymbol) private coreApiService: ICoreApiService,
    @inject(SettingsStoreSymbol) private readonly settingsStore: ISettingsStore,
    @inject(I18nServiceSymbol) private readonly i18n: II18nService
  ) {
    const { user } = this.userStore;
    if (user) {
      throw new Error("User is signed up already");
    }
    const { currentUser } = this.fbAccessor.auth;
    this.phone = (currentUser && currentUser.phoneNumber) || "";
    if (this.phone) {
      this.phoneStatus = true;
    }
  }
  @computed
  public get regionOptions(): IRegionOption[] {
    let options = this.settingsStore.regions
      .slice()
      .filter((r) => r.isVisible)
      .map((location) => {
        return {
          label: location.name[this.i18n.currentLanguage],
          model: location,
          value: location.id,
        };
      });
    let sortedOptions = options.sort((a, b) => {
      try {
        return a.label.localeCompare(b.label, this.i18n.currentLanguage, {
          numeric: true,
        });
      } catch (e) {
        return 0;
      }
    });
    return [
      ...sortedOptions,
      {
        label: "Other",
        model: {
          id: 0,
          name: { sv: "", en: "" },
          description: { sv: "", en: "" },
          locationsIds: [],
          isVisible: false,
        },
        value: 0,
      },
    ];
  }
  @computed
  public get cityOptions(): ICityOption[] {
    const { locations } = this.settingsStore;

    const filteredLocations = locations
      .filter((location) => location.isVisible)
      .filter(
        (location) =>
          //location.isVisible
          location.regionId === this.selectedRegion.value?.value
      );
    console.debug(
      "locations filtered according to originalService",
      locations,
      filteredLocations
    );
    let options = filteredLocations.map((location) => {
      return {
        label: location.name[this.i18n.currentLanguage],
        model: location,
        value: location.id,
      };
    });
    return options.sort((a, b) => {
      try {
        return a.label.localeCompare(b.label, this.i18n.currentLanguage, {
          numeric: true,
        });
      } catch (e) {
        return 0;
      }
    });
  }

  @action
  public reset() {
    this.errorStatus.clear();
  }

  public async signUp() {
    this.errorStatus.clear();

    this.setPhone(this.phone.trim()); // dirty fix;
    this.setEmail(this.email.trim()); // dirty fix;
    this.pendingStatus.startPending();

    try {
      const newClient = await this.registerNewUser();
      await this.userStore.forceReloadUser();
      await this.finalizeSignUp(newClient);
      this.gtm.signedUp().catch((err) => console.error(err));
    } catch (e) {
      console.error(`Sign up failed: ${e}`, e);
      this.notifyBugsnag(e);

      if (isCoreApiError(e)) {
        this.errorStatus.setMessage(
          this.i18nService.i18next.t(`errors.${e.errorCode}`).toString()
        );
      } else {
        this.errorStatus.setMessage(
          this.i18nService.i18next.t("signUp.failed").toString()
        );
      }
    } finally {
      runInAction(() => {
        this.pendingStatus.stopPending();
      });
    }
  }

  @action
  public setPhone(val: string) {
    this.phone = val;
  }
  @action
  public setCurrState(val: number) {
    this.currState = val;
  }

  @action
  public setFirstName(val: string) {
    this.firstName = val;
  }

  @action
  public setLastName(val: string) {
    this.lastName = val;
  }

  @action
  public setSsn(val: string) {
    this.ssn = val;
  }

  @action
  public setEmail(val: string) {
    this.email = val;
  }

  @action
  public setMarketingAccepted(val: boolean) {
    this.marketingAccepted = val;
  }
  @action
  public setEulaAccepted(val: boolean) {
    this.eulaAccepted = val;
  }

  @action
  public setFirstNameStatus(val: boolean | undefined) {
    this.firstNameStatus = val;
  }
  @action
  public setLastNameStatus(val: boolean | undefined) {
    this.lastNameStatus = val;
  }
  @action
  public setSsnStatus(val: boolean | undefined) {
    this.ssnStatus = val;
  }
  @action
  public setRegionStatus(val: boolean | undefined) {
    this.regionStatus = val;
  }
  @action
  public setCityStatus(val: boolean | undefined) {
    this.cityStatus = val;
  }
  @action
  public setEmailStatus(val: boolean | undefined) {
    this.emailStatus = val;
  }
  @action
  public setPhoneStatus(val: boolean | undefined) {
    this.phoneStatus = val;
  }
  @action
  public selectCity(
    city: ICityOption | undefined,
    setTouched: boolean = false
  ) {
    this.selectedCity.setValue(city);

    if (!this.selectedRegion.value?.value) {
      this.locationId = null;
    }
    if (this.selectedCity.value?.value) {
      this.locationId = this.selectedCity.value?.value;
    }
    if (setTouched) {
      this.selectedCity.setTouched(true);
    }
  }

  @action
  public selectRegion(
    region: IRegionOption | undefined,
    setTouched: boolean = false
  ) {
    this.selectedRegion.setValue(region);
    if (setTouched) {
      this.selectedRegion.setTouched(true);
    }
  }
  public selectedCity = new FormFieldState<ICityOption | undefined>(undefined, {
    validator: (value: ICityOption | undefined) => {
      if (!value) {
        return "cityRequired";
      }
    },
  });
  public selectedRegion = new FormFieldState<IRegionOption | undefined>(
    undefined,
    {
      validator: (value: IRegionOption | undefined) => {
        if (!value) {
          return "regionRequired";
        }
      },
    }
  );

  // TODO: move it to special class
  private notifyBugsnag(err: any) {
    console.error(err);
    bugsnagClient.metaData = {
      ...bugsnagClient.metaData,
      catchingPoint: "SignInScreenModel",
    };
    if (err.response) {
      bugsnagClient.metaData = {
        ...bugsnagClient.metaData,
        axiosData: err.response.data,
        axiosStatus: err.response.status,
      };
    }
    if (err.config) {
      bugsnagClient.metaData = {
        ...bugsnagClient.metaData,
        axiosConfig: err.config,
      };
    }
    bugsnagClient.notify(err);
  }

  private async finalizeSignUp(user: IClientData) {
    if (user) {
      const { email, password } = {
        email: `${uuid.v4()}@mda.com`,
        password: uuid.v4(),
      };
      if (!this.uiStore.features.disableLegacyAuthentication) {
        await this.authService.unlinkCurrentUserEmailPassword();
        await this.authService.linkCurrentUserWithEmailPassword(
          email,
          password
        );
      }
      // this.nativeAppService.sendAuthenticationEvent(
      //   email,
      //   password,
      //   user.email,
      //   ""
      // );
    }
  }

  private async registerNewUser(): Promise<IClientData> {
    return await this.coreApiService.registerClient(
      `${this.firstName} ${this.lastName}`,
      this.email,
      this.phone,
      this.ssn,
      this.i18nService.currentLanguage === "sv"
        ? LanguagesSpoken.SV
        : LanguagesSpoken.EN,
      this.userModelFactory.createDbUserConsents(this.marketingAccepted),
      !!this.locationId && this.locationId
    );
  }

  public async signOut() {
    this.authService.signOut();
  }
}
