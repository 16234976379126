import { inject, injectable } from "inversify";
import { IDatetime } from "../../helpers/Datetime/interfaces";
import {
  DatetimeSymbol,
  HttpServiceSymbol,
  SettingsStoreSymbol,
  SettingsSymbol,
} from "../../inversify/symbols";
import { ISettingsStore } from "../../stores/SettingsStore/interfaces";
import { IHttpService } from "../HttpService/interfaces";
import { ISettings } from "../Settings/interfaces";
import { IApiService } from "./interfaces";

@injectable()
export class ApiService implements IApiService {
  public constructor(
    @inject(HttpServiceSymbol) private readonly http: IHttpService,
    @inject(SettingsSymbol) private readonly settings: ISettings,
    @inject(SettingsStoreSymbol) private readonly settingsStore: ISettingsStore,
    @inject(DatetimeSymbol) private readonly datetime: IDatetime
  ) {}

  public async cancelUnpaidOrder(bookingId: string): Promise<void> {
    const payload: {
      id: string;
    } = { id: bookingId };
    const { id } = payload;
    const endpoint = `${this.settings.mdaCoreApiUrl}/v3/bookings/${id}`;

    await this.http.axios.delete(endpoint);
  }

  public async bindAnonymousUser(anonymousUserId: string): Promise<void> {
    const endpoint = `${this.settings.apiUrlV2}/auth/webapp/bindUser`;
    const payload: {
      anonymousUserId: string;
    } = {
      anonymousUserId,
    };

    await this.http.axios.post(endpoint, payload);
  }
}
