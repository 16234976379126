import { injectable } from "inversify";
import { action, observable } from "mobx";
import {
  CalendarEventType,
  EventDetailViewType,
} from "../../components/bookings/ScheduleScreen/Calendar/interfaces";
import { IFbDrivingExamEventModel } from "./interfaces";

@injectable()
export class FbDrivingExamEventModel implements IFbDrivingExamEventModel {
  public readonly isDrivingExam = true;
  @observable
  public id!: number;
  @observable
  public location: string = "";
  @observable
  public name: string = "";
  @observable
  public from!: Date;
  @observable
  public to!: Date;
  @observable
  public typeKey!: CalendarEventType;
  public getEventDetailView(): Promise<
    EventDetailViewType<IFbDrivingExamEventModel>
  > {
    return import(
      /* webpackChunkName: "FbDrivingExamEventDetailView" */ "../../components/bookings/EventsListScreen/EventDetails/EventDetailView/FbDrivingExamEventDetailView"
    ).then((res) => res.FbDrivingExamEventDetailView);
  }

  @action
  public setFrom(from: Date): void {
    this.from = from;
  }

  @action
  public setTo(to: Date): void {
    this.to = to;
  }
  @action
  public setName(name: string): void {
    this.name = name;
  }
  @action
  public setTypeKey(typeKey: CalendarEventType): void {
    this.typeKey = typeKey;
  }

  @action
  public setId(id: number): void {
    this.id = id;
  }
  @action
  public setLocation(location: string): void {
    this.location = location;
  }

  public isEditable(): boolean {
    return false;
  }

  public isEditableViaSupport(): boolean {
    return this.from.getTime() > Date.now();
  }
}
