import { inject, injectable } from "inversify";
import {
  DbServiceSymbol,
  DrivingPlanDbServiceSymbol,
  FirebaseAccessorSymbol,
  SettingsStoreSymbol,
} from "../../inversify/symbols";
import {
  IDbUser,
  IDbUserConsents,
  IUserProfile,
} from "../../models/UserModel/interfaces";
import { ISettingsStore } from "../../stores/SettingsStore/interfaces";
import { IDbService } from "../DbService/interfaces";
import { IDrivingPlanDbService } from "../DrivingPlanDbService/interfaces";
import { IFirebaseAccessor } from "../FirebaseAccessor/interfaces";
import { IUserDbService } from "./interfaces";

@injectable()
export class UserDbService implements IUserDbService {
  public constructor(
    @inject(DbServiceSymbol) private readonly dbService: IDbService,
    @inject(FirebaseAccessorSymbol)
    private readonly fbAccessor: IFirebaseAccessor,
    @inject(SettingsStoreSymbol) private settingsStore: ISettingsStore,
    @inject(DrivingPlanDbServiceSymbol)
    private planDbService: IDrivingPlanDbService
  ) {}

  public async hasBookings(userId: number) {
    const exist = await this.dbService.find<any>(`/userBookingsTree/${userId}`);
    return !!exist;
  }

  public async checkCode(
    phone: string,
    code: string,
    sms = false
  ): Promise<string | undefined> {
    const isCorrect = this.fbAccessor.functions.httpsCallable("checkCode");

    return await isCorrect({ phone, code, sms })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(err);
        return undefined;
      });
  }
  public async sendSms(phone: string): Promise<boolean> {
    const isCorrect = this.fbAccessor.functions.httpsCallable("sendSms");

    return await isCorrect({ phone })
      .then((res) => {
        const data = res.data;
        return !!data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  }

  public findUser(userId: string) {
    return this.dbService.find<IDbUser>(`/users/${userId}`);
  }
  public findInstructor(userId: string) {
    return this.dbService.find<IDbUser>(`/adminUsers/${userId}`);
  }

  public async findUserByBookingClientId(
    bookingClientId: string
  ): Promise<{ fbKey: string; data: IDbUser } | undefined> {
    const snapshot: any = await this.dbService
      .createQuery("/users")
      .orderByChild("bookingClientId")
      .startAt(bookingClientId)
      .limitToFirst(1)
      .once("value");
    // We're using startAt, so we might receive another user, meaning that the required one wasn't found.
    // Hence, the check for clientId equality. We're not using equalTo instead of startAt because in that
    // case once will never return.
    const usersDict = snapshot.val() as { [key: string]: IDbUser } | undefined;
    if (!usersDict) {
      return undefined;
    }
    const userKeys = Object.keys(usersDict);
    if (userKeys.length <= 0) {
      return undefined;
    }
    const userId = userKeys[0];
    const user = usersDict[userId];

    if (!user || user.bookingClientId !== bookingClientId.toString()) {
      return undefined;
    }
    return { fbKey: userId, data: user };
  }

  public updateConsents(
    userId: string,
    consents: IDbUserConsents
  ): Promise<any> {
    const updates = {
      [`/users/${userId}/consents`]: consents,
    };
    return this.dbService.batchUpdate(updates);
  }

  public subscribeToProfileChange(
    apiUserId: number,
    callback: (profile: IUserProfile | null) => void
  ): () => void {
    return this.dbService.subscribe(`/userProfile/${apiUserId}`, callback);
  }

  public subscribeToCodes(
    phone: string,
    callback: (code: string | null) => void
  ) {
    const path = `/codes/${phone}/code`;
    return this.dbService.subscribe(path, callback);
  }
}
