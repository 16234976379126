import { injectable } from "inversify";
import { action, observable } from "mobx";
import {
  CalendarEventType,
  EventDetailViewType,
} from "../../components/bookings/ScheduleScreen/Calendar/interfaces";
import { IClassEventModel, IPickup } from "./interfaces";

@injectable()
export class ClassEventModel implements IClassEventModel {
  @observable
  public name: string = "";
  @observable
  public id!: number;
  @observable
  public pickup: IPickup = {
    address: "",
    htmlFormatted: false,
  };
  @observable
  public from!: Date;
  @observable
  public to!: Date;
  @observable
  public typeKey!: CalendarEventType;
  @observable
  isPaid!: boolean;
  @observable
  isDriving!: boolean;

  public getEventDetailView(): Promise<EventDetailViewType<IClassEventModel>> {
    return import(
      /* webpackChunkName: "ClassEventDetailView" */ "../../components/bookings/EventsListScreen/EventDetails/EventDetailView/ClassEvenDetailView"
    ).then((res) => res.ClassEventDetailView);
  }

  @action
  public setFrom(from: Date): void {
    this.from = from;
  }

  @action
  public setTo(to: Date): void {
    this.to = to;
  }
  @action
  public setName(name: string): void {
    this.name = name;
  }
  @action
  public setIsDriving(isDriving: boolean): void {
    this.isDriving = isDriving;
  }
  @action
  public setTypeKey(typeKey: CalendarEventType): void {
    this.typeKey = typeKey;
  }

  public setPickup(pickup: IPickup): void {
    this.pickup = pickup;
  }

  @action
  public setId(id: number): void {
    this.id = id;
  }

  public isEditable(): boolean {
    return false;
  }

  public isEditableViaSupport(): boolean {
    return this.from.getTime() > Date.now();
  }
}
