import Axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import { AxiosInstance } from "axios";
import { inject, injectable } from "inversify";
import {
  FirebaseAccessorSymbol,
  SettingsSymbol,
} from "../../inversify/symbols";
import { IFirebaseAccessor } from "../FirebaseAccessor/interfaces";
import { ISettings } from "../Settings/interfaces";
import { IHttpService } from "./interfaces";

@injectable()
export class HttpService implements IHttpService {
  public readonly axios: AxiosInstance;
  private readonly originalAxiosGet: <T = any>(
    url: string,
    config?: AxiosRequestConfig
  ) => AxiosPromise<T>;
  private readonly originalAxiosPost: <T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) => AxiosPromise<T>;

  public constructor(
    @inject(FirebaseAccessorSymbol)
    private readonly firebase: IFirebaseAccessor,
    @inject(SettingsSymbol) private readonly settings: ISettings
  ) {
    this.axios = Axios.create();
    this.axios.interceptors.request.use(this.bearerInterceptor);
    this.originalAxiosGet = this.axios.get;
    this.originalAxiosPost = this.axios.post;
    this.axios.get = this.axiosPatchedGet;
    this.axios.post = this.axiosPatchedPost;
  }

  private axiosPatchedGet = <T>(
    url: string,
    config?: AxiosRequestConfig
  ): AxiosPromise<T> => {
    try {
      return this.originalAxiosGet.call(
        this.axios,
        url,
        config
      ) as AxiosPromise<T>;
    } catch (e) {
      const getBookingDetailsEndpoint = `${this.settings.apiUrl}/simplybook/getBookingDetails`;
      if (url === getBookingDetailsEndpoint) {
        // second try
        console.debug("Request has failed. Make one more try");
        return this.originalAxiosGet.call(
          this.axios,
          url,
          config
        ) as AxiosPromise<T>;
      } else {
        throw e;
      }
    }
  };

  private axiosPatchedPost = <T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): AxiosPromise<T> => {
    try {
      return this.originalAxiosPost.call(
        this.axios,
        url,
        data,
        config
      ) as AxiosPromise<T>;
    } catch (e) {
      // second try
      console.debug("Request has failed. Make one more try");
      return this.originalAxiosPost.call(
        this.axios,
        url,
        data,
        config
      ) as AxiosPromise<T>;
    }
  };

  private bearerInterceptor = async (
    config: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> => {
    const token = await this.authToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  };

  private async authToken(): Promise<string | undefined> {
    const user = this.firebase.auth.currentUser;
    return user ? await user.getIdToken() : undefined;
  }
}
