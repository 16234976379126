import * as firebase from "firebase/app";
import { inject, injectable, interfaces } from "inversify";
import { action, observable } from "mobx";
import * as uuid from "uuid";
import { bugsnagClient } from "../../../bugsnag";
import {
  AnalyticsManagerSymbol,
  AuthServiceSymbol,
  ConfirmationFormModelSymbol,
  CoreApiServiceSymbol,
  FirebaseAccessorSymbol,
  I18nServiceSymbol,
  InversifyContextSymbol,
  NativeAppServiceSymbol,
  UiStoreSymbol,
  UserDbServiceSymbol,
  UserStoreSymbol,
} from "../../../inversify/symbols";
import { ErrorMessage } from "../../../models/ErrorMessage";
import { PendingStatus } from "../../../models/PendingStatus";
import { IAnalytics } from "../../../services/AnalyticsManager/interfaces";
import {
  IAuthService,
  isAPIErrors,
} from "../../../services/AuthService/interfaces";
import { SignInErrorCode } from "../../../services/AuthService/interfaces";
import { IFirebaseAccessor } from "../../../services/FirebaseAccessor/interfaces";
import { II18nService } from "../../../services/I18nService/interfaces";
import { INativeAppService } from "../../../services/NativeAppService/interfaces";
import { IUserDbService } from "../../../services/UserDbService/interfaces";
import { IUiStore } from "../../../stores/UiStore/interfaces";
import { IUserStore } from "../../../stores/UserStore/interfaces";
import { IConfirmationFormModel } from "../ConfirmationForm/interfaces";
import { ISignInScreenModel } from "./interfaces";
import { ICoreApiService } from "@/services/CoreApiService/interfaces";
import { LanguagesSpoken } from "@/models/InstructorModel/interfaces";

@injectable()
export class SignInScreenModel implements ISignInScreenModel {
  @observable
  public verifier: firebase.auth.ApplicationVerifier | undefined;
  @observable
  public phone = "";
  public pendingStatus = new PendingStatus();
  public errorStatus = new ErrorMessage();

  @observable
  public confirmationFormModel?: IConfirmationFormModel;

  constructor(
    @inject(FirebaseAccessorSymbol)
    private readonly fbAccessor: IFirebaseAccessor,
    @inject(UiStoreSymbol) private readonly uiStore: IUiStore,
    @inject(UserStoreSymbol) private readonly userStore: IUserStore,
    @inject(UserDbServiceSymbol) private readonly userDbService: IUserDbService,
    @inject(AuthServiceSymbol) private readonly authService: IAuthService,
    @inject(I18nServiceSymbol) private readonly i18nService: II18nService,
    @inject(AnalyticsManagerSymbol) private readonly gtm: IAnalytics,
    @inject(InversifyContextSymbol)
    private readonly inversifyContext: interfaces.Context,
    @inject(NativeAppServiceSymbol)
    private readonly nativeAppService: INativeAppService,
    @inject(CoreApiServiceSymbol) private coreApiService: ICoreApiService
  ) {}

  @action
  public reset() {
    this.errorStatus.clear();
    this.setConfirmationFormModel(undefined);
  }

  @action
  public cancelRecaptcha() {
    this.setConfirmationFormModel(undefined);
  }

  public async signIn() {
    this.errorStatus.clear();

    const validationResult = this.validate();
    if (!validationResult) {
      return;
    }

    const { phone, verifier } = validationResult;
    this.pendingStatus.startPending();

    try {
      const phoneSignInResult =
        await this.authService.startFirebaseSignInWithPhone(phone, verifier);
      await this.userStore.logEvents(phone, "send");

      await this.confirmWithPhoneCode(phone, phoneSignInResult, verifier);
      if (!this.userStore.user) {
        // it is not normal situation but it looks like we have it in case of new registration sometimes.
        // FIXME: better to test this deeper, it is a temporary solution
        await this.userStore.forceReloadUser();
      }

      if (!this.userStore.user?.settings.options.defaultLanguage && this.userStore.user)
      {
        const currentLanguage = this.i18nService.currentLanguage === "sv" ? LanguagesSpoken.SV : LanguagesSpoken.EN;
        await this.coreApiService.setLanguage(this.userStore.user.id, currentLanguage)
      }
      await this.finalizeSignIn();
      this.gtm.signedIn().catch((err) => console.error(err));
    } catch (e) {
      console.error(`Sign in failed: ${e}`);
      this.notifyBugsnag(e);

      this.errorStatus.setMessage(
        this.i18nService.i18next.t("signInScreen.loginFailed").toString(),
        `${e}`
      );

      if (isAPIErrors(e) && e.code === SignInErrorCode.InvalidPhone) {
        this.errorStatus.setMessage(
          this.i18nService.i18next.t("wrongPhone").toString()
        );
      }
    } finally {
      this.closePhoneCodeConfirmationForm();
      this.pendingStatus.stopPending();
    }
  }

  @action
  public setPhone(val: string) {
    this.phone = val;
  }

  @action
  public setVerifier(val: firebase.auth.ApplicationVerifier | undefined) {
    this.verifier = val;
  }

  private validate():
    | undefined
    | { phone: string; verifier?: firebase.auth.ApplicationVerifier } {
    if (!this.verifier) {
      // should not happen
      this.errorStatus.setMessage(
        this.i18nService.i18next.t("verificationFailed").toString()
      );
      return undefined;
    }

    if (!this.phone) {
      this.errorStatus.setMessage(
        this.i18nService.i18next.t("wrongPhone").toString()
      );
      return undefined;
    }

    return { phone: this.phone, verifier: this.verifier };
  }

  @action
  private setConfirmationFormModel(val: IConfirmationFormModel | undefined) {
    this.confirmationFormModel = val;
  }

  private async confirmWithPhoneCode(
    phone: string,
    confirmationResult: firebase.auth.ConfirmationResult,
    verifier?: firebase.auth.ApplicationVerifier
  ) {
    const confirmationFormModel =
      this.inversifyContext.container.get<IConfirmationFormModel>(
        ConfirmationFormModelSymbol
      );
    this.setConfirmationFormModel(confirmationFormModel);
    await confirmationFormModel.waitPhoneCodeConfirmation(
      phone,
      confirmationResult,
      verifier
    );
  }

  private closePhoneCodeConfirmationForm() {
    this.setConfirmationFormModel(undefined);
  }

  private async finalizeSignIn() {
    const { currentUser } = this.fbAccessor.auth;
    const userId = currentUser && currentUser.uid;
    const user = userId ? await this.userDbService.findUser(userId) : void 0;
    if (user) {
      const { email, password } = {
        email: `${uuid.v4()}@mda.com`,
        password: uuid.v4(),
      };
      if (!this.uiStore.features.disableLegacyAuthentication) {
        await this.authService.unlinkCurrentUserEmailPassword();
        await this.authService.linkCurrentUserWithEmailPassword(
          email,
          password
        );
      }
      this.nativeAppService.sendAuthenticationEvent(
        email,
        password,
        user.email,
        user.bookingPassword
      );
    }
  }

  // TODO: move it to special class
  private notifyBugsnag(err: any) {
    console.error(err);
    bugsnagClient.metaData = {
      ...bugsnagClient.metaData,
      catchingPoint: "SignInScreenModel",
    };
    if (err.response) {
      bugsnagClient.metaData = {
        ...bugsnagClient.metaData,
        axiosData: err.response.data,
        axiosStatus: err.response.status,
      };
    }
    if (err.config) {
      bugsnagClient.metaData = {
        ...bugsnagClient.metaData,
        axiosConfig: err.config,
      };
    }
    bugsnagClient.notify(err);
  }
}
