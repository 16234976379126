import { InMemoryStorage } from "./InMemoryStorage";
import { IStorage } from "./interfaces";

export class LocalStorage implements IStorage {
  private readonly isSupported: boolean;
  private readonly localStorage: IStorage;
  constructor() {
    this.isSupported = this.getIsSupported();
    // TODO: new Proxy
    if (this.isSupported) {
      console.debug("LocalStorage is available. We'll use the native one");
      this.localStorage = window.localStorage;
    } else {
      console.warn(
        "LocalStorage isn't available. We'll use an in-memory fallback"
      );
      this.localStorage = new InMemoryStorage();
    }
  }
  public get length(): number {
    return this.localStorage.length;
  }

  public getItem(key: string): string | null {
    return this.localStorage.getItem(key);
  }

  public setItem(key: string, value: string): void {
    return this.localStorage.setItem(key, value);
  }

  public removeItem(key: string): void {
    return this.localStorage.removeItem(key);
  }

  public key(index: number): string | null {
    return this.localStorage.key(index);
  }

  public clear(): void {
    return this.localStorage.clear();
  }

  private getIsSupported() {
    try {
      const testKey = "__some_random_key_you_are_not_going_to_use__";
      window.localStorage.setItem(testKey, testKey);
      window.localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }
}

// it is a safe version of the localStorage. It has fallback to the in-memory version
export const safeLocalStorage = new LocalStorage();
