import { History } from "history";
import { inject, injectable } from "inversify";
import { action, autorun, computed, observable, runInAction } from "mobx";
import qs from "qs";
import {
  HistorySymbol,
  HttpServiceSymbol,
  I18nServiceSymbol,
  NativeAppServiceSymbol,
  SettingsStoreSymbol,
} from "../../inversify/symbols";
import { IHttpService } from "../../services/HttpService/interfaces";
import { II18nService } from "../../services/I18nService/interfaces";
import { NativeAppService } from "../../services/NativeAppService";
import { ISettingsStore } from "../SettingsStore/interfaces";
import { ERROR_CODE, IFeatures, IUiStore } from "./interfaces";

const WIDGET_FEATURES: IFeatures = {
  disableLegacyAuthentication: true,
  showLessonsHistory: true,
};
declare const window: {
  features?: Partial<IFeatures & { disableLegacyAuthentiocation: boolean }>;
};
@injectable()
export class UiStore implements IUiStore {
  // This flag is used to determine if the application works in widget mode
  public get widgetMode() {
    return !this.nativeAppService.isWebView;
  }

  @computed
  public get query() {
    return this.history.location
      ? qs.parse(this.history.location.search.substring(1))
      : {};
  }

  @computed
  public get planMetaLocale() {
    return "sv_SE" as "sv_SE"; // TODO
  }

  @computed
  public get features(): IFeatures {
    // legacy?
    const baseFeatures = this.widgetMode ? WIDGET_FEATURES : window.features;
    const disableLegacyAuthentication = this.widgetMode
      ? WIDGET_FEATURES.disableLegacyAuthentication
      : window.features?.disableLegacyAuthentication ||
        window.features?.disableLegacyAuthentiocation;

    return {
      disableLegacyAuthentication: disableLegacyAuthentication || false,
      showLessonsHistory: !!(baseFeatures && baseFeatures.showLessonsHistory),
    };
  }

  public readonly loadingTokens = observable.array<{ token: any }>([], {
    deep: false,
  });

  @observable
  public appUpgraded = false;
  @observable
  public newAppVersionExists = false;
  @observable
  public error: ERROR_CODE | undefined;

  private lastVersion?: string;

  private readonly versionCheckIntervalMs = 5 * 1000;
  public constructor(
    @inject(SettingsStoreSymbol) private readonly settingsStore: ISettingsStore,
    @inject(HttpServiceSymbol) private readonly httpService: IHttpService,
    @inject(I18nServiceSymbol) private readonly i18nService: II18nService,
    @inject(HistorySymbol) private readonly history: History,
    @inject(NativeAppServiceSymbol)
    private readonly nativeAppService: NativeAppService
  ) {
    setInterval(this.checkUpdates, this.versionCheckIntervalMs);

    autorun(() => {
      this.logQuery(this.query);
      if ("upgraded" in this.query) {
        runInAction(() => {
          this.appUpgraded = true;
        });
      }
    });
  }
  @action
  public registerLoadingToken(token: any): () => void {
    const tokenObj = { token };
    this.loadingTokens.push(tokenObj);
    return action("unregisterLoadingToken", () => {
      this.loadingTokens.remove(tokenObj);
    });
  }

  @action
  public setError(val: ERROR_CODE): void {
    this.error = val;
  }

  public readonly checkUpdates = async (): Promise<void> => {
    if (this.newAppVersionExists) {
      return;
    }
    if (!document.hidden) {
      try {
        // We use content of the index.html as a version hash
        const indexPage = this.history.createHref({
          pathname: "/",
        });
        const response = await this.httpService.axios.get(indexPage, {
          headers: {
            Accept: "text/html",
          },
        });
        const version = response.data;
        if (this.lastVersion && version !== this.lastVersion) {
          console.debug("newAppVersionExists", this.lastVersion, version);
          runInAction(() => {
            this.newAppVersionExists = true;
          });
        }
        this.lastVersion = version;
      } catch (e) {
        console.error(`Version check failed: ${e}`);
      }
    }
  };

  private logQuery(query: any) {
    const values: string[] = [];
    for (const key of Object.keys(query)) {
      let valToDisplay = query[key];
      if (valToDisplay.length > 10) {
        const firstPart = valToDisplay.substring(0, 5);
        const secondPart = valToDisplay.substring(valToDisplay.length - 5);
        valToDisplay = `${firstPart}...${secondPart}`;
      }
      values.push(`${key}=${valToDisplay}`);
    }
    console.debug(`New query string: ${values.join(", ") || "<empty>"}`);
  }
}
