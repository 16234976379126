import { injectable } from "inversify";

@injectable()
export class RequestCancellerModel {
  private waitForRestoreActions: Array<Promise<any>> = [];
  public waitForRestore = () => {
    return Promise.all(this.waitForRestoreActions);
  };
  addAction(awaitable: Promise<any>) {
    this.waitForRestoreActions = [...this.waitForRestoreActions, awaitable];
  }
  clearActions() {
    this.waitForRestoreActions = [];
  }
}
