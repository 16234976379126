import { inject, injectable } from "inversify";
import { action, computed, observable } from "mobx";
import * as moment from "moment";
import { bugsnagClient } from "../../bugsnag";
import { ICalendarEvent } from "../../components/bookings/ScheduleScreen/Calendar/interfaces";
import { IDatetime } from "../../helpers/Datetime/interfaces";
import {
  ApiServiceSymbol,
  CoreApiServiceSymbol,
  DatetimeSymbol,
  DbServiceSymbol,
  EventModelFactorySymbol,
  SettingsStoreSymbol,
  UiStoreSymbol,
  UserDbServiceSymbol,
} from "../../inversify/symbols";
import {
  IApiService,
  ICoreUserProfile,
} from "../../services/ApiService/interfaces";
import {
  IClientData,
  IClientOptions,
  ICoreApiService,
  ISaldo,
} from "../../services/CoreApiService/interfaces";
import { IDbService } from "../../services/DbService/interfaces";
import { IUserDbService } from "../../services/UserDbService/interfaces";
import { ISettingsStore } from "../../stores/SettingsStore/interfaces";
import { ERROR_CODE, IUiStore } from "../../stores/UiStore/interfaces";
import { ProductType } from "../AcademyServiceModel/interfaces";
import {
  IClassEventModel,
  IEventModelFactory,
  IFbDrivingExamEventModel,
  IFbTheoryExamEventModel,
  ILessonEventModel,
  isClassEventModel,
  isFbDrivingExamEventModel,
  isFbTheoryExamEventModel,
  isLessonEventModel,
} from "../bookings/interfaces";
import { PendingStatus } from "../PendingStatus";
import {
  IDashboardCounts,
  IDbUser,
  IDbUserConsents,
  IUserExam,
  IUserModel,
} from "./interfaces";

@injectable()
export class UserModel implements IUserModel {
  @computed
  public get displayName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  @computed
  public get currentRegionId(): number | undefined {
    const currentRegion = this.settingsStore.regions.find((region) =>
      region.locationsIds.some((id) => id === this.profile.currentLocationId)
    );
    return currentRegion?.id;
  }
  @observable
  public firstName!: string;
  @observable
  public email!: string;
  @observable
  public ssn!: string;
  @observable
  public lastName!: string;
  @observable
  public firebaseId!: string;
  @observable
  public id!: number;
  @observable
  public consents!: IDbUserConsents;
  @observable
  public dashboardCounts: IDashboardCounts | undefined;
  @observable
  public isBound: boolean = false;
  @observable
  public saldo: ISaldo[] = [];
  @observable
  public saldoBalance: number = 0;

  public dashboardCountsReloading = new PendingStatus();
  public profileReloading = new PendingStatus();
  public settingsReloading = new PendingStatus();
  @observable
  public isAdmin: boolean = false;
  @observable
  public isInstructor: boolean = false; // TODO: subscribe
  @observable
  public profile: ICoreUserProfile = {
    availableRegions: [],
    status: "",
  };
  @observable
  public settings!: IClientData;

  @observable
  public isNewbie!: boolean;

  constructor(
    @inject(ApiServiceSymbol) private readonly apiService: IApiService,
    @inject(CoreApiServiceSymbol)
    private readonly coreApiService: ICoreApiService,
    @inject(SettingsStoreSymbol) private readonly settingsStore: ISettingsStore,
    @inject(UserDbServiceSymbol) private readonly userDbService: IUserDbService,
    @inject(DatetimeSymbol) private readonly datetime: IDatetime,
    @inject(EventModelFactorySymbol)
    private readonly eventModelFactory: IEventModelFactory,
    @inject(DbServiceSymbol) private readonly dbService: IDbService,
    @inject(UiStoreSymbol) private uiStore: IUiStore
  ) {}

  @action
  public setFirstName(val: string) {
    this.firstName = val;
  }
  @action
  public setEmail(val: string) {
    this.email = val;
  }
  @action
  public setIsBound(val: boolean) {
    this.isBound = val;
  }
  @action
  public setIsAdmin(val: boolean) {
    this.isAdmin = val;
  }
  @action
  public setSsn(ssn: string) {
    this.ssn = ssn;
  }
  @action
  public setLastName(val: string) {
    this.lastName = val;
  }
  @action
  public setFirebaseID(val: string) {
    this.firebaseId = val;
  }

  @action
  public setUserId(val: number) {
    this.id = val;
  }
  @action
  public setConsents(val: IDbUserConsents) {
    this.consents = val;
  }
  @action
  public setSaldo(val: ISaldo[]) {
    this.saldo = val.filter((saldo) => saldo.type === ProductType.Service);
    this.saldoBalance = this.saldo.reduce((acc, item) => {
      return acc + item.qty;
    }, 0);
  }

  public async bindAnonymousUserId(anonymousUserId: string): Promise<void> {
    try {
      await this.apiService.bindAnonymousUser(anonymousUserId);
    } catch (e) {
      bugsnagClient.notify(e, { severity: "warning" });
    }
  }

  public async refreshDashboardCounts(): Promise<void> {
    try {
      this.dashboardCountsReloading.startPending();
      const dashboardCounts = await this.coreApiService.getDashboardCounts(
        this.id
      );
      this.setDashboardCounts(dashboardCounts);
    } finally {
      this.dashboardCountsReloading.stopPending();
    }
  }

  public async refreshSaldoCounts(): Promise<void> {
    try {
      this.dashboardCountsReloading.startPending();
      const saldo = await this.coreApiService.getClientsSaldo(this.id);
      this.setSaldo(saldo);
    } finally {
      this.dashboardCountsReloading.stopPending();
    }
  }

  public async refreshProfile(): Promise<void> {
    console.debug("refreshProfile");
    try {
      this.profileReloading.startPending();
      const apiProfile = await this.coreApiService.fetchUserProfile(this.id);
      if (apiProfile) {
        console.debug("set api based profile", apiProfile);
        this.setProfile(apiProfile);
      } else {
        this.uiStore.setError(ERROR_CODE.NetworkError);
        console.error("api profile is not found");
      }
    } finally {
      console.debug("profile refreshed");
      this.profileReloading.stopPending();
    }
  }

  public async refreshSettings(): Promise<IClientData | null> {
    console.debug("refresh settings");
    try {
      this.settingsReloading.startPending();
      const apiSettings = await this.coreApiService.fetchUserSettings(this.id);
      if (apiSettings) {
        console.debug("set api based settings", apiSettings);
        this.setSettings(apiSettings);
        return apiSettings;
      } else {
        console.error("api settings is not found");
      }
    } finally {
      console.debug("user settings refreshed");
      this.settingsReloading.stopPending();
    }
    return null;
  }

  public async defineNewbie(): Promise<boolean | undefined> {
    try {
      const isNewbie = await this.coreApiService.defineNewSudent(
        Number(this.id)
      );
      this.setNewbie(isNewbie);
    } catch {
      console.debug("api newbie is not found");
    }
    return undefined;
  }

  @action
  public setProfile(profile: ICoreUserProfile): void {
    this.profile = profile;
  }

  @action
  public setSettings(settings: IClientData): void {
    this.settings = settings;
  }

  @action
  public setNewbie(newbie: boolean): void {
    this.isNewbie = newbie;
  }

  public async fetchEvents(
    from: Date,
    to: Date
  ): Promise<Array<ICalendarEvent<any>>> {
    const allFrom = moment(from).clone().toDate();
    const allTo = moment(to).clone().toDate();

    const apiBookings = await this.coreApiService.getStudentBookings(
      allFrom,
      allTo,
      this.id
    );

    const allEvents = apiBookings
      .map((apiEvent) => {
        let model: IClassEventModel | ILessonEventModel | undefined;
        try {
          model = this.eventModelFactory.eventFromApi(apiEvent);
        } catch (e) {
          console.error("Incorrect event", e, apiEvent);
        }

        return model;
      })
      .filter((x) => !!x)
      .map((x) => x!)
      .sort((a, b) => a.from.valueOf() - b.from.valueOf());
    const byDateFilter = (x: ICalendarEvent<any>) =>
      x.from.valueOf() >= from.valueOf() && x.from.valueOf() <= to.valueOf();
    const calendarEvents: Array<ICalendarEvent<any>> = [];

    let prevDrivingExam: undefined | ILessonEventModel;
    for (const event of allEvents) {
      if (!byDateFilter(event)) {
        continue;
      }
      if (isLessonEventModel(event) && event.isDriving) {
        if (prevDrivingExam) {
          const gap = event.from.valueOf() - prevDrivingExam.to.valueOf();
          const tenMinutes = 10 * 60 * 1000;
          if (gap <= tenMinutes) {
            prevDrivingExam.setTo(event.to);
            console.debug(
              "Driving exams merged",
              prevDrivingExam.id,
              event.id,
              { prevDrivingExam, currentDrivingExam: event }
            );
            continue;
          }
        }

        prevDrivingExam = event;
      }

      calendarEvents.push(event);
    }
    const [fbTheoryExams, fbDrivingExams] = await this.fetchUserExams(this.id);
    fbTheoryExams
      .filter((_) => byDateFilter(_))
      .forEach((_) => {
        calendarEvents.push(_);
      });
    const drivingExamBooking = allEvents.find((x) => {
      return isLessonEventModel(x) && x.isDriving;
    });
    const drivingExamCourseBooking = allEvents.find((x) => {
      return isClassEventModel(x) && x.isDriving;
    });
    // If drivingExam is never booked we do not show firebase's record;
    // because real bookings have higher priority
    if (!drivingExamBooking || !drivingExamCourseBooking) {
      fbDrivingExams
        .filter((_) => byDateFilter(_))
        .forEach((_) => {
          calendarEvents.push(_);
        });
    }
    return calendarEvents;
  }

  @action
  private async makeFallbackInstructorsDict(allInstructors: string[]) {
    const res: Record<string, IDbUser | undefined> = {};

    await Promise.all(
      allInstructors.map((id) => {
        return this.userDbService
          .findUserByBookingClientId(id)
          .then((instructor) => {
            res[id] = instructor && instructor.data;
          });
      })
    );
    return res;
  }

  @action
  private setDashboardCounts(val: IDashboardCounts | undefined) {
    this.dashboardCounts = val;
  }

  private async fetchUserExams(
    userId: number
  ): Promise<
    [
      ReadonlyArray<IFbTheoryExamEventModel>,
      ReadonlyArray<IFbDrivingExamEventModel>
    ]
  > {
    const data = await this.dbService.find<{ [key: string]: IUserExam } | null>(
      `/userExamsTree/${userId}`
    );

    const exams = data
      ? Object.keys(data)
          .map((examId: string) => {
            const exam = data[examId];
            exam.id = examId;

            return exam;
          })
          .map((_: IUserExam): [IUserExam, moment.Moment] | undefined => {
            const start = this.datetime.fromDateTimeString(_.date, _.startTime);
            if (!start) {
              console.warn("Wrong time format");
              return;
            }
            return [_, start];
          })
          .filter((_) => !!_)
          .map((_) => _!)
          .filter(([_]) => _.type === "driving" || _.type === "theory")
          .sort((a, b) => {
            return b[1].valueOf() - a[1].valueOf();
          })
          .map(([_]) => {
            if (_.type === "driving") {
              return this.eventModelFactory.createFbDrivingExamEvent(_);
            } else if (_.type === "theory") {
              return this.eventModelFactory.createFbTheoryExamEvent(_);
            } else {
              // should not be possible
              return undefined;
            }
          })
          .map((_) => _!)
      : [];

    const theoryExams = exams.filter((_) =>
      isFbTheoryExamEventModel(_)
    ) as IFbTheoryExamEventModel[];
    const drivingExams = exams.filter((_) =>
      isFbDrivingExamEventModel(_)
    ) as IFbDrivingExamEventModel[];

    return [theoryExams, drivingExams];
  }
}
