import { action, computed, flow, observable, when } from "mobx";
import { FormFieldState } from "../../../../models/FormFieldState";
import { ERROR_CODE, IAttendee } from "./interfaces";

export class Attendee implements IAttendee {
  @computed
  public get isValid(): boolean {
    return !this.validationError && !this.fields.some((x) => !x.isValid);
  }
  @computed
  public get isOutOfAvailableSlots(): boolean {
    return this.validationError === ERROR_CODE.ThereAreNoEnoughSlots;
  }
  @computed
  public get savingDisabled(): boolean {
    return (
      (!!this.validationError &&
        this.validationError !== ERROR_CODE.AttendeeMustBeSaved) ||
      this.fields.some((x) => !x.isValid)
    );
  }

  @computed
  public get validationPending(): boolean {
    return this.fields.some((x) => x.validatingStatus.isPending);
  }
  @computed
  private get fields(): ReadonlyArray<FormFieldState<any>> {
    return [this.name, this.ssn];
  }

  public name = new FormFieldState("" as string, {
    validator: (value) => {
      if (!value) {
        return "nameRequired";
      }
    },
  });

  public ssn = new FormFieldState("" as string, {
    validator: (value) => {
      if (!value) {
        return "ssnRequired";
      }
    },
  });

  @observable
  public saved = false;
  public readonly save = flow<void, []>(function* (this: Attendee) {
    this.setAllFieldsTouched();
    this.requestValidation();
    yield when(() => !this.validationPending);
    if (this.isValid) {
      this.setSaved(true);
    } else {
      console.error("Attendee is not valid", this);
    }
  }).bind(this);
  @observable
  private validationError: string | undefined;

  @action
  public setSaved(val: boolean) {
    this.saved = val;
  }

  public requestValidation() {
    this.setError(undefined);
    this.fields.map((field) => field.setTouched(true));
    this.fields.map((field) => field.requestValidation());
  }
  public setAllFieldsTouched() {
    this.fields.map((field: FormFieldState<unknown>) => field.setTouched());
  }

  public addError(error: string) {
    this.setError(error);
  }

  @action
  private setError(error: string | undefined) {
    this.validationError = error;
  }
}
