import { IAnalyticsEvent } from "../events/types";
import {
  FirebaseMessageHandlerMessageType,
  IAnalyticsTransport,
  IAnalyticsWebInterface,
  IFirebaseMessageHandler,
} from "../interfaces";

export class FirebaseAnalyticsTransport implements IAnalyticsTransport {
  constructor(
    private readonly AnalyticsWebInterface?: IAnalyticsWebInterface,
    private readonly firebaseMessageHandlers?: IFirebaseMessageHandler
  ) {
    if (!AnalyticsWebInterface && !firebaseMessageHandlers) {
      throw new Error("No native APIs found.");
    }
  }
  public async logEvent(event: IAnalyticsEvent): Promise<void> {
    const data = event.toFirebaseAnalyticsEvent();
    if (this.AnalyticsWebInterface) {
      this.AnalyticsWebInterface.logEvent(
        data.name,
        JSON.stringify(data.payload)
      );
    } else if (this.firebaseMessageHandlers) {
      const message: FirebaseMessageHandlerMessageType = {
        command: "logEvent",
        name: data.name,
        parameters: data.payload,
      };
      this.firebaseMessageHandlers.postMessage(message);
    } else {
      throw new Error("No native APIs found.");
    }
  }
}
