import React from "react";
import styles from "./styles.scss";
import loading from "../../../../images/taxi.gif"

export interface IRiseLoaderProps {
  className?: string;
  label?: string;
}
export const RiseLoader: React.FunctionComponent<IRiseLoaderProps> =
  function RiseLoader(props: IRiseLoaderProps) {
    const { label } = props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.loader}>
          <div className={styles.title}>{label}</div>
          <div>
            <img src={loading} alt="loading" width="130px"/>
          </div>
        </div>
      </div>
    );
  };


