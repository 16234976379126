import * as mobx from "mobx";
import * as moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/se";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { setConfig } from "react-hot-loader";
import momentLocalizer from "react-widgets-moment";
import { ErrorBoundary } from "./bugsnag";
import { containerModule } from "./inversify/bindings";
import { container } from "./inversify/container";
import RootComponent from "./RootComponent";
import styles from "./styles.scss";
import "./styles/globals/index.global.scss";
import { Button } from "./components/shared/forms/Button";
import { Link } from "react-router-dom";

moment.locale("en-gb"); // default locale
momentLocalizer();
container.load(containerModule);
mobx.configure({ enforceActions: "observed" });

interface IFallbackProps {
  error: unknown;
  info: unknown;
}

function FallbackComponent(props: IFallbackProps) {
  const { error } = props;
  const errorMsg = error instanceof Error ? error.message : String(error);

  return (
    <div className={styles.errorContainer}>
      <h1 className={styles.errorTitle}>Something went wrong</h1>
      <p className={styles.errorText}>{errorMsg}</p>
      <Button className={styles.linkButton}>
        <a className={styles.link} href={"/booking"}>
          To calendar
        </a>
      </Button>
    </div>
  );
}
ReactDOM.render(
  <ErrorBoundary FallbackComponent={FallbackComponent}>
    <RootComponent />
  </ErrorBoundary>,
  document.getElementById("root")
);

setConfig({ ignoreSFC: true, pureRender: true });
