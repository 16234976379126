export interface IFeatures {
  showLessonsHistory: boolean;
  disableLegacyAuthentication: boolean;
}

export interface IUiStore {
  readonly widgetMode: boolean;
  readonly appUpgraded: boolean;
  readonly newAppVersionExists: boolean;
  readonly query: any;
  readonly planMetaLocale: "sv_SE";
  readonly error: ERROR_CODE | undefined;
  readonly features: IFeatures;
  // as long as there is at least 1 token - screen will be hidden
  readonly loadingTokens: any[];
  registerLoadingToken(token: any): () => void;
  checkUpdates(): Promise<void>;
  setError(val: string): void;
}

export enum ERROR_CODE {
  NetworkError = "networkError",
}
