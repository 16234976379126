import { injectable } from "inversify";
import { FormEvent } from "./events/FormEvent";
import { OrderEvent } from "./events/OrderEvent";
import { SignedInEvent } from "./events/SignedInEvent";
import {
  IAnalytics,
  IAnalyticsTransport,
  IAnalyticsWebInterface,
  IFirebaseMessageHandler,
} from "./interfaces";
import { FirebaseAnalyticsTransport } from "./transports/FirebaseAnalyticsTransport";
import { GoogleTagManagerTransport } from "./transports/GoogleTagManagerTransport";
import { NullTransport } from "./transports/NullTransport";

declare const window: {
  readonly dataLayer?: unknown[];
  readonly AnalyticsWebInterface?: IAnalyticsWebInterface;
  readonly webkit?: {
    messageHandlers?: {
      firebase?: IFirebaseMessageHandler;
    };
  };
};
@injectable()
export class AnalyticsManager implements IAnalytics {
  private readonly transport: IAnalyticsTransport;
  constructor() {
    const { AnalyticsWebInterface, webkit, dataLayer } = window;
    const FirebaseMessageHandler: IFirebaseMessageHandler | undefined =
      webkit && webkit.messageHandlers && webkit.messageHandlers.firebase;
    if (AnalyticsWebInterface || FirebaseMessageHandler) {
      this.transport = new FirebaseAnalyticsTransport(
        AnalyticsWebInterface,
        FirebaseMessageHandler
      );
    } else if (dataLayer) {
      this.transport = new GoogleTagManagerTransport(
        dataLayer.push.bind(dataLayer)
      );
    } else {
      this.transport = new NullTransport();
    }
  }
  public async logOrder(data: {
    orderId: string;
    eventId: string;
    eventName: string;
    city: string;
    price: number;
    totalPrice: number;
    quantity: number;
  }): Promise<void> {
    const event = new OrderEvent(data);
    return this.transport.logEvent(event);
  }

  public async formEvent(
    eventAction: string,
    eventLabel: string
  ): Promise<void> {
    const event = new FormEvent({ eventAction, eventLabel });
    return this.transport.logEvent(event);
  }
  public async signedIn(): Promise<void> {
    const event = new SignedInEvent();
    return this.transport.logEvent(event);
  }
  public async signedUp(): Promise<void> {
    const event = new SignedInEvent();
    return this.transport.logEvent(event);
  }
}
