import * as firebase from "firebase/app";
import { inject, injectable } from "inversify";
import { action, observable, runInAction, when } from "mobx";
import { lazyInject } from "../../../inversify/container";
import {
  ApiServiceSymbol,
  AuthServiceSymbol,
  I18nServiceSymbol,
  SettingsStoreSymbol,
  UserDbServiceSymbol,
  UserStoreSymbol,
} from "../../../inversify/symbols";
import { Countdown } from "../../../models/Countdown";
import { ErrorMessage } from "../../../models/ErrorMessage";
import { PendingStatus } from "../../../models/PendingStatus";
import { IApiService } from "../../../services/ApiService/interfaces";
import {
  IAuthService,
  isAPIErrors,
  SignInErrorCode,
} from "../../../services/AuthService/interfaces";
import { II18nService } from "../../../services/I18nService/interfaces";
import { IUserDbService } from "../../../services/UserDbService/interfaces";
import { ISettingsStore } from "../../../stores/SettingsStore/interfaces";
import { IUserStore } from "../../../stores/UserStore/interfaces";
import { IConfirmationFormModel } from "./interfaces";

@injectable()
export class ConfirmationFormModel implements IConfirmationFormModel {
  @observable
  public phone: string | undefined;
  @observable
  public countdown: Countdown;
  @observable
  public code = "";
  public errorStatus = new ErrorMessage();
  public resendingStatus = new PendingStatus();
  public confirming = new PendingStatus();
  @observable
  public firstAttempt = true;
  @observable
  public showInstructions = false;
  @observable
  public verifier: firebase.auth.ApplicationVerifier | undefined;
  @lazyInject(UserStoreSymbol)
  private readonly userStore!: IUserStore;
  private recaptchaConfirmationResult:
    | firebase.auth.ConfirmationResult
    | undefined;
  @observable
  private confirmed = false;
  constructor(
    @inject(AuthServiceSymbol) private readonly authService: IAuthService,
    @inject(SettingsStoreSymbol) private readonly settingsStore: ISettingsStore,
    @inject(UserDbServiceSymbol) private readonly userDbService: IUserDbService,
    @inject(ApiServiceSymbol) private readonly apiService: IApiService,
    @inject(I18nServiceSymbol) private readonly i18n: II18nService
  ) {
    this.countdown = new Countdown(60000, 1000);
  }

  @action
  public setCode(val: string) {
    this.code = val;
  }

  public async waitPhoneCodeConfirmation(
    phone: string,
    recaptchaConfirmationResult: firebase.auth.ConfirmationResult,
    verifier: firebase.auth.ApplicationVerifier
  ): Promise<void> {
    runInAction(() => {
      this.setPhone(phone);
      this.setRecaptchaConfirmationResult(recaptchaConfirmationResult);
      this.setVerifier(verifier);
    });
    await when(() => this.confirmed);
    await when(() => !this.settingsStore.loading); // wait while firebase`s settings will be loaded
  }
  @action
  public async resend() {
    this.errorStatus.clear();
    this.resendingStatus.startPending();
    if (!this.phone) {
      return;
    }
    try {
      await this.userDbService.sendSms(this.phone);
      await this.userStore.logEvents(this.phone, "resend");
      runInAction(() => {
        this.setFirstAttempt(false);
      });
    } catch (e) {
      console.error(`Confirmation failed: ${e}`);
      this.errorStatus.setMessage(
        this.i18n.i18next.t("sms.googleError").toString()
      );
    } finally {
      this.resendingStatus.stopPending();
    }
  }

  @action
  public async confirm() {
    this.errorStatus.clear();
    if (!this.code) {
      this.errorStatus.setMessage(
        this.i18n.i18next.t("sms.codeMissed").toString()
      );
      return;
    }

    this.confirming.startPending();

    try {
      const byCustomCode = await this.tryToAuthenticateByCustomCode();
      if (!byCustomCode) {
        const { recaptchaConfirmationResult } =
          this.ensureRequiredAttributesAreSet();
        await this.authService.confirmPhoneSignIn(
          recaptchaConfirmationResult,
          this.code
        );
      }
      if (this.phone) {
        await this.userStore.logEvents(this.phone, "enter");
      }

      this.setRecaptchaConfirmationResult(undefined);
      this.setConfirmed();
    } catch (e) {
      console.error(`Confirmation failed: ${e}`);
      if (
        isAPIErrors(e) &&
        e.code === SignInErrorCode.InvalidConfirmationCode
      ) {
        this.errorStatus.setMessage(
          this.i18n.i18next.t("sms.wrongCode").toString()
        );
      } else {
        this.errorStatus.setMessage(
          this.i18n.i18next.t("sms.googleError").toString()
        );
      }
    } finally {
      this.confirming.stopPending();
    }
  }

  @action
  public setShowInstructions(val: boolean) {
    this.showInstructions = val;
    if (val && this.phone) {
      this.userStore.startSubscribe(this.phone);
    }
  }

  private async tryToAuthenticateByCustomCode(): Promise<boolean> {
    const phone = this.phone;
    if (!phone) {
      throw new Error(
        "Phone is not set. But we should not have this case here"
      );
    }

    const customToken = await this.userDbService.checkCode(phone, this.code);
    if (customToken) {
      await this.userStore.signInWithCustomToken(customToken);
    }

    return !!customToken;
  }

  @action
  private setFirstAttempt(val: boolean) {
    this.firstAttempt = val;
  }

  private ensureRequiredAttributesAreSet(): {
    phone: string;
    recaptchaConfirmationResult: firebase.auth.ConfirmationResult;
    verifier: firebase.auth.ApplicationVerifier;
  } {
    const phone = this.phone;
    const recaptchaConfirmationResult = this.recaptchaConfirmationResult;
    const verifier = this.verifier;
    if (!phone || !recaptchaConfirmationResult || !verifier) {
      console.debug({ phone, recaptchaConfirmationResult, verifier });
      throw new Error("set attributes before");
    }
    return { phone, recaptchaConfirmationResult, verifier };
  }

  @action
  private setRecaptchaConfirmationResult(
    val: firebase.auth.ConfirmationResult | undefined
  ) {
    this.recaptchaConfirmationResult = val;
  }
  @action
  private setVerifier(val: firebase.auth.ApplicationVerifier | undefined) {
    this.verifier = val;
  }

  @action
  private setPhone(val: string) {
    this.phone = val;
  }

  @action
  private setConfirmed() {
    this.confirmed = true;
  }
}
