import { inject, injectable } from "inversify";
import { action, computed, flow, observable } from "mobx";

import type {
  ICoreApiService,
  IOrderDetails,
} from "@/services/CoreApiService/interfaces";
import type { IUserStore } from "@/stores/UserStore/interfaces";
import { ErrorMessage } from "@/models/ErrorMessage";
import { PendingStatus } from "@/models/PendingStatus";
import { CoreApiServiceSymbol, UserStoreSymbol } from "@/inversify/symbols";
import { bugsnagClient } from "@/bugsnag";
import { CheckoutScreenModel } from "@/components/klarna/CheckoutScreen/model";

@injectable()
export class OrderPayModel {
  public checkoutPending = new PendingStatus();

  @observable
  public order!: IOrderDetails;
  @observable
  public orderPs!: string;
  @observable
  public notFoundError!: string | undefined;
  @observable
  public snippetVisible: boolean = false;
  @observable
  public snippet!: string;
  @observable
  public somethingWentWrong: boolean | undefined;

  @observable
  public errorStatus = new ErrorMessage();

  constructor(
    @inject(UserStoreSymbol) private readonly userStore: IUserStore,
    @inject(CoreApiServiceSymbol)
    private readonly coreApiService: ICoreApiService
  ) {}

  @action
  public setSomethingWentWrong(val: boolean | undefined) {
    this.somethingWentWrong = val;
  }

  @action
  public setSnippetVisible(val: boolean) {
    this.snippetVisible = val;
  }
  @action
  public setSnippet(val: string) {
    this.snippet = val;
  }

  @action
  setOrder(order: IOrderDetails | undefined) {
    if (order) {
      this.order = order;
      this.orderPs = order?.psOrderId;
    }
  }

  public readonly pay = flow<void, []>(function* (this: OrderPayModel) {
    try {
      console.group("pay");
      this.checkoutPending.startPending();
      this.setSomethingWentWrong(false);
      yield this.load(this.orderPs);
    } finally {
      if (this.userStore.user) {
        bugsnagClient.notify(
          {
            message: "order",
            name: `User_${this.userStore.user.id}`,
          },
          { severity: "info" }
        );
      }
      this.checkoutPending.stopPending();
      console.groupEnd();
    }
  }).bind(this);

  public async load(orderId: string): Promise<void> {
    try {
      const resp = await this.coreApiService.loadConfirmation(orderId);

      const sid = orderId;
      const snip = resp.confirmationSnippet.snippet;

      /* CheckoutScreenModel.saveInternalOrderInLocalStore({
        bookingId: "",
        sid,
        eventId: 0,
        firstLessonStartDateTime: 0,
        locationId: 0,
        price: 0,
        priceWithDiscount: 0,
        isPacket: false,
        //productType: "",
      });*/

      CheckoutScreenModel.saveKlarnaSnippetInLocalStore(snip);
    } catch (e) {
      const errorData: any = e;
      this.errorStatus.setMessage(`Error:${errorData.error}`);
      throw errorData;
    }
  }
}
