import { inject, injectable } from "inversify";
import { action, observable, runInAction } from "mobx";
import moment from "moment";
import {
  ApiServiceSymbol,
  AuthServiceSymbol,
  NativeAppServiceSymbol,
  SettingsStoreSymbol,
  UserStoreSymbol,
} from "../../../inversify/symbols";
import { Countdown } from "../../../models/Countdown";
import { IApiService } from "../../../services/ApiService/interfaces";
import {
  IAuthService,
  isAxiosError,
} from "../../../services/AuthService/interfaces";
import { INativeAppService } from "../../../services/NativeAppService/interfaces";
import { safeLocalStorage } from "../../../stores/LocalStorage";
import { ISettingsStore } from "../../../stores/SettingsStore/interfaces";
import { IUserStore } from "../../../stores/UserStore/interfaces";
import {
  ICheckoutScreenModel,
  IInternalOrderRecord,
  ISavedInternalOrder,
  ISnippetRecord,
} from "./interfaces";

@injectable()
export class CheckoutScreenModel implements ICheckoutScreenModel {
  public static saveKlarnaSnippetInLocalStore(snippet: string) {
    console.debug("saveSnippetInLocalStore", snippet);
    const record: ISnippetRecord = {
      data: snippet,
      timestamp: Date.now(),
    };
    safeLocalStorage.setItem("kSnippet", JSON.stringify(record));
  }
  public static saveInternalOrderInLocalStore(orderData: ISavedInternalOrder) {
    console.debug("saveInternalOrderInLocalStore", orderData);
    const record: IInternalOrderRecord = {
      data: orderData,
      timestamp: Date.now(),
    };
    safeLocalStorage.setItem("orderData", JSON.stringify(record));
  }

  public static getKlarnaSnippetInLocalStore(): ISnippetRecord | undefined {
    const data = safeLocalStorage.getItem("kSnippet");
    let klarnaRecord: ISnippetRecord | undefined;
    if (data) {
      klarnaRecord = JSON.parse(data) as ISnippetRecord;
    } else {
      klarnaRecord = undefined;
    }
    console.debug("fetchSnippetFromLocalStore", klarnaRecord);

    return klarnaRecord;
  }

  public static fetchInternalOrderFromLocalStore():
    | IInternalOrderRecord
    | undefined {
    const data = safeLocalStorage.getItem("orderData");
    let orderRecord: IInternalOrderRecord | undefined;
    if (data) {
      orderRecord = JSON.parse(data) as IInternalOrderRecord;
    } else {
      orderRecord = undefined;
    }
    console.debug("fetchInternalOrderFromLocalStore", orderRecord);

    return orderRecord;
  }
  public static async cancelOrder(apiService: IApiService): Promise<void> {
    const orderData = CheckoutScreenModel.fetchInternalOrderFromLocalStore();
    if (!orderData) {
      console.debug("nothing found to cancel");
      return;
    }
    if (orderData.data.bookingId) {
      await this.cancelUnpaidBooking(apiService, orderData.data.bookingId);
    } else {
      console.error("Trying to cancel order without booking id");
    }

    CheckoutScreenModel.clearInternalOrderData();
  }
  public static async cancelUnpaidBooking(
    apiService: IApiService,
    bookingId: string
  ) {
    console.debug("Canceling old unpaid booking in the backend");
    try {
      await apiService.cancelUnpaidOrder(bookingId);
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response ? error.response.status : undefined;
        if (status === 400) {
          console.debug(
            "Server-side validation error",
            error,
            "Clearing internal order data. It is outdated"
          );
          CheckoutScreenModel.clearInternalOrderData();
        } else {
          console.error(
            "Unusual server-side error",
            error,
            "Clearing internal order data as fallback"
          );
          CheckoutScreenModel.clearInternalOrderData();
        }
      }
    }
  }
  public static clearInternalOrderData(): void {
    console.debug("clearInternalOrderData");
    safeLocalStorage.removeItem("orderData");
  }
  public static clearKlarnaSnippetData(): void {
    console.debug("clearSnippetData");
    safeLocalStorage.removeItem("kSnippet");
  }
  public countdown: Countdown;
  @observable
  public snippet = "";
  @observable
  public isExpiredSnippet = false;
  constructor(
    @inject(ApiServiceSymbol) private readonly apiService: IApiService,
    @inject(SettingsStoreSymbol) private readonly settingsStore: ISettingsStore,
    @inject(AuthServiceSymbol) private readonly authService: IAuthService,
    @inject(NativeAppServiceSymbol)
    private readonly nativeService: INativeAppService,
    @inject(UserStoreSymbol) private readonly userStore: IUserStore
  ) {
    const snippet = CheckoutScreenModel.getKlarnaSnippetInLocalStore();
    const diffMinutes = moment().diff(
      moment(snippet?.timestamp),
      "millisecond"
    );
    const isExpired = diffMinutes > 600000;
    const countdownVal = isExpired ? 0 : 600000 - diffMinutes; // 600000 is 10 min

    this.setIsExpiredSnippet(isExpired);

    this.countdown = new Countdown(countdownVal, 1000, true);
  }

  public async checkout(): Promise<void> {
    runInAction(() => {
      this.setSnippet("");
    });
    this.countdown.resetTimer();
    this.countdown.startTimer();
    const snippet = CheckoutScreenModel.getKlarnaSnippetInLocalStore();
    snippet && this.setSnippet(snippet.data);
  }

  @action
  private setSnippet(snippet: string) {
    this.snippet = snippet;
  }

  @action
  private setIsExpiredSnippet(val: boolean) {
    this.isExpiredSnippet = val;
  }
}
