import { IVehicle } from "../bookings/interfaces";
import { IApiMultiLanguage, IMultiLanguage } from "../LocationModel/interfaces";

export interface IInstructorModel {
  readonly id: number;
  readonly name: IMultiLanguage;
  readonly email: string;
  readonly isActive: boolean;
  readonly isAutomaticCar: boolean;
  readonly isManualCar: boolean;
  readonly instructorVehicleGearTypes: GearType[];
  readonly description: IMultiLanguage;
  readonly picture?: string;
  readonly locationIds: ReadonlySet<number>;
  readonly languagesSpoken: LanguagesSpoken[];
  readonly positionInLocation: ReadonlyMap<number, number>;
}

// FIXME IT IS COMMON - MOVE
export enum LanguagesSpoken {
  EN = "en",
  SV = "sv",
}
export enum MultipleGearType {
  McAMcA2 = "mcA,mcA2",
}
export enum GearType {
  Automatic = "automatic",
  Manual = "manual",
  McA = "mcA",
  McA1 = "mcA1",
  McA2 = "mcA2",
}
export enum GearTypeMc {
  McA = "mcA",
  McA1 = "mcA1",
  McA2 = "mcA2",
}
export interface ICoreInstructor {
  id: number;
  authId: string;
  isInstructor: boolean;
  name: IApiMultiLanguage;
  description: IApiMultiLanguage;
  languagesSpoken: LanguagesSpoken[];
  email: string;
  picture: string | null;
  isDisabled: boolean;
  associationsIds?: {
    ownVehicles: number[];
    locations: number[];
  };
  ownVehicles: IVehicle[];
  sharedVehicles: IVehicle[];
  options?: {
    instructor?: {
      inLocationsOrderIndex?: {
        [key: string]: number;
      };
    };
  };
}
