import {
  MotorcycleLessonType,
  VehicleType,
} from "@/models/AcademyServiceModel/interfaces";
import { IBooking, ICoreApiBooking } from "./interfaces";

export function formatCoreApiBooking(booking: ICoreApiBooking): IBooking {
  return {
    id: booking.id,
    startDate: booking.startAt,
    endDate: booking.endAt,
    instructorId: booking.instructorId,
    instructorName: booking.instructorData?.name || "Instructor's name",
    clientId: booking.clientId,
    clientName: booking.clientData?.name || "-",
    serviceId: booking.serviceId,
    serviceName: booking.serviceData?.name || "service",
    locationId: booking.locationId,
    isCanceled: booking.isCanceled,
    isPaid: booking.isPaid,
    isUTB: booking.isUbk,
    isLocked: booking.isLocked,
    isDriving: booking.isDriving,
    orderId: String(booking.orderId), // fixme
    isClass: booking.serviceData?.isClass,
    vehicle:
      booking.sharedResourceData &&
      booking.sharedResourceData.type === "vehicle"
        ? booking.sharedResourceData.data
        : undefined,
    serviceKey: booking.serviceData?.key || undefined,
    mcLessonType: booking.serviceData?.mcLessonType
      ? booking.serviceData?.mcLessonType
      : booking.serviceData?.vehicleType === VehicleType.mc
      ? MotorcycleLessonType.Lesson
      : undefined,
    vehicleType: booking.serviceData?.vehicleType || undefined,
    isSimulator: !!booking.serviceData?.name
      .toLowerCase()
      .includes("simulator"), // TODO ask backenders,
    attendees: booking?.metadata?.attendees || [],
    externalComment: booking?.slot?.externalComment,
  };
}
