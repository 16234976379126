import {
  action,
  autorun,
  computed,
  IReactionDisposer,
  observable,
  runInAction,
} from "mobx";

export interface IReadonlyCountdown {
  readonly millisecondsRemaining: number;
  readonly isFinished: boolean;
}
export class Countdown implements IReadonlyCountdown {
  @observable
  public millisecondsRemaining: number;
  public readonly originalMillisecondsRemaining: number;
  @computed
  public get isFinished(): boolean {
    return this.countIsFinished;
  }
  @observable
  public isTimerRunning: boolean;
  private readonly disposer: IReactionDisposer;
  private intervalID: number | undefined;
  private readonly interval: number;
  private readonly syncClock: boolean;
  @observable
  private countIsFinished: boolean = false;
  private startTime: Date | undefined;
  constructor(
    durationMilliseconds: number,
    interval: number = 1000,
    syncClock = false
  ) {
    this.syncClock = syncClock;
    this.interval = interval;
    this.originalMillisecondsRemaining = durationMilliseconds;
    this.millisecondsRemaining = durationMilliseconds;
    this.isTimerRunning = false;
    this.disposer = autorun(() => {
      if (this.isTimerRunning) {
        this.intervalID = window.setInterval(this.timerTick, interval);
      } else if (this.intervalID) {
        window.clearInterval(this.intervalID as any);
      }
    });
  }
  @action
  public startTimer() {
    this.isTimerRunning = true;
    this.startTime = new Date();
  }

  @action
  public stopTimer() {
    this.isTimerRunning = false;
    this.startTime = undefined;
  }

  @action
  public resetTimer() {
    this.stopTimer();
    this.setMillisecondsRemaining(this.originalMillisecondsRemaining);
  }

  public unmount() {
    window.clearInterval(this.intervalID as any);
    this.disposer();
  }

  @action
  private setMillisecondsRemaining(val: number) {
    this.millisecondsRemaining = val;
  }

  private timerTick = () => {
    let remaining: number;
    if (this.syncClock) {
      if (!this.startTime) {
        console.error("Start time is not set");
        remaining = 0;
      } else {
        remaining =
          this.originalMillisecondsRemaining -
          (Date.now() - this.startTime.valueOf());
      }
    } else {
      remaining = this.millisecondsRemaining - this.interval;
    }

    if (remaining > 0) {
      this.setMillisecondsRemaining(remaining);
    } else {
      this.lastTick();
    }
  };

  private lastTick() {
    this.setMillisecondsRemaining(0);

    runInAction(() => {
      this.countIsFinished = true;
    });

    this.stopTimer();
  }
}
