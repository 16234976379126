import { inject, injectable } from 'inversify';
import { action, observable } from 'mobx';
import { ReplaySubject } from 'rxjs';
import { CoreApiServiceSymbol } from '../../inversify/symbols';

import type { ICoreApiService } from "../../services/CoreApiService/interfaces";
import type { IClientOptions } from "@/services/CoreApiService/interfaces";

@injectable()
export class UserSettingsStore {
  private userIdSubject = new ReplaySubject<number>();
  constructor(
    @inject(CoreApiServiceSymbol)
    private readonly coreApiService: ICoreApiService
  ) {}

  @observable
  userId!: number;

  @action
  setUserId(userId: number) {
    this.userId = userId;
    this.userIdSubject.next(userId);
  }

  @observable
  settings!: IClientOptions;

  @action
  setSettings(settings: IClientOptions) {
    this.settings = settings;
  }
  async setLanguage(language: string) {
    // const userId = await this.userIdSubject.pipe(take(1)).toPromise();
    // this.coreApiService.setLanguage(this.userId, language);
  }
}
