import { IMultiLanguage } from "../models/LocationModel/interfaces";
import { Language } from "../services/I18nService/interfaces";

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export function mapNameToMultiLanguageName(
  en: string,
  sv?: string
): IMultiLanguage {
  return {
    en,
    sv: sv || en,
  };
}

export const AVAILABLE_LANGUAGES = [Language.Eng, Language.Swe];

export function isNotUndefinedPredicate<T>(t: T | undefined): t is T {
  return t !== undefined;
}
