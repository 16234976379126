import * as firebase from "firebase/app";
import { inject, injectable } from "inversify";
import { DbServiceSymbol } from "../../inversify/symbols";
import { IDbService } from "../DbService/interfaces";
import {
  DrivingGroupStatus,
  DrivingItemStatusChange,
  IDrivingGroup,
  IDrivingGroupsSummary,
  IDrivingPlan,
  IDrivingPlanCounters,
  IDrivingPlanDbService,
  IDrivingPlanMeta,
} from "./interfaces";

// TODO use transactions
// TODO trottling
/**
 * @note: it is copied as it is from previous system
 */
@injectable()
export class DrivingPlanDbService implements IDrivingPlanDbService {
  constructor(
    @inject(DbServiceSymbol) private readonly dbService: IDbService
  ) {}

  public writePlan(ownerId: string, plan: IDrivingPlan) {
    return this.dbService.set(`/userDrivingPlans/${ownerId}`, plan);
  }

  public async getPlan(ownerId: string) {
    const plan = await this.dbService.find<IDrivingPlan>(
      `/userDrivingPlans/${ownerId}`
    );
    if (!plan) {
      throw new Error(`Plan not found for owner ${ownerId}`);
    }
    return plan;
  }

  public async getPlanMeta(metaId: string) {
    const meta = await this.dbService.find<IDrivingPlanMeta>(
      `/drivingPlanMetas/${metaId}`
    );
    if (!meta) {
      throw new Error(`Meta not found for plan ${metaId}`);
    }
    return meta;
  }

  public subscribeToGroupsSummary(
    ownerId: string,
    callback: (summary: IDrivingGroupsSummary) => void
  ) {
    const path = `/userDrivingPlans/${ownerId}/groupsSummary`;
    return this.dbService.subscribe(path, callback);
  }

  public subscribeToGroup(
    ownerId: string,
    groupId: string,
    callback: (group: IDrivingGroup) => void
  ) {
    const path = `/userDrivingPlans/${ownerId}/groups/${groupId}`;
    return this.dbService.subscribe(path, callback);
  }

  public subscribeToCounters(
    ownerId: string,
    callback: (counters: IDrivingPlanCounters) => void
  ) {
    const path = `/userDrivingPlans/${ownerId}/counters`;
    return this.dbService.subscribe(path, callback);
  }

  public batchUpdate(updates: object) {
    return this.dbService.batchUpdate(updates);
  }

  public markGroupSummaryAsRead(
    ownerId: string,
    groupId: string,
    readerId: string
  ) {
    const path = `/userDrivingPlans/${ownerId}/groupsSummary/${groupId}/lastReadTime/${readerId}`;
    return { [path]: firebase.database.ServerValue.TIMESTAMP };
  }

  public setGroupSummaryStatus(
    ownerId: string,
    groupId: string,
    status: DrivingGroupStatus
  ) {
    const pathPrefix = `/userDrivingPlans/${ownerId}/groupsSummary/${groupId}`;
    return {
      [`${pathPrefix}/status`]: status,
      [`${pathPrefix}/ts`]: firebase.database.ServerValue.TIMESTAMP,
    };
  }

  public touchGroupSummary(ownerId: string, groupId: string) {
    const path = `/userDrivingPlans/${ownerId}/groupsSummary/${groupId}/ts`;
    return { [path]: firebase.database.ServerValue.TIMESTAMP };
  }

  public touchPlanAsInstructor(instructorId: string, ownerId: string) {
    const path = `/drivingPlanInstructorUpdates/${instructorId}/${ownerId}/updated`;
    return { [path]: firebase.database.ServerValue.TIMESTAMP };
  }

  public async addItemComment(
    ownerId: string,
    groupId: string,
    itemId: string,
    comment: string,
    instructorId?: string
  ) {
    await this.addItemEvent(
      ownerId,
      groupId,
      itemId,
      comment,
      undefined,
      instructorId
    );
  }

  public async addItemStatusChange(
    ownerId: string,
    groupId: string,
    itemId: string,
    change: DrivingItemStatusChange,
    instructorId?: string
  ) {
    await this.addItemEvent(
      ownerId,
      groupId,
      itemId,
      undefined,
      change,
      instructorId
    );
  }

  public async getPlanCounters(ownerId: string): Promise<IDrivingPlanCounters> {
    const path = `/userDrivingPlans/${ownerId}/counters`;
    return (
      (await this.dbService.find<IDrivingPlanCounters>(path)) || {
        finishedItems: 0,
        inProgressItems: 0,
        instructorComments: 0,
        studentComments: 0,
      }
    );
  }

  public updatePlanCounters(
    ownerId: string,
    counters: IDrivingPlanCounters
  ): object {
    const path = `/userDrivingPlans/${ownerId}/counters`;
    return { [path]: counters };
  }

  private async addItemEvent(
    ownerId: string,
    groupId: string,
    itemId: string,
    comment?: string,
    statusChange?: DrivingItemStatusChange,
    instructorId?: string
  ) {
    const path = `/userDrivingPlans/${ownerId}/groups/${groupId}/${itemId}/events`;
    const val = {
      ts: firebase.database.ServerValue.TIMESTAMP,
      ...(statusChange ? { statusChange } : {}),
      ...(comment ? { comment } : {}),
      ...(instructorId ? { instructorId } : {}),
    };
    return this.dbService.push(path, val);
  }
}
