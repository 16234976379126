import { observer } from "mobx-react";
import React, { SyntheticEvent } from "react";
import styles from "./styles.scss";
import classNames from "classnames";
import { action, observable } from "mobx";
import { lazyInject } from "@/inversify/container";
import { I18nServiceSymbol } from "@/inversify/symbols";
import type { II18nService } from "@/services/I18nService/interfaces";
import { Transition } from "react-transition-group";
import ReactDOM from "react-dom";
import { OverlayContext } from "../../../grid/ScreenContainer/context";
import { LanguageIcon } from "@/components/shared/icons/LanguageIcon";

@observer
export default class LanguageSelector extends React.Component<{}> {
  @observable
  private isOverlayVisible = false;

  private overlayRef = React.createRef<HTMLDivElement>();
  private triggerRef = React.createRef<HTMLButtonElement>();
  @lazyInject(I18nServiceSymbol)
  private readonly i18nService!: II18nService;
  constructor(props) {
    super(props);
  }
  componentDidMount(): void {}
  onKeyboardUp = (e: KeyboardEvent) => {
    if (e.code === "Escape") {
      this.setIsOverlayVisible(false);
    }
  };
  @action
  setIsOverlayVisible(isOverlayVisible: boolean) {
    this.isOverlayVisible = isOverlayVisible;
    if (isOverlayVisible) {
      document.addEventListener("keyup", this.onKeyboardUp);
    } else {
      document.removeEventListener("keyup", this.onKeyboardUp);
    }
    setTimeout(() => {
      this.overlayRef.current?.querySelector("button")?.focus();
    });
  }
  t = (str) => this.i18nService.i18next.t(str);
  en() {
    return <LanguageIcon width={32} height={31} stroke="#ffffff" />;
  }
  sv() {
    return <LanguageIcon width={32} height={31} stroke="#ffffff" />;
  }
  componentWillUnmount() {
    try {
      document.removeEventListener("keyup", this.onKeyboardUp);
    } catch (e) {}
  }
  render() {
    return (
      <div>
        <div className={classNames(styles.host)}>
          <button
            onClick={() => this.setIsOverlayVisible(!this.isOverlayVisible)}
            className={classNames(styles.triggerButton, styles.button)}
            ref={this.triggerRef}
            aria-haspopup="listbox"
            aria-expanded={this.isOverlayVisible}
          >
            {this.t(`languages-short.${this.i18nService.currentLanguage}`)}{" "}
            {this[this.i18nService.currentLanguage]()}
          </button>
          <Transition
            in={this.isOverlayVisible}
            nodeRef={this.overlayRef}
            unmountOnExit
            timeout={500}
          >
            {(state) => (
              <div
                className={classNames(
                  styles.overlayContainer,
                  state && `${styles["overlay-container-" + state]}`
                )}
                style={{
                  height:
                    +["entering", "entered"].includes(state) &&
                    this.overlayRef.current?.getBoundingClientRect().height,
                }}
                role="listbox"
              >
                {state && (
                  <div
                    className={classNames(
                      styles.overlay,
                      state && `${styles["overlay-" + state]}`
                    )}
                    tabIndex={-1}
                    ref={this.overlayRef}
                  >
                    <div className={classNames(styles.overlayItem)}>
                      <button
                        className={classNames(styles.button)}
                        onClick={() => {
                          this.i18nService.i18next.changeLanguage("en");
                          this.setIsOverlayVisible(false);
                        }}
                        role="option"
                        aria-label="English"
                      >
                        <LanguageIcon width={16} height={16} stroke="#000000" />
                        <span className={styles.lngName}>English</span>
                      </button>
                    </div>
                    <div className={classNames(styles.overlayItem)}>
                      <button
                        className={classNames(styles.button)}
                        onClick={() => {
                          this.i18nService.i18next.changeLanguage("sv");
                          this.setIsOverlayVisible(false);
                        }}
                        role="option"
                        aria-label="Swedish"
                      >
                        <LanguageIcon width={16} height={16} stroke="#000000" />
                        <span className={styles.lngName}>Svenska</span>
                      </button>
                    </div>
                  </div>
                )}

                <div
                  tabIndex={0}
                  onFocus={() => this.triggerRef.current?.focus()}
                ></div>
              </div>
            )}
          </Transition>
        </div>
        <OverlayContext.Consumer>
          {(value: React.Ref<HTMLDivElement>) => {
            return (
              this.isOverlayVisible &&
              (value as any)?.current &&
              ReactDOM.createPortal(
                <div
                  className={classNames(styles.backdrop)}
                  onClick={() => this.setIsOverlayVisible(false)}
                ></div>,
                (value as any)?.current
              )
            );
          }}
        </OverlayContext.Consumer>
      </div>
    );
  }
}
