import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { lazyInject } from "../../../../../inversify/container";
import { I18nServiceSymbol } from "../../../../../inversify/symbols";
import {
  AcademyServiceTypes,
  IAcademyServiceModel,
  ProductType,
} from "../../../../../models/AcademyServiceModel/interfaces";
import type { II18nService } from "@/services/I18nService/interfaces";
import { IServiceOption } from "../interfaces";
import styles from "./styles.scss";
import { Button } from "../../Button";
import { LOAD_MORE_SERVICES } from "../../../../bookings/OrderScreen/OrderForm/interfaces";

interface IServiceListOptionProps<T> {
  className?: string;
  activeId: string;
  dataItem: T & typeof LOAD_MORE_SERVICES;
  index: number;
  focused: boolean;
  selected: boolean | string;
  disabled: boolean;
  onSelect: {
    (dataItem: T, event: React.SyntheticEvent<unknown>): void;
    (
      dataItem: typeof LOAD_MORE_SERVICES,
      event: React.SyntheticEvent<unknown>
    ): void;
  };
}

@observer
export class ServiceListOption<
  T extends IServiceOption
> extends React.Component<IServiceListOptionProps<T>, {}> {
  @lazyInject(I18nServiceSymbol)
  private readonly i18nService!: II18nService;

  public render() {
    if (this.props.dataItem.value === LOAD_MORE_SERVICES.value) {
      return (
        <li className={classNames("rw-list-option")}>
          <Button
            className={classNames(styles.loadMoreButton)}
            onClick={($event) => {
              $event.stopPropagation();
              $event.preventDefault();
              this.props.dataItem.onButtonClick();
              
              // restore focus after proposed element delition.
              try {
                (document as any).querySelector('.select-service-container .rw-popup li:first-child').focus();
              } catch(e) {

              }
            }}
          >{`${this.i18nService.i18next.t(`view-all`)}`}</Button>
        </li>
      );
    }

    const {
      className,
      children,
      dataItem,
      focused,
      selected,
      disabled,
      activeId,
      onSelect,
      ...otherProps
    } = this.props;

    const Tag = "li";

    const classes = {
      "rw-state-disabled": disabled,
      "rw-state-focus": focused,
      "rw-state-selected": selected,
    };

    const id = focused ? activeId : undefined;
    const { meta, isClass, packageLessonsQty, productType } = (dataItem as T)
      .model;
    const isService = productType === ProductType.Service;

    const description = ((dataItem) => {
          return dataItem.model.description[this.i18nService.currentLanguage];
    })(dataItem);

    return (
      <Tag
        id={id}
        role="option"
        tabIndex={!disabled ? -1 : undefined}
        aria-selected={!!selected}
        className={classNames(
          "rw-list-option",
          styles.li,
          classes,
          { [styles.selected]: selected },
          className
        )}
        onClick={this.handleSelect}
        {...otherProps}
      >
        <div className={styles.textBlock}>
          <span className={styles.name}>{dataItem.label}</span>
          <span className={styles.description}>
            <span dangerouslySetInnerHTML={{ __html: description }}></span>
            <>
              <span title={"Service id:" + dataItem.value}>&nbsp;</span>
            </>
          </span>
        </div>
        <div
          className={classNames(styles.button, {
            [styles.single]: dataItem.type === ServiceOptionType.Single,
            [styles.packet]: dataItem.type === ServiceOptionType.Package,
            [styles.courses]: dataItem.type === ServiceOptionType.Class,
            [styles.other]: dataItem.type === ServiceOptionType.Other,
          })}
        >
          <>
            {`${dataItem.model.priceWithVat}:-`}
            {dataItem.isClass && <br />}
            {dataItem.isClass && (
              <span className={styles.perPerson}>
                {this.i18nService.i18next.t("order.perPerson").toString()}
              </span>
            )}
          </>
        </div>
      </Tag>
    );
  }

  private handleSelect = (event: React.SyntheticEvent<unknown>) => {
    console.log('select event');
    const { onSelect, disabled, dataItem } = this.props;
    if (onSelect && !disabled) {
      onSelect(dataItem, event);
    }
  };
}

export enum ServiceOptionType {
  Single,
  Package,
  Class,
  Other,
}
export function calcServiceOptionType(
  model: IAcademyServiceModel
): ServiceOptionType {
  if (model.meta?.type === AcademyServiceTypes.Class) {
    return ServiceOptionType.Class;
  } else if (model.meta?.type === AcademyServiceTypes.Test) {
    return ServiceOptionType.Other;
  // some bundles can have packageLessonsQty === 1, that's why we need to check productType
  } else if (model.productType === ProductType.Bundle) {
    return ServiceOptionType.Package;
  } else if (model.packageLessonsQty === 1) {
    return ServiceOptionType.Single;
  } else {
    return ServiceOptionType.Package;
  }
}
