import i18next from "i18next";

export interface II18nService {
  readonly i18next: i18next.i18n;
  readonly currentLanguage: Language;
  lng: string;
}

export enum Language {
  Eng = "en",
  Swe = "sv",
}
