import type {
  IBooking,
  ICoreApiService,
} from "@/services/CoreApiService/interfaces";
import { inject, injectable } from "inversify";
import { action, observable } from "mobx";
import { CoreApiServiceSymbol } from "../../inversify/symbols";

@injectable()
export class ReservePayStore {
  @observable
  private booking!: IBooking | undefined;

  @action
  setBooking(booking: IBooking | undefined) {
    this.booking = booking;
  }

  constructor(
    @inject(CoreApiServiceSymbol)
    private readonly coreApiService: ICoreApiService
  ) {}

  async init(bookingId: number) {
    this.setBooking(undefined);
    try {
      const booking = await this.coreApiService.getBooking(`${bookingId}`);
      this.setBooking(booking);
      return booking;
    } catch (e) {
      return Promise.resolve(undefined);
    }
  }
}
