import type { UserSettingsStore } from '@/stores/UserStore/UserSettingsStore';
import LanguageDetector from 'i18next-browser-languagedetector';
import { injectable } from 'inversify';

import { Language } from './interfaces';

@injectable()
class MdaDetector {
  name = 'mda';

  constructor(private userSettingsStore: UserSettingsStore) {}
  lookup = (options): any => {
  }

  cacheUserLanguage = (lng, options): any => {
    this.userSettingsStore.setLanguage(lng)
  }
}

/**
 * In order to support "eng" and "swe" codes we are using not documented feature:
 * overriding "detect" function.
 */
export class MyLanguageDetector extends LanguageDetector {
  constructor() {
    super();
  }
  public detect(...args: any[]): string {
    // @ts-ignore
    let language = super.detect(...args);

    if (language === "eng" || language.indexOf("en") === 0) {
      language = Language.Eng;
    } else if (language === "swe" || language.indexOf("sv") === 0) {
      language = Language.Swe;
    }
    return language;
  }
}

export const MyLanguageDetectorFactory = (userSettingsStore) => {
  return class MyLanguageDetector extends LanguageDetector {
    constructor() {
      super();
      this.addDetector(new MdaDetector(userSettingsStore));
    }
    public detect(...args: any[]): string {
      // @ts-ignore
      let language = super.detect(...args);

      if (language === "eng" || language.indexOf("en") === 0) {
        language = Language.Eng;
      } else if (language === "swe" || language.indexOf("sv") === 0) {
        language = Language.Swe;
      }
      return language;
    }
  };
};
