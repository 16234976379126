import { injectable } from "inversify";
import { action, observable } from "mobx";
import {
  CalendarEventType,
  EventDetailViewType,
} from "../../components/bookings/ScheduleScreen/Calendar/interfaces";
import { IInstructorModel } from "../InstructorModel/interfaces";
import { ILessonEventModel, IPickup, IVehicle } from "./interfaces";
import { MotorcycleLessonType } from "../AcademyServiceModel/interfaces";

@injectable()
export class LessonEventModel implements ILessonEventModel {
  @observable
  public id!: number;
  @observable
  public name: string = "";
  @observable
  public pickup: IPickup = {
    address: "",
    htmlFormatted: false,
  };
  @observable
  public mcLessonType!: MotorcycleLessonType;
  @observable
  public externalComment!: string;
  @observable
  public from!: Date;
  @observable
  public isUTB: boolean = false;
  @observable
  public isDriving: boolean = false;
  @observable
  public isLocked: boolean = false;
  @observable
  public instructor!: IInstructorModel | undefined;
  @observable
  public vehicle!: IVehicle | undefined;
  @observable
  public fallbackInstructorName!: string | undefined;
  @observable
  public to!: Date;
  @observable
  public typeKey: CalendarEventType = CalendarEventType.Lesson;
  @observable
  isPaid!: boolean;

  public getEventDetailView(): Promise<EventDetailViewType<ILessonEventModel>> {
    return import(
      /* webpackChunkName: "LessonEvenDetailView" */ "../../components/bookings/EventsListScreen/EventDetails/EventDetailView/LessonEvenDetailView"
    ).then((res) => res.LessonEventDetailView);
  }

  @action
  public setFrom(from: Date): void {
    this.from = from;
  }

  @action
  public setTo(to: Date): void {
    this.to = to;
  }

  @action
  public setInstructor(instructor: IInstructorModel | undefined): void {
    this.instructor = instructor;
  }
  @action
  public setVehicle(vehicle?: IVehicle): void {
    this.vehicle = vehicle;
  }
  @action
  public setFallbackInstructor(instructorName: string | undefined): void {
    this.fallbackInstructorName = instructorName;
  }

  @action
  public setName(name: string): void {
    this.name = name;
  }
  @action
  public setId(id: number): void {
    this.id = id;
  }
  @action
  public setPickup(pickup: IPickup): void {
    this.pickup = pickup;
  }
  @action
  public setMcLessonType(type: MotorcycleLessonType): void {
    this.mcLessonType = type;
  }
  @action
  public setExternalComment(comment: string): void {
    this.externalComment = comment;
  }
  @action
  public setIsUTB(isUTB: boolean): void {
    this.isUTB = isUTB;
  }
  @action
  public setIsDriving(isDriving: boolean): void {
    this.isDriving = isDriving;
  }
  @action
  public setIsLocked(isLocked: boolean): void {
    this.isLocked = isLocked;
  }

  public isEditable(): boolean {
    if (!this.isUTB && !this.isDriving && !this.isLocked) {
      function reschedulePermission(date) {
        const now = new Date();
        const lessonDate = new Date(date);

        const isTomorrow =
          lessonDate.getDate() === now.getDate() + 1 &&
          lessonDate.getMonth() === now.getMonth() &&
          lessonDate.getFullYear() === now.getFullYear();

        const isPastNoon = now.getHours() >= 12;

        if (isTomorrow && isPastNoon) {
          return false;
        }

        return true;
      }
      return reschedulePermission(this.from);
    } else {
      return false;
    }
  }

  public isEditableViaSupport(): boolean {
    return this.from.getTime() > Date.now();
  }

  @action
  public setTypeKey(typeKey: CalendarEventType): void {
    this.typeKey = typeKey;
  }
}
