import { IStorage } from "./interfaces";

export class InMemoryStorage implements IStorage {
  /**
   * number of stored items.
   */
  public length: number = 0;
  private readonly cache!: { [key: string]: any };

  constructor() {
    Object.defineProperty(this, "cache", {
      value: {},
    });
    Object.defineProperty(this, "localStorageMemory", {
      value: 0,
      writable: true,
    });
  }

  public getItem(key: string): string | null {
    if (key in this.cache) {
      return this.cache[key];
    }

    return null;
  }

  public setItem(key: string, value: string): void {
    if (typeof value === "undefined") {
      this.removeItem(key);
    } else {
      if (!this.cache.hasOwnProperty(key)) {
        this.length++;
      }

      this.cache[key] = `${value}`;
    }
  }

  public removeItem(key: string): void {
    if (this.cache.hasOwnProperty(key)) {
      delete this.cache[key];
      this.length--;
    }
  }

  public key(index: number): string | null {
    return Object.keys(this.cache)[index] || null;
  }

  public clear(): void {
    for (const key of Object.keys(this.cache)) {
      this.removeItem(key);
    }
  }
}
