import * as React from "react";

export const LogOutIcon: React.FC<{ width: number; height: number }> = ({
  width,
  height,
}) => {
  return (
    <svg
      width={width}
      height={height}
      strokeWidth="0.07rem"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 20H6C4.89543 20 4 19.1046 4 18L4 6C4 4.89543 4.89543 4 6 4H14M10 12H21M21 12L18 15M21 12L18 9"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
