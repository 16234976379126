import * as React from "react";

import { IIconProps } from "./interfaces";

export const ArrowLeft = (props: IIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 20" {...props}>
    <path
      fill="currentColor"
      d="M9.717 0L12 2.283 4.583 9.717 12 17.15l-2.283 2.283L0 9.717z"
    />
  </svg>
);
