import { action, observable } from "mobx";

export interface IReadonlyPendingStatus {
  readonly isPending: boolean;
}

export class PendingStatus implements IReadonlyPendingStatus {
  @observable
  private isPendingInternal: boolean = false;

  public get isPending() {
    return this.isPendingInternal;
  }

  @action
  public setPending(val: boolean) {
    this.isPendingInternal = val;
  }

  @action
  public startPending() {
    this.setPending(true);
  }

  @action
  public stopPending() {
    this.setPending(false);
  }
}
