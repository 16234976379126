import { injectable } from "inversify";
import * as moment from "moment";
import { IDatetime } from "./interfaces";

@injectable()
export class Datetime implements IDatetime {
  public readonly strictDateMode = false; // TODO: strict mode

  public formatMonth(date: moment.Moment): string {
    return moment(date).format("MMMM");
  }
  public toDateString(date: moment.Moment | Date): string {
    if (!moment.isMoment(date)) {
      date = moment(date);
    }
    return date.format("YYYY-MM-DD");
  }

  public fromDateString(date: string): moment.Moment | undefined {
    const val = moment(date, "YYYY-MM-DD", this.strictDateMode);
    let res: moment.Moment | undefined;
    if (val.isValid()) {
      res = val;
    } else {
      res = void 0;
    }

    return res;
  }

  public toTimeString(
    date: Date | moment.Moment,
    midnightProtect = false
  ): string {
    if (!moment.isMoment(date)) {
      date = moment(date);
    }
    let result = date.format("HH:mm:ss");
    if (midnightProtect && result === "00:00:00") {
      result = "24:00:00";
    }

    return result;
  }

  public toDateTimeString(date: Date | moment.Moment): string {
    if (!moment.isMoment(date)) {
      date = moment(date);
    }

    return date.format("YYYY-MM-DD HH:mm:ss");
  }

  public fromTimeString(date: string): moment.Moment | undefined {
    const res: moment.Moment = moment(date, "HH:mm:ss", this.strictDateMode);
    if (res.isValid()) {
      return res;
    } else {
      return undefined;
    }
  }

  public fromDateTimeString(
    dateString: string,
    timeString?: string
  ): moment.Moment | undefined {
    if (timeString) {
      const datePart = this.fromDateString(dateString);
      const timePart = this.fromTimeString(timeString);

      return datePart && timePart
        ? datePart
            .hours(timePart.hours())
            .minutes(timePart.minutes())
            .seconds(0)
        : void 0;
    } else {
      const date = moment(
        dateString,
        "YYYY-MM-DD HH:mm:ss",
        this.strictDateMode
      );

      return date.isValid() ? date : void 0;
    }
  }

  public toDateTimeStrings(
    date: Date | moment.Moment,
    midnightProtect = false
  ): string {
    if (!moment.isMoment(date)) {
      date = moment(date);
    }
    const timePart = this.toTimeString(date, midnightProtect);
    const datePart = this.toDateString(date);

    return `${datePart} ${timePart}`;
  }

  public compareTimeStrings(a: string, b: string): number {
    const ap = this.fromTimeString(a);
    const bp = this.fromTimeString(b);

    return ap ? (bp ? ap.unix() - bp.unix() : 1) : -1;
  }

  public daysList(from: moment.Moment, to: moment.Moment): moment.Moment[] {
    const start = from.clone().startOf("day");
    const res: moment.Moment[] = [];
    const current: moment.Moment = start;
    while (current.unix() <= to.unix()) {
      res.push(current.clone());
      current.add(1, "day");
    }

    return res;
  }

  public fromDayAndTimePart(
    dayPart: Date | moment.Moment,
    timePart: Date | moment.Moment
  ): moment.Moment {
    const res = moment.isMoment(dayPart) ? dayPart.clone() : moment(dayPart);
    const time = moment.isMoment(timePart)
      ? timePart.clone()
      : moment(timePart);
    res
      .hours(time.hours())
      .minutes(time.minutes())
      .seconds(time.seconds())
      .milliseconds(time.milliseconds());

    // TODO: 24:00:00
    return res;
  }

  public toLocalDate(date: Date | moment.Moment): string {
    return moment(date).format("LL");
  }
  public toLocalTime(date: Date | moment.Moment): string {
    return moment(date).format("LT");
  }
  public toLocalDatetime(date: Date | moment.Moment): string {
    return moment(date).format("LLL");
  }
}
