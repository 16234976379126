import classNames from "classnames";
import * as React from "react";
import { IDatePickerOption } from "./index";
import styles from "./styles.scss";

export interface IDateCellProps<T> {
  className?: string;
  date: Date;
  option?: IDatePickerOption<T>;
  datePickerDate: Date;
  invisible?: boolean;
  selected?: boolean;
  selectable: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
  onSelect?: (date: Date) => void;
}

interface IEvent<E extends IEvent<any>> {
  disabled: boolean;
}
interface ICalendarDayCellState {}
export class DateCell<T extends IEvent<any>> extends React.PureComponent<
  IDateCellProps<T>,
  ICalendarDayCellState
> {
  public render() {
    const isDisabledSlot = this.isDisabledSlot();
    return (
      <div
        className={classNames(
          styles.calendarDayCell,
          {
            [styles.selected]: !!this.props.selected,
            [styles.small]: !this.props.selectable,
            [styles.invisible]: !!this.props.invisible,
            [styles.selectable]: this.props.selectable && !isDisabledSlot,
            [styles.disabled]: isDisabledSlot,
          },
          this.props.className
        )}
        onClick={this.onClick}
      >
        <div className={styles.circle}>
          <span className={styles.dayN}>{this.props.date.getDate()}</span>
        </div>
      </div>
    );
  }

  private onClick = (event: React.SyntheticEvent) => {
    if (this.props.onClick) {
      this.props.onClick(event);
    }
    if (
      this.props.selectable &&
      this.props.onSelect &&
      !this.isDisabledSlot()
    ) {
      this.props.onSelect(this.props.date);
    }
  };

  private isDisabledSlot(): boolean {
    return this.models.some((model) => model.disabled);
  }

  private get models(): Array<IEvent<any>> {
    const option = this.props.option;
    if (!option) {
      return [];
    }
    return Array.isArray(option.model) ? option.model : [option.model];
  }
}
