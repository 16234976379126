import { observable } from "mobx";
import { DrivingItem } from "./DrivingItem";

export class DrivingSubgroup {
  @observable
  public items: DrivingItem[] = [];

  public id: string = "";
  public title?: string;
}
