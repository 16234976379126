import debounce from "lodash-es/debounce";
import { action, computed, flow, observable, toJS } from "mobx";
import { CancellablePromise } from "mobx/lib/api/flow";
import { PendingStatus } from "./PendingStatus";
import { Subject, timer } from "rxjs";
import { debounceTime, debounce as debounceOperator, distinctUntilChanged, tap } from "rxjs/operators";

export type SyncValidatorType<V> = (value: V) => string | undefined;
export type AsyncValidatorType<V> = (value: V) => Promise<string | undefined>;
export type AsyncValidatorCancellableType<V> = (value: V, cancel?: Promise<void>) => Promise<string | undefined>;
export class FormFieldState<V> {
  @observable
  public value: V;
  @observable
  public touched: boolean = false;
  @observable
  public validatingStatus = new PendingStatus();
  @observable
  public validationError: string | undefined;
  @computed
  public get isValid(): boolean {
    return !this.validationError;
  }
  public readonly requestValidation: () => void;
  private readonly validationDelay: number;
  private readonly validator?: SyncValidatorType<V> | AsyncValidatorType<V>;

  private flowPromise: CancellablePromise<any> | undefined;
  constructor(
    initialValue: V,
    options: {
      validator?: SyncValidatorType<V> | AsyncValidatorType<V>;
      validationDelay?: number; // debounce async validation
    } = {}
  ) {
    this.value = initialValue;
    this.validator = options.validator;
    this.validationDelay = options.validationDelay || 0;
    const validationFlow = flow(function* (this: FormFieldState<V>) {
      const value = this.value;
      this.validationError = undefined;

      if (this.validator) {
        try {
          this.validationError = yield this.validator(value);
        } catch (e) {
          this.validationError = String(e);
        } finally {
          this.validatingStatus.stopPending();
        }
      }
    }).bind(this);

    const debouncedValidation = debounce(() => {
      if (this.flowPromise) {
        this.flowPromise.cancel();
      }
      this.flowPromise = validationFlow();
      this.flowPromise!.catch((err: unknown) => console.error(err));
    }, this.validationDelay);

    this.requestValidation = () => {
      this.validatingStatus.startPending();
      debouncedValidation();
    };
  }
  @action
  public setValue(value: V, validate = true) {
    this.value = value;
    if (validate) {
      this.requestValidation();
    }
  }

  @action
  public setTouched(value = true) {
    this.touched = value;
  }
}

export class FormFieldPendingState<V> {
  @observable
  public value: V;
  @observable
  public touched: boolean = false;
  @observable
  public validatingStatus = new PendingStatus();
  @observable
  public validationError: string | undefined;
  @computed
  public get isValid(): boolean {
    return !this.validationError;
  }
  public readonly requestValidation: () => void;
  private readonly validationDelay: number | ((value: V) => number);
  private readonly validator?: AsyncValidatorType<V>  | AsyncValidatorCancellableType<V>;

  private flowPromise: CancellablePromise<any> | undefined;
  constructor(
    initialValue: V,
    options: {
      validator?: AsyncValidatorType<V> | AsyncValidatorCancellableType<V> ;
      validationDelay?: number | ((value: V) => number); // debounce async validation
    } = {}
  ) {
    this.value = initialValue;
    this.validator = options.validator;
    this.validationDelay = options.validationDelay || 0;

    const validationFlow = flow(function* (this: FormFieldPendingState<V>, canceller: Promise<void>) {
      const value = this.value;
      this.validationError = undefined;

      if (this.validator) {
        try {
          this.validationError = yield this.validator(value, canceller);
        } catch (e) {
          this.validationError = String(e);
        } finally {
          this.validatingStatus.stopPending();
        }
      }
    }).bind(this);

    const validateableSubject = new Subject<V>();
    let canceller: Subject<void>;
    validateableSubject.pipe(
      distinctUntilChanged(),
      tap(() => {
        this.validatingStatus.startPending();
      }),
      debounceOperator((v: V) => {
        if(typeof this.validationDelay === 'function') {
          return timer(this.validationDelay(v))
        }
        return timer(this.validationDelay);
      })
    ).subscribe(() => {
      if(canceller) {
        canceller.next();
        canceller.complete();
      }
      if (this.flowPromise) {
        this.flowPromise.cancel();
      }
      canceller = new Subject();
      this.flowPromise = validationFlow(canceller.toPromise());
      this.flowPromise!.catch((err: unknown) => console.error(err)).finally(() => {
        canceller.next();
        canceller.complete();
        this.validatingStatus.stopPending();
      });
    })

    this.requestValidation = () => {
      validateableSubject.next(toJS(this.value))
    };
  }
  @action
  public setValue(value: V, validate = true) {
    this.value = value;
    if (validate) {
      this.requestValidation();
    }
  }

  @action
  public setTouched(value = true) {
    this.touched = value;
  }
}
