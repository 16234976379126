import { injectable } from "inversify";
import { UnsafeFirebaseDictionary } from "../../stores/SettingsStore/interfaces";

import { mapNameToMultiLanguageName } from "../../helpers";
import { IMultiLanguage } from "../LocationModel/interfaces";
import {
  GearType,
  ICoreInstructor,
  IInstructorModel,
  LanguagesSpoken,
} from "./interfaces";
import { LANGUAGES_DICT } from "./LanguagesDict";

@injectable()
export class InstructorModel implements IInstructorModel {
  public readonly id: number;
  public readonly name: IMultiLanguage;
  public readonly email: string;
  public readonly isActive: boolean;
  public readonly isAutomaticCar: boolean;
  public readonly isManualCar: boolean;
  public readonly instructorVehicleGearTypes: GearType[];
  public readonly description: IMultiLanguage;
  public readonly picture?: string;
  public readonly locationIds: ReadonlySet<number>;
  public readonly languagesSpoken: LanguagesSpoken[];
  public readonly positionInLocation: ReadonlyMap<number, number>;

  constructor(private readonly apiInstructor: ICoreInstructor) {
    const locations = apiInstructor.associationsIds?.locations || [];

    const languages = !!apiInstructor.languagesSpoken.length
      ? ` (${apiInstructor.languagesSpoken
          .map((language) =>
            language in LANGUAGES_DICT ? LANGUAGES_DICT[language] : language
          )
          .join(", ")})`
      : "";

    const inLocationSortingIndexes =
      apiInstructor.options?.instructor?.inLocationsOrderIndex;

    const positionInLocationDict = this.dictFromUnsafeValue(
      inLocationSortingIndexes
    );

    const positionInLocation = new Map<number, number>();
    for (const locationKey of Object.keys(positionInLocationDict)) {
      positionInLocation.set(
        Number(locationKey),
        positionInLocationDict[locationKey]
      );
    }
  
    this.id = apiInstructor.id;
    this.name = mapNameToMultiLanguageName(
      `${apiInstructor.name.en} · ${languages}`,
      `${apiInstructor.name.sw} · ${languages}`
    );
    this.description = mapNameToMultiLanguageName(
      `${apiInstructor.description.en}`,
      `${apiInstructor.description.sw}`
    );
    this.email = apiInstructor.email;
    this.isActive = !apiInstructor.isDisabled;
    this.isAutomaticCar = [
      ...apiInstructor.ownVehicles,
      ...apiInstructor.sharedVehicles,
    ].some((value) => value.gearType === GearType.Automatic);
    this.isManualCar = [
      ...apiInstructor.ownVehicles,
      ...apiInstructor.sharedVehicles,
    ].some((value) => value.gearType === GearType.Manual);
    this.instructorVehicleGearTypes =  [
      ...apiInstructor.ownVehicles,
      ...apiInstructor.sharedVehicles,
    ].filter((value) => value.gearType).map(value => value.gearType).filter((value, index, array) => array.indexOf(value) === index);
    this.picture = apiInstructor.picture || undefined; // fixme
    this.locationIds = new Set(locations);
    this.languagesSpoken = apiInstructor.languagesSpoken;
    this.positionInLocation = positionInLocation;
  }

  private dictFromUnsafeValue<T>(unsafeValue: UnsafeFirebaseDictionary<T>): {
    [key: string]: T;
  } {
    let res: { [key: number]: T };
    if (!unsafeValue) {
      res = {};
    } else if (Array.isArray(unsafeValue)) {
      res = {};
      unsafeValue.forEach((v, i) => {
        res[i] = v;
      });
    } else {
      res = unsafeValue;
    }

    return res;
  }
}
