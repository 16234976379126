import classNames from "classnames";
import { computed } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { II18nService } from "src/services/I18nService/interfaces";
import { lazyInject } from "../../../../inversify/container";
import {
  I18nServiceSymbol,
  UiStoreSymbol,
} from "../../../../inversify/symbols";
import { IUiStore } from "../../../../stores/UiStore/interfaces";
import { RiseLoader } from "../../loaders/RiseLoader";
import { MyDrivingHeader } from "./MyDrivingHeader";
import styles from "./styles.scss";
import { OverlayContext } from "./context";

interface IScreenContainerProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children: React.ReactNode;
}

const ScreenContainerContext = React.createContext(false);
@observer
export class ScreenContainer extends React.Component<IScreenContainerProps> {
  public static contextType = ScreenContainerContext;
  public context!: React.ContextType<typeof ScreenContainerContext>;
  @lazyInject(UiStoreSymbol) private readonly uiStore!: IUiStore;
  @lazyInject(I18nServiceSymbol)
  private readonly i18nService!: II18nService;
  overlayRef: React.Ref<HTMLDivElement> = React.createRef();

  @computed
  private get loading(): boolean {
    return this.uiStore.loadingTokens.length > 0;
  }
  public componentDidMount(): void {
    if (this.context === true) {
      console.warn("There must be only one ScreenContainer on the screen");
    }
  }

  public render() {
    const { className, children, ...otherProps } = this.props;
    const { widgetMode } = this.uiStore;
    return (
      <ScreenContainerContext.Provider value={true}>
        <OverlayContext.Provider value={this.overlayRef}>
          <div
            className={classNames(
              styles.container,
              { [styles.loading]: this.loading },
              { [styles.widgetMode]: widgetMode },
              className
            )}
            {...otherProps}
          >
            <div>
              {widgetMode && <MyDrivingHeader />}
              {this.loading && (
                <RiseLoader
                  label={this.i18nService.i18next.t("loading").toString()}
                  className={styles.loader}
                />
              )}
              <div className={styles.content}>{children}</div>
              <div ref={this.overlayRef} id="overlay-container" />
            </div>
          </div>
        </OverlayContext.Provider>
      </ScreenContainerContext.Provider>
    );
  }
}
