import { DrivingGroupStatus } from "../../services/DrivingPlanDbService/interfaces";

export class DrivingGroupSummary {
  public constructor(
    public readonly groupId: string,
    public readonly status: DrivingGroupStatus,
    public readonly newEvents: boolean,
    public readonly groupOrder: number
  ) {}
}
