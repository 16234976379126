import { History } from "history";
import { inject, injectable, interfaces } from "inversify";
import {
  action,
  computed,
  IReactionDisposer,
  observable,
  reaction,
} from "mobx";
import * as moment from "moment";
import { IUserDbService } from "src/services/UserDbService/interfaces";
import { IDatetime } from "../../../../helpers/Datetime/interfaces";
import {
  ApiServiceSymbol,
  AuthServiceSymbol,
  DatetimeSymbol,
  EventModelFactorySymbol,
  HistorySymbol,
  InversifyContextSymbol,
  UserDbServiceSymbol,
  UserStoreSymbol,
} from "../../../../inversify/symbols";
import { IEventModelFactory } from "../../../../models/bookings/interfaces";
import { PendingStatus } from "../../../../models/PendingStatus";
import { IUserModel } from "../../../../models/UserModel/interfaces";
import { IApiService } from "../../../../services/ApiService/interfaces";
import { IAuthService } from "../../../../services/AuthService/interfaces";
import { IUserStore } from "../../../../stores/UserStore/interfaces";
import { IDatePickerOption } from "../../../shared/DatePicker";
import { ICalendarEvent, ICalendarModel } from "./interfaces";

@injectable()
export class CalendarModel implements ICalendarModel {
  public get lessonsLeft(): number {
    const { user } = this.userStore;
    if (!user) {
      return 0;
    }
    const { saldoBalance } = user;
    if (!saldoBalance) {
      return 0;
    }
    return saldoBalance;
  }
  public readonly events = observable.array<ICalendarEvent<any>>();
  @computed
  public get selectableDays(): ReadonlyArray<
    IDatePickerOption<ICalendarEvent<any>>
  > {
    const days: Array<IDatePickerOption<ICalendarEvent<any>>> = [];
    this.eventsByDays.forEach((events, key) => {
      const date = new Date(key);
      days.push({
        model: events,
        value: date,
      });
    });
    return days;
  }

  @computed
  private get eventsByDays(): Map<number, Array<ICalendarEvent<any>>> {
    const map = new Map<number, Array<ICalendarEvent<any>>>();
    for (const event of this.events) {
      const key = moment(event.from).startOf("day").valueOf();
      if (!map.has(key)) {
        map.set(key, []);
      }
      const chunk = map.get(key)!;
      chunk.push(event);
    }

    return map;
  }
  @observable
  public date: Date = new Date();
  @observable
  public selectedDay: Date | undefined;
  @computed
  public get selectedDayOption():
    | IDatePickerOption<ICalendarEvent<any>>
    | undefined {
    if (!this.selectedDay) {
      return undefined;
    }
    const time = this.selectedDay.getTime();
    return this.selectableDays.find((x) => x.value.getTime() === time);
  }
  @computed
  public get selectedDayEvents(): Array<ICalendarEvent<any>> {
    const { selectedDayOption } = this;
    if (!selectedDayOption) {
      return [];
    }
    return Array.isArray(selectedDayOption.model)
      ? selectedDayOption.model
      : [selectedDayOption.model];
  }
  @observable
  public legendVisible: boolean = true;
  public eventsReloading = new PendingStatus();
  private readonly eventsDisposer: IReactionDisposer;
  constructor(
    @inject(InversifyContextSymbol)
    private readonly inversifyContext: interfaces.Context,
    @inject(ApiServiceSymbol) private readonly apiService: IApiService,
    @inject(AuthServiceSymbol) private readonly authService: IAuthService,
    @inject(DatetimeSymbol) private readonly datetime: IDatetime,
    @inject(UserDbServiceSymbol) private readonly userDbService: IUserDbService,
    @inject(HistorySymbol) private readonly history: History,
    @inject(EventModelFactorySymbol)
    private readonly eventModelFactory: IEventModelFactory,
    @inject(UserStoreSymbol) private readonly userStore: IUserStore
  ) {
    this.eventsDisposer = reaction(
      () => ({
        datePickerDate: this.date,
      }),
      ({ datePickerDate }) => {
        const { user } = this.userStore;
        if (!user) {
          throw new Error("User must be authenticated");
        }

        this.reloadEvents(datePickerDate, user).catch((err) =>
          console.error(err)
        );
      }
    );
  }

  public async mount() {
    const { user } = this.userStore;
    if (!user) {
      throw new Error("User must be authenticated");
    }

    const userHasAnyBooking = await this.userDbService.hasBookings(user.id);
    const userHasSaldo = user.saldoBalance > 0;
    if (!userHasAnyBooking && !userHasSaldo) {
      console.debug("User has no lessons scheduled or complited.");
      this.history.replace("/booking/order?redirected=true");
    }

    await Promise.all([
      this.reloadEvents(this.date, user),
      user.refreshDashboardCounts(),
      user.refreshSaldoCounts(),
    ]);
    const { dashboardCounts } = user;
    if (!dashboardCounts) {
      throw new Error(
        "Dashboard can't be undefined after refreshDashboardCounts"
      );
    }
    if (!user.profile) {
      await user.refreshProfile();
    }
    if (!user.profile) {
      throw new Error("User profile undefined");
    }
  }
  public unmount() {
    this.eventsDisposer();
  }

  @action
  public setDay(date: Date): void {
    this.date = date;
  }

  public showLegend() {
    this.setLegendVisible(true);
  }
  public hideLegend() {
    this.setLegendVisible(false);
  }

  @action
  public selectDay(val: Date | undefined) {
    this.selectedDay = val;
  }
  @action
  private setLegendVisible(val: boolean): void {
    this.legendVisible = val;
  }

  @action
  private replaceEvents(val: Array<ICalendarEvent<any>>): void {
    this.events.replace(val);
  }

  private async reloadEvents(datePickerDate: Date, user: IUserModel) {
    console.debug("reloadEvents", datePickerDate);
    const from = moment(datePickerDate).clone().startOf("month").toDate();
    const to = moment(datePickerDate).clone().endOf("month").toDate();
    try {
      this.eventsReloading.startPending();
      const calendarEvents = await user.fetchEvents(from, to);
      this.replaceEvents(calendarEvents);
    } catch (e) {
      console.error(e);
    } finally {
      this.eventsReloading.stopPending();
    }
  }
}
