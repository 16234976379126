import { inject, injectable, interfaces } from "inversify";
import { action, computed, observable, runInAction } from "mobx";
import {
  AuthServiceSymbol,
  CoreApiServiceSymbol,
  DbServiceSymbol,
  InversifyContextSymbol,
} from "../../inversify/symbols";
import { AcademyServiceModel } from "../../models/AcademyServiceModel";
import {
  IAcademyServiceModel,
  ICoreService,
  ICoreServiceSort,
  ICoreServiceSortable,
  ProductType,
  VehicleType,
} from "../../models/AcademyServiceModel/interfaces";
import { IApiBundle } from "../../models/BundleModel/interfaces";
import { InstructorModel } from "../../models/InstructorModel";
import {
  ICoreInstructor,
  IInstructorModel,
} from "../../models/InstructorModel/interfaces";
import { LocationModel } from "../../models/LocationModel";
import {
  IApiLocation,
  ILocationModel,
} from "../../models/LocationModel/interfaces";
import { RegionModel } from "../../models/RegionModel";
import { IRegionModel } from "../../models/RegionModel/interfaces";
import { IAuthService } from "../../services/AuthService/interfaces";
import { ICoreApiService } from "../../services/CoreApiService/interfaces";
import { IDbService } from "../../services/DbService/interfaces";
import {
  IApiRegion,
  IMaintenance,
  ISettings,
  ISettingsStore,
  ITranslations,
} from "./interfaces";
import { safeLocalStorage } from "../LocalStorage";
import { Subject } from "rxjs";
import { Language } from "@/services/I18nService/interfaces";

@injectable()
export class SettingsStore implements ISettingsStore {
  public static get versionKey() {
    return SettingsStore.version.replace(new RegExp("\\.", "g"), "_");
  }

  @computed
  public get maintenance(): IMaintenance {
    const settingsValue = this.settingsValue.get();
    const maintenanceList = settingsValue && settingsValue.maintenanceList;
    if (
      undefined === maintenanceList ||
      !maintenanceList[SettingsStore.versionKey]
    ) {
      return {
        enable: false,
        message: "",
      };
    }
    return maintenanceList[SettingsStore.versionKey];
  }

  @computed
  public get translations(): ITranslations {
    const settingsValue = this.settingsValue.get();
    return settingsValue && settingsValue.translations
      ? settingsValue.translations
      : { en: {}, sv: {} };
  }

  @computed
  public get drivingPlanMetaId() {
    const settingsValue = this.settingsValue.get();
    return settingsValue ? settingsValue.drivingPlanMeta : "meta2";
  }

  public static version = "v.1.5";
  public readonly countdownDuration: number = 60 * 10;

  @observable
  public regions: IRegionModel[] = [];
  @observable
  public locations: ILocationModel[] = [];
  @observable
  public services: IAcademyServiceModel[] = [];
  @observable
  public instructors: IInstructorModel[] = [];

  @observable
  public loading = true;
  protected locationsCachedData: ILocationModel[] | null = null;
  protected instructorsCachedData: IInstructorModel[] | null = null;
  @observable
  private settingsValue = observable.box<ISettings | undefined>(undefined, {
    deep: false,
    name: "settingsValue",
  });

  private loadedSubject = new Subject<boolean>();
  readonly loaded = this.loadedSubject.asObservable().toPromise();

  public constructor(
    @inject(DbServiceSymbol) private readonly dbService: IDbService,
    @inject(AuthServiceSymbol) private readonly authService: IAuthService,
    @inject(CoreApiServiceSymbol)
    private readonly coreApiService: ICoreApiService,
    @inject(InversifyContextSymbol)
    private readonly inversifyContext: interfaces.Context
  ) {
    this.getCoreSettings();
    // fetching the local copy of settings. Please note that it could be outdated so...
    const localSettings: string | null = safeLocalStorage.getItem(
      this.localStoreDataKey
    );
    if (localSettings) {
      this.setSettings(JSON.parse(localSettings));
    }
    // ...subscribe on settings in FB and update immediately
    this.subscribe();
  }
  protected localStoreDataKey = "SettingsLocalData";
  private disposer: undefined | (() => void);
  private subscribe() {
    this.unsubscribe();
    this.disposer = this.dbService.subscribe("/settings", this.updateSettings);
  }

  private unsubscribe() {
    if (this.disposer) {
      this.disposer();
    }
  }
  private readonly updateSettings = (settings: ISettings) => {
    this.setSettings(settings);
    // update local copy of settings
    safeLocalStorage.setItem(this.localStoreDataKey, JSON.stringify(settings));
  };

  @action
  private setSettings(val: ISettings | undefined) {
    // clear internal caches
    this.locationsCachedData = null;
    this.instructorsCachedData = null;

    // update settings and fire up all observables
    this.settingsValue.set(val);
  }

  /**
   * Returns sorted services list with onTop flag
   */
  private getSortableServiceList(
    services: Array<ICoreService>,
    bundles: Array<IApiBundle>,
    serviceSortOptions: ICoreServiceSort[]
  ): ICoreServiceSortable[] {
    const serviceSortProductIds = serviceSortOptions.map(
      (s) => `${s.productType}-${s.productId}`
    );
    const serviceSortIndexes = serviceSortOptions.reduce(
      (acc, serviceSort) =>
        new Map(acc).set(
          `${serviceSort.productType}-${serviceSort.productId}`,
          serviceSort.sortIndex
        ),
      new Map<string, number>()
    );

    //add vehicleType to Bundles

    bundles.map((bundle) => {
      let bundleIds = bundle.content
        //.filter((ser) => ser.type !== "Theory")
        .map((ser) => ser.id);
      if (bundleIds.length > 0) {
        const bundleServices = services.filter((ser) =>
          bundleIds.includes(ser.id)
        );

        const bundleServicesVehicleTypes = new Set(
          bundleServices.map((v) => v.vehicleType)
        );
        const bundleServicesLanguage = new Set(
          bundleServices.map((v) => v.languageCode).filter((v) => v && v.length)
        );
        const bundleServiceMustBeBooked = bundle.content.filter(
          (ser) =>
            ser.productProcessingConfig?.autoBookingData.autoBookIndex === 0
        );
        if (bundleServiceMustBeBooked.length) {
          bundle.mustBeBookedOnPurchase = true;
          const serviceToBook = services.find(
            (ser) => ser.id === bundleServiceMustBeBooked[0].id
          );
          bundle.serviceToBook = {
            serviceId: serviceToBook?.id || 0,
            locationId: serviceToBook?.associationsIds.ownLocations[0] || 0,
          };
        }

        if (bundleServicesVehicleTypes.size > 1) {
          bundle.vehicleType = undefined;
        } else if (bundleServicesVehicleTypes.has(VehicleType.car)) {
          bundle.vehicleType = VehicleType.car;
        } else if (bundleServicesVehicleTypes.has(VehicleType.mc)) {
          bundle.vehicleType = VehicleType.mc;
        } else if (bundleServicesVehicleTypes.has(VehicleType.Moped)) {
          bundle.vehicleType = VehicleType.Moped;
        }

        if (
          bundleServicesLanguage.size > 1 ||
          bundleServicesLanguage.size === 0
        ) {
          bundle.languageCode = undefined;
        } else if (bundleServicesLanguage.has(Language.Eng)) {
          bundle.languageCode = Language.Eng;
        } else if (bundleServicesLanguage.has(Language.Swe)) {
          bundle.languageCode = Language.Swe;
        }
      }
      //}
    });

    return [...services, ...bundles]
      .map((s) => ({
        ...s,
        onTop: serviceSortProductIds.includes(`${s.productType}-${s.id}`),
        sortIndex: serviceSortIndexes.get(`${s.productType}-${s.id}`),
      }))
      .sort((a, b) => {
        if (a.sortIndex || -1 > (b.sortIndex || -1)) {
          return 1;
        }
        if (b.sortIndex || -1 > (a.sortIndex || -1)) {
          return -1;
        }
        return 0;
      });
  }

  private async getCoreSettings(): Promise<void> {
    this.setLoading(true);
    const [regions, locations, bundles, services, productSort, instructors] =
      await Promise.all([
        this.coreApiService.getRegions(),
        this.coreApiService.getLocations(),
        this.coreApiService.getActiveBundles().catch((e) => []),
        this.coreApiService.getServices(),
        this.coreApiService.getServiceSort().catch((e) => []),
        this.coreApiService.getInstructors(),
      ]);

    runInAction(() => {
      this.setLocations(locations);
      this.setRegions(regions);
      this.setServices(
        this.getSortableServiceList(
          [
            //...bundles.map((b) => ({ ...b, productType: ProductType.Bundle })),
            ...services.map((b) => ({
              ...b,
              productType: ProductType.Service,
            })),
          ],
          [...bundles.map((b) => ({ ...b, productType: ProductType.Bundle }))],
          productSort
        ),
        services,
        regions
      );
      this.setInstructors(instructors);
      this.setLoading(false);
    });
  }

  @action
  private setLoading(val: boolean) {
    this.loading = val;
    if (!val) {
      this.loadedSubject.next(true);
      this.loadedSubject.complete();
    }
  }

  @action
  private setRegions(val?: IApiRegion[]) {
    this.regions = (val || []).map((region) => {
      return new RegionModel(region);
    });
  }

  @action
  private setLocations(val?: IApiLocation[]) {
    this.locations = (val || []).map((location) => {
      return new LocationModel(location);
    });
  }

  @action
  private setServices(
    val: Array<ICoreServiceSortable>,
    services: ICoreService[],
    regions: IApiRegion[]
  ) {
    this.services = val.map(
      (bundle) => new AcademyServiceModel(bundle, services, regions).data
    );
  }

  @action
  private setInstructors(val?: ICoreInstructor[]) {
    this.instructors = (val || [])
      .filter((instructor) => instructor.isInstructor) // TODO get only instructors -> remove this filter
      .map((instructor) => {
        return new InstructorModel(instructor);
      });
  }
}
