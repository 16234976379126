import { inject, injectable } from "inversify";
import { action, observable, runInAction } from "mobx";
import * as moment from "moment";
import {
  ApiServiceSymbol,
  EventModelFactorySymbol,
  UserStoreSymbol,
} from "../../../inversify/symbols";
import { IEventModelFactory } from "../../../models/bookings/interfaces";
import { ErrorMessage } from "../../../models/ErrorMessage";
import { PendingStatus } from "../../../models/PendingStatus";
import { IApiService } from "../../../services/ApiService/interfaces";
import { IUserStore } from "../../../stores/UserStore/interfaces";
import { ICalendarEvent } from "../ScheduleScreen/Calendar/interfaces";
import { IEventsListScreenModel } from "./interfaces";

@injectable()
export class EventsListScreenModel implements IEventsListScreenModel {
  public readonly loadingStatus = new PendingStatus();
  public readonly errorStatus = new ErrorMessage();
  mountedDay: Date | undefined;

  public events = observable.array<ICalendarEvent<any>>();
  constructor(
    @inject(ApiServiceSymbol) private readonly apiService: IApiService,
    @inject(UserStoreSymbol) private readonly userStore: IUserStore,
    @inject(EventModelFactorySymbol)
    private readonly eventsFactory: IEventModelFactory
  ) {}

  public async mount(day: Date | undefined): Promise<void> {
    this.mountedDay = day;
    await this.reloadEvents(day);
  }

  public unmount(): void {
    this.mountedDay = undefined;
    // nothing for now
  }

  async refresh() {
    console.log('refresh', this.mountedDay);
    this.reloadEvents(this.mountedDay);
  }

  private async reloadEvents(day: Date | undefined) {
    try {
      runInAction(() => {
        this.loadingStatus.startPending();
        this.errorStatus.clear();
      });
      const from = (
        day ? moment(day).startOf("day") : moment().subtract(1, "year")
      ).toDate();
      const to = (
        day ? moment(day).endOf("day") : moment().add(1, "years")
      ).toDate();
      const events = await this.userStore.user!.fetchEvents(from, to);
      this.replaceAction(events);
    } catch (e) {
      this.errorStatus.setMessage(
        (e instanceof Error && e.message) || String(e)
      );
    } finally {
      this.loadingStatus.stopPending();
    }
  }

  @action
  private replaceAction(events: Array<ICalendarEvent<any>>) {
    this.events.replace(events);
  }
}
