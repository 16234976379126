import classNames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import {
  isFbDrivingExamEventModel,
  isFbTheoryExamEventModel,
  isLessonEventModel,
} from "../../../../models/bookings/interfaces";
import { isIntroServiceKey } from "../../../../stores/SettingsStore/interfaces";
import { Color } from "../../../../styles/interfaces";
import { IDateCellProps } from "../../../shared/DatePicker/DateCell";
import { CalendarEventType, ICalendarEvent } from "./interfaces";
import styles from "./styles.scss";
import { GearType, GearTypeMc } from "@/models/InstructorModel/interfaces";

@observer
export class CalendarDayCell<
  T extends ICalendarEvent<any>
> extends React.Component<IDateCellProps<T>, {}> {
  public render() {
    const { className, selectable, invisible, selected, date } = this.props;
    const color = this.getColor();
    const special = this.getSpecial();
    const models = this.models;
    const hasExternalComment = models.some((model) => model.externalComment);

    return (
      <div
        className={classNames(
          styles.calendarDayCell,
          {
            [styles.selected]: !!selected,
            [styles.invisible]: !!invisible,
            [styles.selectable]: selectable,
            [styles.selected]: selected,
          },
          color ? styles[color] : undefined,
          special ? styles[special] : undefined,
          className
        )}
        onClick={this.onClick}
      >
        <div className={styles.circle}>
          {special && (
            <div className={classNames(styles[`${special}-after`])}>!</div>
          )}
          <span className={styles.dayN}>{date.getDate()}</span>
          {models.length > 1 && (
            <div className={styles.subcount}>{models.length}</div>
          )}
          {hasExternalComment && (
            <span className={styles.message}>&#x2709;</span>
          )}
        </div>
      </div>
    );
  }

  private onClick = (event: React.SyntheticEvent) => {
    if (this.props.onClick) {
      this.props.onClick(event);
    }
    if (this.props.selectable && this.props.onSelect) {
      this.props.onSelect(this.props.date);
    }
  };

  private getSpecial() {
    return this.models.some((m) => m.isPaid === false) ? "attention" : null;
  }

  private getColor(): Color | undefined {
    for (const model of this.models) {
      if (isIntroServiceKey(model.typeKey)) {
        return Color.Mushroom;
      }
    }
    for (const model of this.models) {
      if (model.typeKey === CalendarEventType.Risk1) {
        return Color.RosePink;
      }
      if (model.typeKey === CalendarEventType.Risk2) {
        return Color.Rose;
      }
      if (model.typeKey === CalendarEventType.Moped) {
        return Color.LightGreyBlue;
      }
      if (model.typeKey === CalendarEventType.Taxi) {
        return Color.BrightOrange;
      }
      if (model.typeKey === CalendarEventType.EducationControl) {
        return Color.Wisteria;
      }
      if (isFbTheoryExamEventModel(model)) {
        return Color.SickGreen;
      }
      if (model.typeKey === CalendarEventType.TheoryGroup) {
        return Color.PastelBlue;
      }
      if (model.typeKey === CalendarEventType.TestDriving) {
        return Color.DarkViolet;
      }
      if (model.typeKey === CalendarEventType.DrivingTest) {
        return Color.Sea;
      }
      if (isFbDrivingExamEventModel(model)) {
        return Color.Sea;
      }
      if (model.typeKey === CalendarEventType.Test) {
        return Color.Wheat;
      }
      if (isLessonEventModel(model)) {
        if (model.isUTB) {
          return Color.Wisteria;
        } else if (model.isDriving) {
          if (
            model.vehicle?.gearType &&
            [GearType.McA, GearType.McA1, GearType.McA2].includes(
              model.vehicle?.gearType
            )
          )
            return Color.DarkViolet;
          else return Color.Sea;
        } else {
          if (
            model.vehicle?.gearType &&
            [GearType.McA, GearType.McA1, GearType.McA2].includes(
              model.vehicle?.gearType
            )
          )
            return Color.Magenta;
          return Color.FadedOrange;
        }
      }
    }
  }

  private get models(): Array<ICalendarEvent<any>> {
    const option = this.props.option;
    if (!option) {
      return [];
    }
    return Array.isArray(option.model) ? option.model : [option.model];
  }
}
