import { inject, injectable } from "inversify";
import { action, computed, observable } from "mobx";
import {
  CoreApiServiceSymbol,
  SettingsStoreSymbol,
} from "../../../inversify/symbols";
import {
  AcademyServiceTypes,
  IAcademyServiceModel,
} from "../../../models/AcademyServiceModel/interfaces";
import { ILocationModel } from "../../../models/LocationModel/interfaces";
import { ICoreApiService } from "../../../services/CoreApiService/interfaces";
import { ISettingsStore } from "../../../stores/SettingsStore/interfaces";
import { IServicesScreenModel } from "./interfaces";

@injectable()
export class ServicesScreenModel implements IServicesScreenModel {
  @observable
  public location: ILocationModel = this.settingsStore.locations[0]; // I'm sure
  @observable
  public coreServices: IAcademyServiceModel[] | undefined;
  @computed
  public get services(): IAcademyServiceModel[] {
    return this.settingsStore.services.slice().filter((service) => {
      return service.locations.some((loc) => loc === this.location.id);
    });
  }
  @computed
  public get lessons(): IAcademyServiceModel[] {
    return this.services.filter(
      (service) => service.meta?.type === AcademyServiceTypes.Lesson
    );
  }
  @computed
  public get courses(): IAcademyServiceModel[] {
    return this.services.filter(
      (service) => service.meta?.type === AcademyServiceTypes.Class
    );
  }
  @computed
  public get tests(): IAcademyServiceModel[] {
    return this.services.filter(
      (service) => service.meta?.type === AcademyServiceTypes.Test
    );
  }
  constructor(
    @inject(SettingsStoreSymbol) private readonly settingsStore: ISettingsStore,
    @inject(CoreApiServiceSymbol)
    private readonly coreApiService: ICoreApiService
  ) {}

  @action
  public setLocation(val: ILocationModel) {
    this.location = val;
  }
}
