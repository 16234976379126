import classNames from "classnames";
import * as React from "react";
import { StyleType } from "../../../../styles/interfaces";
import styles from "./styles.scss";

interface ICardProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  styleType?: StyleType;
}

export function Card(props: ICardProps) {
  const { styleType = StyleType.Secondary, className, ...tagProps } = props;
  return (
    <div
      className={classNames(styles.card, styles[styleType], className)}
      {...tagProps}
    />
  );
}
