import { when } from "mobx";
import { fromPromise } from "mobx-utils";
import * as React from "react";
import { lazyInject } from "../../../inversify/container";
import { I18nServiceSymbol, UiStoreSymbol } from "../../../inversify/symbols";
import { II18nService } from "../../../services/I18nService/interfaces";
import { IUiStore } from "../../../stores/UiStore/interfaces";
import { Button } from "../forms/Button";
import { LoadingWrapper } from "../loaders/LoadingWrapper";

type ComponentType<P> = React.ComponentType<P>;
interface ILazyComponentProps<P> {
  componentPromise: Promise<ComponentType<P>>;
}
interface ILazyComponentState<P> {
  component: undefined | ComponentType<P>;
  failed: boolean;
}
export class LazyComponent<
  // All properties passed to LazyComponent
  AP extends ILazyComponentProps<any>
> extends React.Component<
  AP,
  ILazyComponentState<
    // Properties forwarded to the child component
    Pick<AP, Exclude<keyof AP, keyof ILazyComponentProps<any>>>
  >
> {
  private cancelComponentPromise?: () => void;
  @lazyInject(UiStoreSymbol) private readonly uiStore!: IUiStore;
  @lazyInject(I18nServiceSymbol) private readonly i18nService!: II18nService;

  constructor(props: AP) {
    super(props);
    this.state = {
      component: undefined,
      failed: false,
    };
    setTimeout(() => {
      this.resolveComponent(this.props.componentPromise).catch((err) =>
        console.warn(err)
      );
    }, 0);
  }
  public componentWillReceiveProps(
    nextProps: Readonly<AP>,
    nextContext: any
  ): void {
    this.resolveComponent(nextProps.componentPromise).catch((err) =>
      console.warn(err)
    );
  }

  public render() {
    console.debug("re-render LazyComponent", this.props);
    const { component: Component, failed } = this.state;
    const { componentPromise, ...restProps } = this.props;
    if (Component) {
      console.debug("component", Component.displayName || Component.name);
      return <Component {...restProps} />;
    } else if (failed) {
      return (
        <div>
          <h1>Something went wrong.</h1>
          <Button onClick={this.reload}>
            {this.i18nService.i18next.t("reload").toString()}
          </Button>
        </div>
      );
    } else {
      return (
        <LoadingWrapper loading={true}>
          <div />
        </LoadingWrapper>
      );
    }
  }
  private reload = () => {
    window.location.reload();
  };

  private async resolveComponent(
    componentPromise: Promise<
      ComponentType<Pick<AP, Exclude<keyof AP, keyof ILazyComponentProps<any>>>>
    >
  ) {
    try {
      if (this.cancelComponentPromise) {
        this.cancelComponentPromise();
        this.cancelComponentPromise = undefined;
      }
      this.setState((prevState) => ({ ...prevState, failed: false }));
      const wrappedPromise = fromPromise(componentPromise);

      const cancelSource = when(() => wrappedPromise.state !== "pending");
      this.cancelComponentPromise = cancelSource.cancel;
      await cancelSource;
      const component = await wrappedPromise;
      this.setState((prevState) => ({ ...prevState, component }));
      this.cancelComponentPromise = undefined;
      console.debug("ping");
    } catch (e) {
      if (e !== "WHEN_CANCELLED") {
        console.warn(e);
        this.setState((prevState) => ({ ...prevState, failed: true }));
        await this.uiStore.checkUpdates();
      } else {
        console.debug("WHEN_CANCELLED");
      }
    }
  }
}
