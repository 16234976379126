import classNames from "classnames";
import { observer } from "mobx-react";
import * as moment from "moment";
import React from "react";
import { IDatetime } from "../../../helpers/Datetime/interfaces";
import { lazyInject } from "../../../inversify/container";
import { DatetimeSymbol, I18nServiceSymbol } from "../../../inversify/symbols";
import { II18nService } from "../../../services/I18nService/interfaces";
import { Button } from "../forms/Button";
import { InputStatusType } from "../forms/interfaces";
import { ArrowLeft } from "../icons/ArrowLeft";
import { ArrowRight } from "../icons/ArrowRight";
import { DateCell, IDateCellProps } from "./DateCell";
import styles from "./styles.scss";

interface IDatePickerProps<T> {
  initialDate?: Date;
  className?: string;
  selectedDate?: Date | undefined;
  statusType?: InputStatusType;
  onNavigateDate?: (date: Date) => void;
  onSelectDate?: (date: Date) => void;
  Cell?: React.ComponentType<IDateCellProps<T>>;
  dateOptions?: ReadonlyArray<IDatePickerOption<T>>;
}

export interface IClassMeta {
  disabled: boolean;
}

export interface IDatePickerOption<T> {
  value: Date;
  model: T | T[];
}
interface IDatePickerState {
  date: Date;
  weeks: Date[][];
}

@observer
export class DatePicker<T> extends React.Component<
  IDatePickerProps<T>,
  IDatePickerState
> {
  @lazyInject(I18nServiceSymbol)
  private readonly i18nService!: II18nService;
  @lazyInject(DatetimeSymbol)
  private readonly datetime!: IDatetime;
  constructor(props: IDatePickerProps<T>) {
    super(props);

    const date = props.initialDate || props.selectedDate || new Date();
    const weeks = this.calculateWeeks(moment(date)).map((x) =>
      x.map((y) => y.toDate())
    );

    this.state = {
      date,
      weeks,
    };
  }

  public componentWillReceiveProps(nextProps: IDatePickerProps<T>) {
    if (nextProps.initialDate || nextProps.selectedDate) {
      this.setDate(
        nextProps.initialDate || nextProps.selectedDate || new Date()
      );
    }
  }

  public render() {
    const anchorStart = moment(this.state.date)
      .clone()
      .startOf("month")
      .valueOf();
    const anchorEnd = moment(this.state.date).clone().endOf("month").valueOf();

    const { className, statusType } = this.props;
    const statusTypeStyleClass = statusType ? styles[statusType] : undefined;
    const Cell: React.ComponentType<IDateCellProps<T>> =
      this.props.Cell || DateCell;
    const selectedDateTime =
      this.props.selectedDate && this.props.selectedDate.getTime();
    return (
      <div
        className={classNames(
          styles.container,
          statusTypeStyleClass,
          className
        )}
      >
        <div className={styles.headerRow}>
          <div className={styles.leftControlsContainer}>
            <div className={styles.arrowContainer}>
              <Button
                className={styles.arrow}
                iconButton={true}
                onClick={this.onClickPrevMonth}
              >
                <ArrowLeft />
              </Button>
            </div>
          </div>
          <div className={styles.monthTitle}>
            {this.datetime.formatMonth(moment(this.state.date))}{" "}
            {this.state.date.getFullYear()}
          </div>
          <div className={styles.rightControlsContainer}>
            <div className={styles.arrowContainer}>
              <Button
                className={styles.arrow}
                iconButton={true}
                onClick={this.onClickNextMonth}
              >
                <ArrowRight />
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.daysRow}>
          <div className={styles.dayName}>
            {this.i18nService.i18next.t("datepicker.monday").toString()}
          </div>
          <div className={styles.dayName}>
            {this.i18nService.i18next.t("datepicker.tuesday").toString()}
          </div>
          <div className={styles.dayName}>
            {this.i18nService.i18next.t("datepicker.wednesday").toString()}
          </div>
          <div className={styles.dayName}>
            {this.i18nService.i18next.t("datepicker.thursday").toString()}
          </div>
          <div className={styles.dayName}>
            {this.i18nService.i18next.t("datepicker.friday").toString()}
          </div>
          <div className={styles.dayName}>
            {this.i18nService.i18next.t("datepicker.saturday").toString()}
          </div>
          <div className={styles.dayName}>
            {this.i18nService.i18next.t("datepicker.sunday").toString()}
          </div>
        </div>
        <div className={styles.weeksContainer}>
          {this.state.weeks.map((row, i) => (
            <div className={styles.weekContainer} key={i}>
              {row.map((date, j) => {
                const time = date.getTime();
                const invisible = time >= anchorStart && time < anchorEnd;
                const selected = selectedDateTime === date.getTime();
                const dateOption = this.props.dateOptions
                  ? this.props.dateOptions.find(
                      (x) => x.value.getTime() === date.getTime()
                    )
                  : undefined;
                const selectable = this.props.dateOptions ? !!dateOption : true;

                return (
                  <Cell
                    key={j}
                    date={date}
                    option={dateOption}
                    datePickerDate={this.state.date}
                    invisible={!invisible}
                    selectable={selectable}
                    onSelect={this.onSelectCeil}
                    selected={selected}
                  >
                    {date.getDate()}
                  </Cell>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  }

  public setDate(date: Date) {
    const weeks = this.calculateWeeks(moment(date)).map((x) =>
      x.map((y) => y.toDate())
    );
    this.setState({ date, weeks });
    const { onNavigateDate } = this.props;
    if (onNavigateDate) {
      onNavigateDate(date);
    }
  }

  private calendarGridDates(month: moment.Moment) {
    const monthStart = month.clone().startOf("month");
    const monthEnd = month.clone().endOf("month");
    const monthStartDay = monthStart.day();
    const monthEndDay = monthEnd.day();
    const monthScreenStart = monthStart
      .clone()
      .add(1 - (monthStartDay || 7), "days");
    const monthScreenEnd = monthEnd.clone().add(7 - (monthEndDay || 7), "days");

    return this.datetime.daysList(monthScreenStart, monthScreenEnd);
  }

  private calculateWeeks(date: moment.Moment): moment.Moment[][] {
    const datesList = this.calendarGridDates(date);

    const weeks: moment.Moment[][] = [];
    for (let i = 0; i < datesList.length; i += 7) {
      const row = datesList.slice(i, i + 7);
      weeks.push(row);
    }
    return weeks;
  }
  private onClickPrevMonth = () => {
    this.setDate(moment(this.state.date).add(-1, "month").toDate());
  };
  private onClickNextMonth = () => {
    this.setDate(moment(this.state.date).add(1, "month").toDate());
  };

  private onSelectCeil = (date: Date) => {
    const { onSelectDate } = this.props;
    if (onSelectDate) {
      onSelectDate(date);
    }
  };
}
