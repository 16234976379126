import { inject, injectable } from "inversify";
import { action, computed, observable } from "mobx";
import { SettingsStoreSymbol } from "../../../inversify/symbols";
import { IInstructorModel } from "../../../models/InstructorModel/interfaces";
import { ILocationModel } from "../../../models/LocationModel/interfaces";
import { ISettingsStore } from "../../../stores/SettingsStore/interfaces";
import { IInstructorsScreenModel } from "./interfaces";

@injectable()
export class InstructorsScreenModel implements IInstructorsScreenModel {
  @observable
  public location: ILocationModel | undefined = undefined;
  @computed
  public get instructors(): ReadonlyArray<IInstructorModel> {
    return this.settingsStore.instructors;
  }

  @computed
  public get instructorsByLocations(): ReadonlyMap<
    ILocationModel,
    ReadonlyArray<IInstructorModel>
  > {
    const res = new Map<ILocationModel, ReadonlyArray<IInstructorModel>>();
    for (const location of this.settingsStore.locations) {
      const instructors = this.instructors
        .filter((instructor) => instructor.locationIds.has(location.id))
        .sort((a, b) => {
          const posA = a.positionInLocation.get(location.id);
          const posB = b.positionInLocation.get(location.id);

          if (typeof posA === "number" && typeof posB === "number") {
            return posA - posB;
          } else {
            return 0;
          }
        });
      res.set(location, instructors);
    }

    return res;
  }

  constructor(
    @inject(SettingsStoreSymbol) private readonly settingsStore: ISettingsStore
  ) {}

  @action
  public setLocation(val: ILocationModel | undefined) {
    this.location = val;
  }
}
