import { action,computed, IObservableArray } from "mobx";
import { FormFieldState } from "../../../../models/FormFieldState";
import { Attendee } from "./Attendee";
import type { IAttendee, IAttendeesList } from "./interfaces";

export class AttendeesList
  extends FormFieldState<IObservableArray<IAttendee>>
  implements IAttendeesList
{
  @computed
  public get isValid(): boolean {
    const parentIsValid = !this.validationError;
    const hasUnsavedAttendees = this.value.some(v => !v.saved);
    return parentIsValid && !hasUnsavedAttendees;
  }
  @action
  public addAttendee(attendee?: IAttendee) {
    this.value.push(attendee || new Attendee());
    if (attendee) {
      attendee.requestValidation();
    }
  }

  @action
  public deleteAttendee(attendee: IAttendee) {
    if (this.value.length > 1) {
      this.value.remove(attendee);
    }
  }
}
