import classNames from "classnames";
import * as React from "react";
import { Color } from "../../../../../styles/interfaces";
import { IIconProps } from "../../../../shared/icons/interfaces";
import styles from "./styles.scss";

export interface ILegendItemProps extends React.HTMLAttributes<HTMLDivElement> {
  bold?: boolean;
  backgroundColor: Color;
  special?: Color;
  Icon?: React.ComponentType<IIconProps> | string;
}

export function LegendItem(props: ILegendItemProps) {
  const { backgroundColor, special, bold, Icon, ...tagProps } = props;

  return (
    <div {...tagProps} className={styles.legendItem}>
      <div className={classNames(styles.circle, styles[backgroundColor], special && styles[special])}>
        {special && <div className={classNames(styles[`${special}-after`])}>!</div>}
        {typeof Icon === "function" ? <Icon /> : Icon}
      </div>
      <span className={classNames(styles.legendItemDescription, { bold })}>
        {props.children}
      </span>
    </div>
  );
}
