import * as React from "react";
import { bugsnagClient } from "../../../bugsnag";
import { Button } from "../forms/Button";
import { LoadingWrapper } from "../loaders/LoadingWrapper";
import styles from "./styles.scss";

export interface IErrorComponentProps {
  error: any;
}

interface IModelMounterProps {
  label?: string; // just for debug
  errorComponent?: React.ComponentType<IErrorComponentProps>;
  mount: () => void | Promise<void>;
  unmount: () => void | Promise<void>;
  children: React.ReactNode;
}

interface IModelMounterState {
  mounted: boolean;
  error?: unknown;
}
export class ModelMounter extends React.Component<
  IModelMounterProps,
  IModelMounterState
> {
  public state: IModelMounterState = {
    mounted: false,
  };
  private mounted = false;
  public componentDidMount() {
    console.debug("ModelMounter did mount", this.props.label);
    this.mounted = true;
    this.tryMount();
  }
  public componentWillUnmount() {
    console.debug("ModelMounter will unmount", this.props.label);
    this.mounted = false;
    Promise.resolve(this.props.unmount()).catch((err) => {
      console.error(err);
    });
  }
  public render() {
    const ErrorComponent = this.props.errorComponent;
    const error = this.extractErrorMessage(this.state.error);
    return (
      <LoadingWrapper loading={!this.state.mounted}>
        {this.state.error && (
          <p className={styles.error}>
            {ErrorComponent ? <ErrorComponent error={error} /> : error}
            <Button
              onClick={this.tryMountAgain}
              style={{
                display: "block",
                margin: "0 auto",
                width: "unset",
              }}
            >
              Try again
            </Button>
          </p>
        )}
        {!this.state.error && this.props.children}
      </LoadingWrapper>
    );
  }

  private tryMountAgain = () => {
    this.tryMount(true);
  };

  private tryMount = (tryAgainAction?: boolean) => {
    this.setState({
      mounted: false,
    });
    Promise.resolve(this.props.mount())
      .catch((err) => {
        if (this.mounted) {
          this.setState({
            error: err,
          });
        }
        console.debug("notify bugsnag", err);
        this.notifyBugsnag(err);
      })
      .then(() => {
        if (this.mounted) {
          if (!!this.props.label) {
            bugsnagClient.leaveBreadcrumb(
              tryAgainAction
                ? `${this.props.label} is loaded with Try Again btn`
                : `${this.props.label} is loaded`
            );
          }
          this.setState({ mounted: true });
        }
      });
  };

  // TODO: move it to special class
  private notifyBugsnag(err: any) {
    bugsnagClient.metaData = {
      ...bugsnagClient.metaData,
      catchingPoint: "modelMounter",
    };
    if (err.response) {
      bugsnagClient.metaData = {
        ...bugsnagClient.metaData,
        axiosData: err.response.data,
        axiosStatus: err.response.status,
      };
    } else if (err.request) {
      bugsnagClient.metaData = {
        ...bugsnagClient.metaData,
        axiosRequest: err.request,
      };
    }
    bugsnagClient.notify(err);
  }

  private extractErrorMessage(error?: unknown): string | undefined {
    if (!error) {
      return;
    }
    if (error instanceof Error) {
      return error.message;
    } else if ("toString" in (error as any)) {
      return (error as any).toString();
    } else {
      return "Unknown error";
    }
  }
}
