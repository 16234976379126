import { observer } from "mobx-react";
import React from "react";
import styles from "./styles.scss";
import classNames from "classnames";
import { action, observable } from "mobx";
import { lazyInject } from "@/inversify/container";
import {
  AuthServiceSymbol,
  HistorySymbol,
  I18nServiceSymbol,
  SignUpScreenModelSymbol,
  UserStoreSymbol,
} from "@/inversify/symbols";
import type { II18nService } from "@/services/I18nService/interfaces";
import { ISignUpScreenModel } from "@/components/account/SignUpScreen/interfaces";
import { History } from "history";
import { IUserStore } from "@/stores/UserStore/interfaces";
import { IAuthService } from "@/services/AuthService/interfaces";
import { LogOutIcon } from "@/components/shared/icons/LogoutIcon";
import { PersonIcon } from "@/components/shared/icons/PersonIcon";
@observer
export default class UserSettings extends React.Component<{}> {
  @observable
  private isOverlayVisible = false;

  @lazyInject(HistorySymbol)
  private readonly history!: History;
  @lazyInject(I18nServiceSymbol)
  private readonly i18n!: II18nService;
  @lazyInject(SignUpScreenModelSymbol)
  private readonly model!: ISignUpScreenModel;
  @lazyInject(UserStoreSymbol)
  private readonly userStore!: IUserStore;
  @lazyInject(AuthServiceSymbol) private readonly authService!: IAuthService;

  constructor(props) {
    super(props);
  }

  @action
  setIsOverlayVisible(isOverlayVisible: boolean) {
    this.isOverlayVisible = isOverlayVisible;
  }

  render() {
    return (
      <div className={styles.userInfoContainer}>
        <div
          className={classNames(styles.personBtn, {
            [styles.isVisiblePersonBtn]: this.userStore.fbUser,
          })}
          onMouseEnter={() => this.setIsOverlayVisible(true)}
          onMouseLeave={() => this.setIsOverlayVisible(false)}
        >
          <PersonIcon width={30} height={28} stroke="#ffffff" />
        </div>
        <div
          className={classNames(styles.userModal, {
            [styles.isVisible]: this.isOverlayVisible && this.userStore.fbUser,
          })}
          onMouseEnter={() => this.setIsOverlayVisible(true)}
          onMouseLeave={() => this.setIsOverlayVisible(false)}
        >
          <div
            className={classNames(styles.userModalBlock, {
              [styles.isVisible]: this.isOverlayVisible,
            })}
          >
            <div className={styles.userInfo}>
              <PersonIcon width={32} height={32} stroke="#000000" />
              <p>
                {" "}
                {this.userStore.user?.firstName} {this.userStore.user?.lastName}
              </p>
              <p> {this.userStore.user?.email}</p>
            </div>
            <div className={styles.logOutBtnContainer}>
              <button className={styles.logOutBtn} onClick={this.signOut}>
                <LogOutIcon width={24} height={18} />
                {this.i18n.i18next.t("signOut").toString()}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private signOut = () => {
    this.authService.signOut();
    this.userStore.setUser(undefined);
  };
}
