import * as React from "react";

import { IIconProps } from "./interfaces";

export const ArrowRight = (props: IIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 20" {...props}>
    <path
      fill="currentColor"
      d="M2.283 0L0 2.283l7.417 7.434L0 17.15l2.283 2.283L12 9.717z"
    />
  </svg>
);
