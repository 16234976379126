import type {
  IBooking,
  ICoreApiService,
  IOrderDetails,
} from "@/services/CoreApiService/interfaces";
import { inject, injectable } from "inversify";
import { action, observable } from "mobx";
import { CoreApiServiceSymbol } from "../../inversify/symbols";

@injectable()
export class OrderPayStore {
  @observable
  private order!: IOrderDetails | undefined;

  @action
  setOrder(order: IOrderDetails | undefined) {
    this.order = order;
  }

  constructor(
    @inject(CoreApiServiceSymbol)
    private readonly coreApiService: ICoreApiService
  ) {}

  async init(orderId: number) {
    this.setOrder(undefined);
    try {
      const order = await this.coreApiService.getOrderDetails(orderId);
      this.setOrder(order);

      return order;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
