import { injectable } from "inversify";
import { ISettings } from "./interfaces";

@injectable()
export class Settings implements ISettings {
  public readonly fbApiKey = FIREBASE_API_KEY;
  public readonly fbAuthDomain = FIREBASE_DOMAIN;
  public readonly fbDatabaseURL = DATABASE_URL;
  public readonly fbProjectId = FIREBASE_PROJECT_ID;
  public readonly apiUrl = API_URL;
  public readonly apiUrlV2 = API_URL_V2;
  public readonly mdaCoreApiUrl = MDA_CORE_API;
  public readonly landingUrl = LANDING_URL;
}
