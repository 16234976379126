import * as React from "react";
import { lazyInject } from "../../../../inversify/container";
import { UiStoreSymbol } from "../../../../inversify/symbols";
import { IUiStore } from "../../../../stores/UiStore/interfaces";

export interface ILoadingWrapperProps {
  children: React.ReactNode;
  loading: boolean;
}

export class LoadingWrapper extends React.Component<ILoadingWrapperProps> {
  @lazyInject(UiStoreSymbol) private readonly uiStore!: IUiStore;

  private unregisterCb?: () => void;
  constructor(props: ILoadingWrapperProps) {
    super(props);
    if (this.props.loading) {
      this.spinnerOn();
    }
  }

  public componentWillReceiveProps(
    nextProps: Readonly<ILoadingWrapperProps>,
    nextContext: any
  ): void {
    if (nextProps.loading) {
      this.spinnerOn();
    } else {
      this.spinnerOff();
    }
  }

  public componentWillUnmount(): void {
    this.spinnerOff();
  }

  public render() {
    return this.props.children;
  }

  private spinnerOn = () => {
    if (!this.unregisterCb) {
      this.unregisterCb = this.uiStore.registerLoadingToken(this);
    }
  };

  private spinnerOff = () => {
    if (this.unregisterCb) {
      this.unregisterCb();
      this.unregisterCb = undefined;
    }
  };
}
