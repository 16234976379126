import * as React from "react";
import styles from "./styles.scss";

export interface IRawHtmlContainerProps {
  content: string;
}

export class RawHtmlContainer extends React.PureComponent<IRawHtmlContainerProps> {
  private readonly rawHtmlContainerRef = React.createRef<HTMLDivElement>();

  public componentDidMount() {
    if (this.props.content) {
      this.updateContainer();
    }
  }

  public componentDidUpdate(prevProps: IRawHtmlContainerProps) {
    if (this.props.content && this.props.content !== prevProps.content) {
      this.updateContainer();
    }
  }

  public render() {
    return (
      <div className={styles.rawHtmlContainer} ref={this.rawHtmlContainerRef} />
    );
  }

  protected updateContainer() {
    if (this.rawHtmlContainerRef && this.rawHtmlContainerRef.current) {
      this.rawHtmlContainerRef.current.innerHTML = this.props.content;
    }
  }
}
