import { ICorePromoCodeInfo } from "../../../../services/ApiService/interfaces";

export class DiscountHelper {
  public static calcMoneys(
    promocodeInfo: ICorePromoCodeInfo | undefined,
    originalPriceWithTax: number,
    originalPrice: number,
    count: number,
    giftCardsSum?: {name: string, amount: number}[]
  ): {
    isAbsolute: boolean;
    discount: number | undefined;
    priceWithTax: number;
    tax: number;
    totalPriceWithTax: number;
    totalTax: number;
    partialGiftcardAmount?:number;
  } {
    let isAbsolute: boolean;
    let discount: number | undefined;
    let priceWithTax: number;
    let totalPriceWithTax: number;
    let partialGiftcardAmount: number | undefined;
    let giftcardsTotalAmount: number | undefined;

    //if (giftCardsSum) {
    giftcardsTotalAmount = giftCardsSum && giftCardsSum?.map(v=>v.amount).reduce((acc, a) => acc + Number(a),0)/100 || 0;
    //}
    if (promocodeInfo) {
      if (promocodeInfo.isAbsolute) {
        isAbsolute = true;
        discount = Number(promocodeInfo.value);
        // promocode works on each position in the order
        priceWithTax = originalPriceWithTax;
        if (priceWithTax < 0) {
          priceWithTax = 0;
        }
        totalPriceWithTax = priceWithTax * count - discount - giftcardsTotalAmount;
        if (giftCardsSum) {
          partialGiftcardAmount = totalPriceWithTax < 0 ?  giftCardsSum[giftCardsSum.length-1]?.amount/100+totalPriceWithTax   : 0;
        }
      } else {
        isAbsolute = false;
        discount = Number(promocodeInfo.value);
        priceWithTax = originalPriceWithTax;
        totalPriceWithTax = priceWithTax * count - ((priceWithTax * count * discount) / 100) - giftcardsTotalAmount;
        if (giftCardsSum) {
          partialGiftcardAmount = totalPriceWithTax < 0 ?  giftCardsSum[giftCardsSum.length-1]?.amount/100+totalPriceWithTax   : 0;
        }
      }
    } else {
      isAbsolute = false;
      discount = undefined;
      priceWithTax = originalPriceWithTax;
      totalPriceWithTax = priceWithTax * count - giftcardsTotalAmount;  
      if (giftCardsSum) {
      partialGiftcardAmount = totalPriceWithTax < 0 ?  giftCardsSum[giftCardsSum.length-1]?.amount/100+totalPriceWithTax   : 0;
      }
    }
    
    const taxR = (originalPriceWithTax - originalPrice) / originalPriceWithTax;
    const tax = Math.round(priceWithTax * taxR);
    const totalTax = tax * count - giftcardsTotalAmount;

    return {
      discount,
      isAbsolute,
      priceWithTax,
      tax,
      totalPriceWithTax: totalPriceWithTax > 0 ? totalPriceWithTax : 0,
      totalTax: totalTax > 0 ? totalTax : 0,
      partialGiftcardAmount
    };
  }
}
