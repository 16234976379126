import { inject, injectable } from "inversify";
import { action, computed, flow, observable } from "mobx";
import { FormFieldState } from "../../models/FormFieldState";
import { bugsnagClient } from "../../bugsnag";
import {
  ApiServiceSymbol,
  CoreApiServiceSymbol,
  UserStoreSymbol,
} from "../../inversify/symbols";
import { IApiService } from "../../services/ApiService/interfaces";
import { PendingStatus } from "../../models/PendingStatus";
import { ICoreApiService } from "@/services/CoreApiService/interfaces";
import { UserModel } from "@/models/UserModel";
import { IUserModel } from "@/models/UserModel/interfaces";
import { IUserStore } from "@/stores/UserStore/interfaces";
import { CheckoutScreenModel } from "../klarna/CheckoutScreen/model";
import { ProductType } from "@/models/AcademyServiceModel/interfaces";
import { ErrorMessage } from "@/models/ErrorMessage";

const MIN_VALUE = 250;
const MAX_VALUE = 100000;

@injectable()
export class GiftCardOrderModel {
  public checkoutPending = new PendingStatus();
  @observable
  public giftPrice = new FormFieldState<number>(MIN_VALUE, {
    validator: (value: number) => {
      if (isNaN(value)) {
        return "priceRequired";
      }
      if (value < MIN_VALUE) {
        return "priceLessThanMin";
      }
      if (value > MAX_VALUE) {
        return "priceMoreThanMax";
      }
      if (value % 50) {
        return "priceMustBeMultiple";
      }
    },
  });
  @observable
  public email!: string;
  @observable
  public snippetVisible: boolean = false;
  @observable
  public snippet!: string;
  @observable
  public somethingWentWrong: boolean | undefined;
  @observable
  public emailStatus: boolean | undefined;
  @observable
  public errorStatus = new ErrorMessage();

  private get fields(): Array<FormFieldState<any>> {
    const fields: Array<FormFieldState<any>> = [this.giftPrice];
    return fields;
  }

  @computed
  public get isFormValid(): boolean {
    return !this.fields.some((field) => !field.isValid);
  }
  @computed
  public get isFormValidating(): boolean {
    return this.fields.some((field) => field.validatingStatus.isPending);
  }

  constructor(
    //@inject(ApiServiceSymbol) private readonly apiService: IApiService,
    @inject(UserStoreSymbol) private readonly userStore: IUserStore,
    @inject(CoreApiServiceSymbol)
    private readonly coreApiService: ICoreApiService
  ) {}

  @action
  public setGiftPrice(val: number) {
    this.giftPrice.setValue(val);
  }
  @action
  public setSomethingWentWrong(val: boolean | undefined) {
    this.somethingWentWrong = val;
  }
  @action
  public setEmail(val: string) {
    this.email = val;
  }
  @action
  public setSnippetVisible(val: boolean) {
    this.snippetVisible = val;
  }
  @action
  public setSnippet(val: string) {
    this.snippet = val;
  }
  @action
  public setEmailStatus(val: boolean | undefined) {
    this.emailStatus = val;
  }
  public readonly pay = flow<void, []>(function* (this: GiftCardOrderModel) {
    try {
      console.group("pay");
      this.checkoutPending.startPending();

      this.setSomethingWentWrong(false);

      yield this.load(this.giftPrice.value * 100, this.email);
    } finally {
      if (this.userStore.user) {
        bugsnagClient.notify(
          {
            message: "order",
            name: `User_${this.userStore.user.id}`,
          },
          { severity: "info" }
        );
      }
      this.checkoutPending.stopPending();
      console.groupEnd();
    }
  }).bind(this);

  public async load(value: number, email: string): Promise<void> {
    try {
      const resp = await this.coreApiService.orderGiftCard(
        email,
        value,
        this.userStore.user?.id
      );

      const sid = resp.id;
      const bookingId = resp.id;
      CheckoutScreenModel.saveInternalOrderInLocalStore({
        bookingId,
        sid,
        eventId: 0,
        firstLessonStartDateTime: 0,
        locationId: 0,
        price: 0,
        priceWithDiscount: 0,
        isPacket: false,
        productType: ProductType.Giftcard,
      });
      CheckoutScreenModel.saveKlarnaSnippetInLocalStore(resp.snippet);
    } catch (e) {
      const errorData: any = e;
      this.errorStatus.setMessage(`Error:${errorData.error}`);
      throw errorData;
      // bugsnagClient.notify(e, { severity: "warning" });
    }
  }
}
