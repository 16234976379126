import { action, observable } from "mobx";

export interface IReadonlyErrorMessage {
  readonly hasError: boolean;
  readonly message: string | undefined;
  readonly description: string | undefined;
}
export class ErrorMessage implements IReadonlyErrorMessage {
  @observable
  private messageInternal: string | undefined = undefined;
  @observable
  private descriptionInternal: string | undefined = undefined;

  public get hasError(): boolean {
    return !!this.messageInternal;
  }

  public get message(): string | undefined {
    return this.messageInternal;
  }

  public get description(): string | undefined {
    return this.descriptionInternal;
  }

  @action
  public setMessage(val: string | Error, description?: string) {
    if (typeof val === "object" && "message" in val) {
      val = val.message;
    }
    this.messageInternal = val;
    /**
     * Reset description if was set
     */
    this.descriptionInternal = description;
  }

  @action
  public clear() {
    this.messageInternal = undefined;
  }
}
